import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {APP_DATA} from "../../../general.app.config";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {AdminsService} from "../../../core/services/admins.service";
import {Subject, takeUntil} from 'rxjs';
import {b2cAvatar, ExpandedB2CUserItemDetailsModel} from "../../models/ExpandedB2CUserItemDetailsModel";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VendorStatusEnum } from '../../Enums/VendorStatusEnum';
import {TextService} from "../../../core/services/text.service";

@Component({
  selector: 'edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit, OnDestroy {
  /**
   * Indicator to show loader
   */
  isLoaded = true;

  userDataFromGroup: FormGroup;

  appData = APP_DATA;

  userAvatar: b2cAvatar;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackBarService,
    private adminsService: AdminsService,
    public textService: TextService,
  ) {
  }

  ngOnInit(): void {
    this.isLoaded = false;
    this.buildForm();

    this.adminsService.getB2CUserDetailsById(this.data.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userData: ExpandedB2CUserItemDetailsModel) => {
          this.fillForm(userData);
          this.userAvatar = userData.avatar;
          this.isLoaded = true;
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true);
        });
  }

  buildForm() {
    this.userDataFromGroup = new FormGroup({
      firstName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(3),
        Validators.pattern("^[a-zA-Z-]*$")]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(3),
        Validators.pattern("^[a-zA-Z-]*$")]),
      email: new FormControl({value:null, disabled: true}, [Validators.required, Validators.email, Validators.maxLength(320)]),
      avatarId: new FormControl(null),
      avatarUrl: new FormControl(null),
      metaMaskWalletAddress: new FormControl(null, [Validators.pattern(/^0[xX][0-9a-fA-F]{40}$/)]),
    });
  }

  fillForm(userData) {
    this.userDataFromGroup.get('firstName').patchValue(userData.firstName);
    this.userDataFromGroup.get('lastName').patchValue(userData.lastName);
    this.userDataFromGroup.get('email').patchValue(userData.email);
    this.userDataFromGroup.get('avatarId').patchValue(userData.avatar?.id);
    this.userDataFromGroup.get('avatarUrl').patchValue(userData.avatar?.url);
    this.userDataFromGroup.get('metaMaskWalletAddress').patchValue(userData.metaMaskWalletAddress);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveUser() {
    this.isLoaded = false;
    const {firstName, lastName, email, metaMaskWalletAddress, avatarId} = this.userDataFromGroup.value;
    const body = {firstName, lastName, email, metaMaskWalletAddress, avatarId};
    this.adminsService.updateB2CUsersDetails(body, this.data.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
          this.isLoaded = true;
          this.adminsService.needUpdateB2CUsersTable();
          this.dialogRef.close();
        },
        error => {
          this.isLoaded = true;
          this.snackBarService.showSnackBar(error.error.message, true);
        });
  }

  uploadFile(event, name) {
    if (event) {
      const {id, url} = event;
      this.userAvatar = {id, url}
    } else {
      this.userAvatar = null;
    }

    this.userDataFromGroup.get(name + 'Id').patchValue(event ? event.id : null);
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.userDataFromGroup) return false;
    if (!this.userDataFromGroup.get(fieldName).touched) return false;
    return this.userDataFromGroup.get(fieldName).hasError(errorName);
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }
}
