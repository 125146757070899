<div class="form-container" [ngClass]="{'usaved': isUnsavedQuestions && !question.id}">
  <form [formGroup]="questionFormGroup">
    <div class="general-from-filed">
      <div class="form-field-with-label">
        <mat-label>Text*</mat-label>
        <mat-form-field appearance="outline">
          <input
            (input)="isChangedQuestion = true"
            trim
            matInput
            placeholder="Enter question text"
            [formControlName]="'text'">
        </mat-form-field>
        <mat-error *ngIf="isShowGeneralError('text', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowGeneralError('text', 'required') && isShowGeneralError('text', 'maxlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_255}}</span>
        </mat-error>
      </div>
      <div class="form-field-with-label">
        <mat-label>Type*</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="isChangedQuestion = true"
            [(value)]="questionType"
            [formControlName]="'type'"
            required>
            <mat-option *ngFor="let type of questionTypeSelector" [value]="type.label">
              {{type.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field-with-label" *ngIf="!isOther">
      <mat-label>{{questionType === this.questionTypes.SCORE.label ? 'Event*' : 'Event'}}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Select an event"
          [matAutocomplete]="eventAuto"
          [formControlName]="'sportEventId'">
        <mat-icon
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          class="event-suffix"
          *ngIf="questionFormGroup.value['sportEventId']"
          (click)="clearEvent($event)"
          matSuffix>
          clear
        </mat-icon>
        <mat-icon
          class="event-suffix"

          *ngIf="!questionFormGroup.value['sportEventId']"
          matSuffix>
          manage_search
        </mat-icon>
        <mat-autocomplete
          (opened)="openPannel('event')"
          (closed)="closePannel('event')"
          [displayWith]="getOptionTextEvent"
          (optionSelected)="isChangedQuestion = true"
          #eventAuto="matAutocomplete">
          <mat-option class="no-event-option event-option">
            No event
          </mat-option>
          <mat-option
            class="event-option"
            *ngFor="let event of sportEvents|filter: questionFormGroup.get('sportEventId').value:'name'"
            [value]="event"
            [id]="event.id">
            <div class="mat-option-item event">
              <div class="text-item-part">
                <div class="name">{{event.name}}</div>
                <div class="date body-small-regular">
                  {{event.startedAt|datesFormatPipe: dateFormats.DD_MMMM_YYYY_hh_mmtz}}
                </div>
              </div>

              <div class="icons-part">
                <mat-icon
                  matRipple
                  [matRippleRadius]="10"
                  [matRippleUnbounded]="true"
                  [matRippleCentered]="true"
                  matSuffix class="material-icons material-icons-outlined"
                  (click)="editEvent($event, event)">edit
                </mat-icon>
                <mat-icon
                  matRipple
                  [matRippleRadius]="10"
                  [matRippleUnbounded]="true"
                  [matRippleCentered]="true"
                  matSuffix class="material-icons material-icons-outlined"
                  (click)="deleteEvent($event, event)">delete_outline
                </mat-icon>
              </div>
            </div>

          </mat-option>
          <mat-option class="create-new-item" (click)="openEventModel()">
            <mat-icon class="material-icons">add_circle_outline</mat-icon>
            Create a new event
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error *ngIf="isShowGeneralError('sportEventId', 'required')">
        <i class="fas fa-exclamation-circle"></i>
        <span>{{appData.MANDATORY_EVENT}}</span>
      </mat-error>
    </div>
    <div class="special-form-fields" [ngClass]="{'flex-grid': formField?.length > 1}">
      <ng-container *ngFor="let field of formField; let i = index">
        <ng-container *ngIf="field.type === 'input'">
          <div class="chip-form-field form-field-with-label" [ngClass]="{'last-item': i === 1 || i === 3}">
            <div class="form-field-with-label" [ngClass]="{'last-row': i > 1}">
              <mat-label>{{field.filedLabel}}{{ field.required ? '*' : '' }}</mat-label>
              <div *ngIf="field.inputType === 'number'; else textInput" class="number-input">
                <mat-form-field appearance="outline">
                  <input
                    trim
                    matInput
                    (input)="isChangedQuestion = true"
                    [id]="field.fieldName"
                    [required]="field.required"
                    [placeholder]="'Enter ' + field.filedLabel.toLowerCase()"
                    [type]="'number'"
                    [formControlName]="field.fieldName">
                </mat-form-field>
                <mat-error *ngIf="isShowGeneralError(field.fieldName, 'required')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY}}</span>
                </mat-error>
                <mat-error
                  *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                  isShowGeneralError(field.fieldName, field.fieldName)">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{field.fieldName === 'minValue' ? appData.RANGE_MIN_VALUE_ERROR : appData.RANGE_INCREMENTAL_VALUE_ERROR}}</span>
                </mat-error>
                <mat-error
                  *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                  isShowGeneralError(field.fieldName, 'min')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MIN_RANGE}}</span>
                </mat-error>
                <mat-error
                  *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                  isShowGeneralError(field.fieldName, 'max')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MAX_RANGE}}</span>
                </mat-error>
                <mat-error
                  *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                  !isShowGeneralError(field.fieldName, field.fieldName) &&
                  isShowGeneralError(field.fieldName, field.fieldName + 'Aliquot')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.RANGE_INCREMENTAL_ALIQUOT_ERROR}}</span>
                </mat-error>
              </div>
              <ng-template #textInput>
                <mat-form-field appearance="outline">
                  <input
                    trim
                    matInput
                    (input)="isChangedQuestion = true"
                    [required]="field.required"
                    [placeholder]="'Enter ' + field.filedLabel.toLowerCase()"
                    [type]="'text'"
                    [formControlName]="field.fieldName">
                </mat-form-field>
                <mat-error *ngIf="isShowGeneralError(field.fieldName, 'required')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY}}</span>
                </mat-error>
                <mat-error *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                isShowGeneralError(field.fieldName, 'maxlength')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MAX_LENGHT}}</span>
                </mat-error>
                <mat-error *ngIf="!isShowGeneralError(field.fieldName, 'required') &&
                isShowGeneralError(field.fieldName, 'minlength')">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MIN_LENGHT_2}}</span>
                </mat-error>
              </ng-template>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="field.type === 'chips'">
          <div class="chip-form-field form-field-with-label" [ngClass]="{'last-item': i === 1 || i === 3}">
            <div class="selectors">
              <ng-container [formArrayName]="field.fieldName">
                <mat-label>{{field.filedLabel}}{{ field.required ? '*' : '' }}</mat-label>
                <ng-container *ngFor="let answerForm of this[field.fieldName].controls; let i = index">
                  <div class="answer-with-image">
                    <div class="form-field-with-label" [formGroup]="answerForm">
                      <mat-form-field appearance="fill" class="autocomplete-selector-item">
                        <input
                          trim
                          matInput
                          placeholder="Select answer"
                          aria-label="State"
                          (input)="onAutocompleteInputEvent($event, i)"
                          [matAutocomplete]="auto"
                          formControlName="title"
                          (focusout)="focusOut()"
                          (keyup.enter)="addNewAnswer(field.fieldName, i)">
                        <mat-icon
                          matRipple
                          [matRippleRadius]="15"
                          [matRippleUnbounded]="true"
                          [matRippleCentered]="true"
                          matSuffix
                          *ngIf="isShowDoneEnterIcon(field.fieldName, i)"
                          (click)="addNewAnswer(field.fieldName, i)">
                          done
                        </mat-icon>

                        <mat-icon
                          matRipple
                          [matRippleRadius]="15"
                          [matRippleUnbounded]="true"
                          [matRippleCentered]="true"
                          matSuffix
                          *ngIf="i === this[field.fieldName].controls.length - 1 && isAddingAvailable(field.fieldName) && !isEditingDisable"
                          (click)="addAnswer($event, field.fieldName)">
                          add_circle_outline
                        </mat-icon>

                        <mat-icon
                          matRipple
                          [matRippleRadius]="15"
                          [matRippleUnbounded]="true"
                          [matRippleCentered]="true"
                          matSuffix
                          *ngIf="this[field.fieldName].controls.length > 1 && !isEditingDisable && !newAnswerItemValueEntering[i]"
                          (click)="deleteAnswerItem($event, i, field.fieldName)">
                          clear
                        </mat-icon>
                        <mat-autocomplete
                          (closed)="onCloseAnswerSelector()"
                          [displayWith]="getOptionText"
                          (optionSelected)="answerSelect(i, field.fieldName)"
                          (opened)="openPannel()"
                          (closed)="closePannel()"
                          #auto="matAutocomplete">
                          <mat-option
                            class="answer-option"
                            *ngFor="let state of filteredList|filter: answerForm.controls.title.value:'text'"
                            [value]="state"
                            [id]="state.id">
                            <div *ngIf="state.id === editedId; else regularItem" class="edit-input">
                              <div class="div-with-error"
                                   [ngClass]="{'invalid': showEditAnswerRequiredError || showEditAnswerMaxLenghtError}">
                                <div class="answer-input-container">
                                  <input
                                    trim
                                    [formControlName]
                                    [value]="state.text"
                                    (input)="onAnswerInputChange(username)"
                                    #username
                                    (keydown)="$event.stopPropagation()"
                                    (click)="prevent($event)">
                                </div>
                                <div class="edit-answer-container">
                                  <small-file-loader
                                    [image]="state"
                                    (click)="prevent($event)"
                                    (fileUpload)="uploadAnswerImage($event, i)"
                                    (imageErrorMassage)="imageErrorMassege = $event">
                                  </small-file-loader>
                                  <mat-icon
                                    matRipple
                                    [matRippleRadius]="10"
                                    [matRippleUnbounded]="true"
                                    [matRippleCentered]="true"
                                    matSuffix
                                    class="save-answer-icon"
                                    (click)="saveAnswer($event, username.value, i)">done
                                  </mat-icon>
                                </div>

                              </div>
                              <mat-error *ngIf="showEditAnswerRequiredError">
                                <i class="fas fa-exclamation-circle"></i>
                                <span>{{appData.MANDATORY}}</span>
                              </mat-error>
                              <mat-error *ngIf="showEditAnswerMaxLenghtError">
                                <i class="fas fa-exclamation-circle"></i>
                                <span>{{appData.MAX_LENGHT_100}}</span>
                              </mat-error>
                              <mat-error *ngIf="imageErrorMassege">
                                <i class="fas fa-exclamation-circle"></i>
                                <span>{{appData.BIG_IMAGE}}</span>
                              </mat-error>

                            </div>
                            <ng-template #regularItem>
                              <div class="mat-option-item">
                                <ng-container>
                                  <div class="image-loader-container">
                                    <image-with-loader
                                      *ngIf="state.imageUrl && state.id !== editedId; else emptySpace"
                                      [overlayHeight]="'24px'"
                                      [spinnerSize]="20"
                                      [imageSrc]="state.imageUrl" class="cell-image">
                                    </image-with-loader>
                                  </div>

                                  <ng-template #emptySpace>
                                    <div class="empty-space">

                                    </div>
                                  </ng-template>
                                </ng-container>

                                <div class="single-answer-text">
                                  {{ state.text }}
                                </div>

                                <div class="regular-item-controls-icon">
                                  <mat-icon
                                    matRipple
                                    [matRippleRadius]="10"
                                    [matRippleUnbounded]="true"
                                    [matRippleCentered]="true"
                                    matSuffix class="material-icons material-icons-outlined"
                                    (click)="editAnswer($event, state)">edit
                                  </mat-icon>
                                  <mat-icon
                                    matRipple
                                    [matRippleRadius]="10"
                                    [matRippleUnbounded]="true"
                                    [matRippleCentered]="true"
                                    matSuffix class="material-icons material-icons-outlined delete"
                                    (click)="deleteAnswer($event, state)">delete_outline
                                  </mat-icon>
                                </div>
                              </div>
                            </ng-template>

                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <mat-error *ngIf="isShowAddingError(field.fieldName, i)">
                        <i class="fas fa-exclamation-circle"></i>
                        <span>{{appData.MAX_LENGHT_100}}</span>
                      </mat-error>
                    </div>
                    <div class="image-container" *ngIf="questionFormGroup.get(field.fieldName).value[i].title; else selectedEmptySpace">
                      <image-with-loader
                        *ngIf="questionFormGroup.get(field.fieldName).value[i].title?.imageUrl; else emptySpace"
                        [overlayHeight]="'24px'"
                        [spinnerSize]="20"
                        [imageSrc]="questionFormGroup.get(field.fieldName).value[i].title?.imageUrl" class="cell-image">
                      </image-with-loader>

                      <ng-template #emptySpace>
                        <div class="empty-space" *ngIf="!isShowDoneEnterIcon(field.fieldName, i)"></div>
                      </ng-template>
                      <ng-container *ngIf="isShowDoneEnterIcon(field.fieldName, i)">
                        <small-file-loader
                          [image]="this.newAnswersImages[i]"
                          (click)="prevent($event)"
                          (fileUpload)="uploadAnswerImage($event, i)"
                          (imageErrorMassage)="imageErrorMassege = $event">
                        </small-file-loader>
                      </ng-container>

                    </div>
                    <ng-template #selectedEmptySpace>
                      <div class="empty-space"></div>

                    </ng-template>

                  </div>


                </ng-container>
                <mat-error
                  *ngIf="isShowError(field.fieldName)">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{this.validationRules[this.questionType].massage}}</span>
                </mat-error>
                <mat-error
                  *ngIf="this[field.fieldName + 'SelectedError']">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY_SELECT}}</span>
                </mat-error>
                <mat-error *ngIf="imageErrorMassege">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.BIG_IMAGE}}</span>
                </mat-error>
              </ng-container>

            </div>
          </div>

        </ng-container>
      </ng-container>

    </div>

  </form>
  <div class="control-block">
    <mat-divider></mat-divider>
    <div class="control-panel">
      <button
        mat-raised-button
        color="primary"
        (click)="saveQuestion()"
        [disabled]="question.id && !isChangedQuestion">
        Confirm
      </button>
      <div class="controls-icons">
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="isSingleQuestion || isEditingDisable"
          class="material-icons material-icons-outlined"
          [ngClass]="{'disabled': isSingleQuestion || isEditingDisable}"
          (click)="moveQuestionUp()">arrow_upward
        </mat-icon>
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="isSingleQuestion || isEditingDisable"
          class="material-icons material-icons-outlined"
          [ngClass]="{'disabled': isSingleQuestion || isEditingDisable}"
          (click)="moveQuestionDown()">arrow_downward
        </mat-icon>

        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="isEditingDisable"
          [ngClass]="{'disabled': isEditingDisable}"
          class="material-icons material-icons-outlined"
          (click)="addNewQuestion()">add_circle_outline
        </mat-icon>

        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="isSingleQuestion || isEditingDisable"
          [ngClass]="{'disabled': isSingleQuestion || isEditingDisable}"
          class="material-icons material-icons-outlined"
          (click)="deleteQuestion()"
        >delete_outline
        </mat-icon>
      </div>
    </div>
    <div *ngIf="isUnsavedQuestions && !question.id" class="warning-messege">
      Please, confirm the question before saving the game
    </div>
  </div>
</div>
