<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Add Prize</h2>
      </div>
      <div class="control-header">
        <button
          [disabled]="currentTab === tabs.transfer ? !selectedPrizes?.length || !isLoaded : !uploadedPrize || !isLoaded"
          mat-raised-button
          color="primary"
          (click)="currentTab === tabs.transfer ? onTransferPrize() : onImportPrize()">
          {{buttonName}}
        </button>
      </div>

    </div>
  </div>
  <div class="game-builder-body">
    <div class="game-builder-content">
      <div class="prize-details">
        <div class="prize-tabs-header body-small-medium">Choose how you'd like to add a prize</div>
        <div>
          <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="currentTab" aria-label="Font Style">
            <mat-button-toggle value="Transfer">{{tabs.transfer}}</mat-button-toggle>
            <mat-button-toggle value="Import">{{tabs.import}}</mat-button-toggle>
          </mat-button-toggle-group>
          <ng-container   [ngSwitch]="currentTab">
            <ng-container *ngSwitchCase="tabs.transfer">
              <div class="transfer-description">
                Transferring enables you to transfer NFTs that you already own, contained in another wallet over to Hunch where it can be used as a prize. Once the NFT is transferred it will no longer be available within your external wallet and will be stored securely in Hunch.
              </div>
              <ng-container *ngIf="!personalWalletAddress; else nftGrid">
                <div class="transfer-action-instruction body-small-medium">
                  To transfer an NFT to Hunch you’ll need to link your wallet that contains it.         </div>
                <div>
                  <button mat-raised-button color="primary" (click)="onLinkWallet()">
                    Link a Wallet
                  </button>
                </div>
              </ng-container>
              <ng-template #nftGrid>
                <form [formGroup]="vendorWalletFormGroup">
                    <div class="form-field-with-label transfer">
                      <mat-label>The following wallet is currently linked to you Hunch account</mat-label>
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          placeholder="Enter question text"
                          [formControlName]="'walletAddress'">
                        <div class="unlink-button body-medium-bold" (click)="unlinkWallet()" matSuffix>Unlink</div>

                      </mat-form-field>
                    </div>
                </form>
                <div class="prize-list" *ngIf="personalWalletAddress">
                  <prizes
                    [personalPrizes]="true"
                    [multiSelect]="true"
                    (selectedPrizes)="onSelectedPrize($event)"
                  ></prizes>

                </div>

              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.import">
              <div class="transfer-description">
                Importing enables you to import an NFT you own from a 3rd party marketplace such as Opensea or an NFT stored on the Polygon Blockchain.
              </div>
              <form [formGroup]="transferFormGroup">
                <mat-label class="general-label" *ngIf="source">{{source === 'openseaURL' ? 'Opensea URL' : 'NFT Contract Address (Polygon only)'}}</mat-label>

                <div class="form-field-with-label opensea" *ngIf="showInput; else nftImage">
                  <mat-label>Opensea URL</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      trim
                      placeholder="Opensea URL"
                      [formControlName]="'openseaURL'">
                    <div
                      *ngIf="transferFormGroup.get('openseaURL').value && transferFormGroup.get('openseaURL').valid"
                      class="unlink-button body-medium-bold" (click)="uploadImage('openseaURL')" matSuffix>Upload</div>
                  </mat-form-field>
                  <mat-error
                    class="select-error"
                    *ngIf="isShowError('openseaURL', 'pattern')">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="text">{{appData.OPENSEA_URL}}</span>
                  </mat-error>

                </div>
                <div *ngIf="showInput" class="divider">
                  <mat-divider></mat-divider>
                  <div class="or body-medium-bold">OR</div>
                  <mat-divider></mat-divider>
                </div>
                <div class="contract-adress" *ngIf="showInput" >
                  <div class="form-field-with-label transfer">
                    <mat-label>Contract Address (Polygon only)</mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        trim
                        matInput
                        placeholder="Contract Address (Polygon only)"
                        [formControlName]="'NFTContractAddress'">
                    </mat-form-field>
                    <mat-error
                      class="select-error"
                      *ngIf="isShowError('NFTContractAddress', 'pattern')">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="text">{{appData.CONTRACT_ADDRESS}}</span>
                    </mat-error>
                    <mat-error
                      class="select-error"
                      *ngIf="this.transferFormGroup.getError('required') && !this.transferFormGroup.get('NFTContractAddress').value">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="text">{{appData.MANDATORY}}</span>
                    </mat-error>
                  </div>
                  <div *ngIf="showInput" class="form-field-with-label transfer">
                    <mat-label>TokenId</mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        trim
                        matInput
                        placeholder="Token Id"
                        [formControlName]="'tokenId'">
                      <div
                        *ngIf="transferFormGroup.get('tokenId').value && transferFormGroup.get('NFTContractAddress').value && transferFormGroup.valid"
                        class="unlink-button body-medium-bold"
                        (click)="uploadImage('NFTContractAddress')" matSuffix>
                        Upload
                      </div>
                    </mat-form-field>
                    <mat-error
                      class="select-error"
                      *ngIf="isShowError('tokenId', 'pattern')">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="text">{{appData.INTEGER_VALUE}}</span>
                    </mat-error>
                    <mat-error
                      class="select-error"
                      *ngIf="this.transferFormGroup.getError('required') && !this.transferFormGroup.get('tokenId').value">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="text">{{appData.MANDATORY}}</span>
                    </mat-error>
                  </div>
                </div>

                <ng-template #nftImage>
                  <div class="nft-image-preview-container">
                    <div class="ngt-image-preview">
                      <image-with-loader
                        [spinnerSize]="60"
                        [overlayHeight]="'200px'"
                        [imageSrc]="uploadedPrize.imageUrl">

                      </image-with-loader>
                      <div class="label-toolbar">
                        <div class="icon" (click)="deleteUploadedImage()">
                          <mat-icon
                            class="material-icons material-icons-outlined">clear
                          </mat-icon>
                        </div>

                      </div>

                    </div>
                    <div class="name body-big-bold">{{uploadedPrize.name}}</div>
                  </div>
                </ng-template>
              </form>

              <ng-container *ngIf="!personalWalletAddress; else linkedWallet">
                <div class="transfer-action-instruction">
                  <div class="body-small-regular">
                    Before you can import from a 3rd party, you’ll need to link your wallet to Hunch. This  enables us to verify that you are the owner of the NFT.
                  </div>
                </div>
                <button mat-raised-button color="primary" (click)="onLinkWallet()">
                  Link a Wallet
                </button>
              </ng-container>
              <ng-template #linkedWallet>
                <form [formGroup]="vendorWalletFormGroup">
                  <div class="form-field-with-label transfer">
                    <mat-label>The following wallet is currently linked to you Hunch account</mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        placeholder="Enter question text"
                        [formControlName]="'walletAddress'">
                      <div class="unlink-button body-medium-bold" (click)="unlinkWallet()" matSuffix>Unlink</div>

                    </mat-form-field>
                  </div>
                </form>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
