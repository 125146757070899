export class QuestionRequestDetails {
  id: number
  gameName: string
  order: number
  type: string
  text: string
  sportEvent: SportEventItem
  answers: AnswersRequest[]
  roundId?:number
  attributes?: Attributes
}
export class QuestionResultDetailsModel {
  id: number
  answers:AnswersRequest[]
  order: number
  text: string
  sportEvent: SportEventItem
  status: string
  correctAnswer: CorrectAnswerItem
  type: string;
}

export class AnswersRequest {
  id: number
  text: string
  groupName?: string
}

export class CorrectAnswerItem {
  value: string
  score: string
  answerId: number
  answer?: string
  id: number
}

export class SportEventItem {
  id: number
  name: string
  teams?: {
    away: string
    home: string
  }
}
export class Attributes {
  minValue: number
  maxValue: string
  incrementValue: string
}

export class SubmitAnswer {
  questionId: number
  score?: string
  value?: number
  answerId?: number
}

export interface AnswerItem {
  id: number,
  text: string
}
