import {Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {APP_DATA} from "../../../general.app.config";
import {TextStreakQuestionDetailsModel} from "../../../common/models/TextStreakQuestionDetailsModel";
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {MoveDirectionEnum} from "../../../common/Enums/MoveDirectionEnum";
import {QuestionStatusEnum} from "../../../common/Enums/QuestionStatusEnum";
import {AnswerService} from "../../../core/services/answer.service";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {cloneDeep} from "lodash";

@Component({
  selector: 'single-text-streak-question',
  templateUrl: './single-text-streak-question.component.html',
  styleUrls: ['./single-text-streak-question.component.scss']
})
export class SingleTextStreakQuestionComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Is current question is not saved
   */
  isUnsavedQuestion: boolean;

  /**
   * Set is current question is not saved
   */
  @Input() set _isUnsavedQuestion(flag) {
    this.isUnsavedQuestion = flag;
  }

  /**
   * Current question
   */
  question: any;

  /**
   * Set current question
   */
  @Input() set _question(q) {
    this.question = q;
  }

  /**
   * Index of current question
   */
  @Input() index: number;

  /**
   * All questions list
   */
  @Input() questions: any;

  /**
   * Current round id
   */
  @Input() roundId: number;

  /**
   * Output event is current question was changed
   */
  @Output() changedQuestion = new EventEmitter<TextStreakQuestionDetailsModel>();


  /**
   * Output event for moving question
   */
  @Output() moveQuestion = new EventEmitter<MoveDirectionEnum>();

  /**
   * Output event for track question validity
   */
  @Output() isValidQuestion = new EventEmitter<boolean>();

  questionFormGroup: FormGroup;

  /**
   * Save is question was changed
   */
  isChangedQuestion = false;

  appData = APP_DATA;

  answerOptions = ['YES', 'NO'];

  /**
   * Saved list of question status
   */
  QuestionStatusEnum = QuestionStatusEnum;

  disableMoveUp = false;

  /**
   * Is current day has close status (cant be edited or set correct answer)
   */
  isCloseDay = false;

  /**
   * Is current day has completed status (cant be edited but can set correct answer)
   */
  isCompleteDay = false;

  isSubmitCorrectDisabled = false;

  isAnswerAbleToSubmit = true;

  isAnswerWasSubmitted = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private answerService: AnswerService,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.isCloseDay = this.question.status === QuestionStatusEnum.CLOSED;
    this.isCompleteDay = this.question.status === QuestionStatusEnum.COMPLETED;
    this.questionFormGroup = new FormGroup({
      questionOneText: new FormControl(
        {
          value: this.question.questionOneText,
          disabled: this.question.status !== QuestionStatusEnum.CREATED
        },
        [Validators.required, Validators.maxLength(255)]
      ),
      answerOptions1: new FormControl(
        {
          value: this.isCloseDay || this.isCompleteDay ? this.question.questionOneCorrectAnswer : this.answerOptions,
          disabled: !this.isCloseDay || this.question.status === QuestionStatusEnum.COMPLETED
        },
        [Validators.required]),
      questionTwoText: new FormControl({
          value: this.question.questionTwoText,
          disabled: this.question.status !== QuestionStatusEnum.CREATED
        },
        [Validators.required, Validators.maxLength(255)]),
      answerOptions2: new FormControl(
        {
          value: this.isCloseDay || this.isCompleteDay ? this.question.questionTwoCorrectAnswer : this.answerOptions,
          disabled: !this.isCloseDay || this.question.status === QuestionStatusEnum.COMPLETED
        },
        [Validators.required]),
    });
    this.isAnswerAbleToSubmit = !(!!this.question.questionTwoCorrectAnswer) && !(!!this.question.questionOneCorrectAnswer);
    this.isAnswerWasSubmitted = !!this.question.questionTwoCorrectAnswer && !!this.question.questionOneCorrectAnswer;
    this.isSubmitCorrectDisabled = this.question.status === QuestionStatusEnum.COMPLETED || this.isAnswerWasSubmitted;
    this.questionFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isValidQuestion.emit(this.questionFormGroup.valid);
        this.question = {...this.question, ...response}
        this.changedQuestion.emit(this.question);
        this.isAnswerAbleToSubmit = true;
        this.isSubmitCorrectDisabled = false;
      });
    this.disableMoveUp = this.index ? this.questions[this.index - 1].status !== QuestionStatusEnum.CREATED : this.question.status !== QuestionStatusEnum.CREATED;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isUnsavedQuestion) {
      this.questionFormGroup.markAllAsTouched();
    }
    this.disableMoveUp = this.index ? this.questions[this.index - 1].status !== QuestionStatusEnum.CREATED : this.question.status !== QuestionStatusEnum.CREATED;
  }

  /**
   * Control showing error
   */
  isShowGeneralError(fieldName, errorName) {
    if (!this.questionFormGroup.get(fieldName).touched) return false;
    return this.questionFormGroup.get(fieldName).hasError(errorName);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * On move question up
   */
  moveQuestionUp() {
    if (this.question.status !== QuestionStatusEnum.CREATED || this.disableMoveUp || this.questions.length === 1) return;
    this.moveQuestion.emit(MoveDirectionEnum.UP);
  }

  /**
   * On move question down
   */
  moveQuestionDown() {
    if (this.question.status !== QuestionStatusEnum.CREATED || this.questions.length === 1) return;
    this.moveQuestion.emit(MoveDirectionEnum.DOWN);
  }

  /**
   * Submit correct answer to the current day question
   * Create correct answer body for one and for second question
   * If answers was submitted earlier, resubmit this correct answer
   */
  submitCorrectAnswer() {
    const answerOneValue = this.questionFormGroup.get('answerOptions1').value;
    const answerTwoValue = this.questionFormGroup.get('answerOptions2').value;
    if (!answerOneValue || !answerTwoValue) {
      this.questionFormGroup.markAllAsTouched();
    }
    const {roundId} = this;
    const correctAnswerOne = {
      questionId: this.question.questionOneId,
      answerId: answerOneValue.toLowerCase() === 'yes' ? 1 : 2,
      newQuestionId: this.question.questionOneNewId
    };
    const correctAnswerTwo = {
      questionId: this.question.questionTwoId,
      answerId: answerTwoValue.toLowerCase() === 'yes' ? 1 : 2,
      newQuestionId: this.question.questionTwoNewId
    };
    const correctAnswers = [correctAnswerOne, correctAnswerTwo];
    if (this.isAnswerWasSubmitted) {
      combineLatest([
        this.answerService.resubmitCorrectAnswer({answerId: correctAnswers[0].answerId}, correctAnswers[0].newQuestionId),
        this.answerService.resubmitCorrectAnswer({answerId: correctAnswers[1].answerId}, correctAnswers[1].newQuestionId)
      ])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
            this.snackBarService.showSnackBar('Answers submitted');
            this.isSubmitCorrectDisabled = true;
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
          });

    } else {
      this.answerService.bulkSubmitCorrectAnswers({roundId, correctAnswers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((submittedAnswers:[{questionId: number, correctAnswerId: number}]) => {
            this.snackBarService.showSnackBar('Answers submitted');
            this.isSubmitCorrectDisabled = true;
            this.isAnswerWasSubmitted = true;
            this.updateQuestion(submittedAnswers);
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
          });
    }
  }

  updateQuestion(correctAnswers) {
    correctAnswers.forEach(correctAnswer => {
      if (this.question.questionOneId === correctAnswer.questionId) {
        this.question.questionOneNewId = correctAnswer.correctAnswerId;
      }
      if (this.question.questionTwoId === correctAnswer.questionId) {
        this.question.questionTwoNewId = correctAnswer.correctAnswerId;
      }
    })
  }
}
