import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {PrizeDetailsModel} from "../../common/models/PrizeDetailsModel";
import {StreakRoundDetalsModel} from "../../common/models/StreakRoundDetalsModel";
import {StreakPrizeDayDitailsModel} from "../../common/models/StreakPrizeDayDitailsModel";
import {PersonalPrizeDitailsModel} from "../../common/models/PersonalPrizeDitailsModel";

@Injectable({
  providedIn: 'root'
})
export class PrizeService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);


  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getPrizeList(requestBody): Observable<{ total: number, records: PrizeDetailsModel[] }> {
    let params = new HttpParams()
    if (requestBody) {
      Object.keys(requestBody).forEach(param => {
        if (param === 'includeIds') {
          params = params.set(param, requestBody[param].join(','));
        }
        params = params.set(param, requestBody[param]);
      });
      return this.http
        .get<{ total: number, records: PrizeDetailsModel[] }>(this.uriConfig.prizes, { params });
    } else {
      return this.http
        .get<{ total: number, records: PrizeDetailsModel[] }>(this.uriConfig.prizes);
    }

  }

  transferPrize(prize) {
    return this.http.post(this.uriConfig.prizes, prize)
  }

  getStreakRoundById(roundId:number): Observable<StreakPrizeDayDitailsModel[]> {
    return this.http.get<StreakPrizeDayDitailsModel[]>(this.uriConfig.streaks + '/' +roundId);
  }

  updateOpenedStreakRound(roundId:number, dayBody) {
    return this.http.put(this.uriConfig.streaks + '/' +roundId, dayBody);
  }

  getPersonalPrizeList(body):Observable<{records: PersonalPrizeDitailsModel[], pageKey: string}> {
    let params = new HttpParams()
    if (body) {
      Object.keys(body).forEach(param => {
        params = params.set(param, body[param])
      })
      return this.http.get<{records: PersonalPrizeDitailsModel[], pageKey: string}>(this.uriConfig.personalPrizes, {params});
    } else {
      return this.http.get<{records: PersonalPrizeDitailsModel[], pageKey: string}>(this.uriConfig.personalPrizes);
    }
  }

  getPersonalPrizeInfo(contractAddress, tokenId): Observable<PersonalPrizeDitailsModel> {
    return this.http.get<PersonalPrizeDitailsModel>(this.uriConfig.personalPrizes + '/' + contractAddress + '/' + tokenId);
  }

  archivePrize(id) {
    return this.http.patch(this.uriConfig.prizes + '/archive/' + id, {})
  }

  needUpdatePrizeList() {
    this.isNeedUpdate$.next(true);
  }

  eventPrizeUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getPrizeListStatus() {
    return this.isNeedUpdate$;
  }
}
