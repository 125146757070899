import { AbstractControl } from "@angular/forms";

export const  toPositionValidator = (control: AbstractControl) => {
  if (!control.get('toPosition')?.pristine && !Number.isNaN(control.get('toPosition')?.value)) {
    if (Number(control.get('toPosition')?.value) <= 0) {
      control.get('toPosition')
        .setErrors({toPositionError: 'To Place must not be less than 1'});
    } else if (Number(control.get('fromPosition')?.value) > Number(control.get('toPosition')?.value)) {
      control.get('toPosition')
        .setErrors({toPositionError: 'To Place must always be equal or greater than From Place'});
    } else {
      control.get('toPosition').setErrors(null);
    }
  }
  return null;
}