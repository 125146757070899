import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {APP_DATA} from "../../../general.app.config";
import {TooltipPositionEnum} from '../../Enums/TooltipPositionEnum';
import {FilesService} from "../../../core/services/files.service";

@Component({
  selector: 'video-drag-and-drop',
  templateUrl: './video-drag-and-drop.component.html',
  styleUrls: ['./video-drag-and-drop.component.scss']
})
export class VideoDragAndDropComponent implements OnInit, OnDestroy {

  constructor(
    private snackBarService: SnackBarService,
    private filesService: FilesService
  ) {
  }

  @Input() title: string;

  @Input() isRequired: boolean;

  @Input() isDisabled: boolean;

  @Input() tooltipText: string;

  @Input() currentVideoTime: number;

  /**
   * If current component opening with existing video assing this video to show it
   */
  @Input() set video(video) {
    if (video?.link) {
      this.uploadedFiles = video;
    }
  }

  /**
   * Send event with uploaded video data (url and id)
   */
  @Output() fileUpload = new EventEmitter<any>();

  @Output() timeUpdated = new EventEmitter<number>();

  TooltipPositionEnum = TooltipPositionEnum;

  /**
   * Use for show processing styles
   */
  processing: boolean;

  /**
   * Indicator to show loader
   */
  isLoading = false;

  /**
   * Assigned uploaded file to show image
   */
  uploadedFiles: any;

  appData = APP_DATA;

  isVideoUploaded = false;

  progressValue = 0;

  /**
   * Flag to show to big size (16mb) error
   */
  isImageTooBig = false;

  private unsubscribe$: Subject<void> = new Subject();

  @ViewChild('player') videoPlayer: ElementRef;
  @ViewChild('progressBar') progressBar: ElementRef;

  /**
   * If drag event canceled
   */
  onDragOver(event) {
    event.preventDefault();
  }

  /**
   * If drag event finished
   */
  onDropSuccess(event) {
    event.preventDefault();
    if (this.isDisabled) return;

    this.onFileChange(event);
  }

  /**
   * If input event field vas changed in any way(drag and drop, click)
   */
  onChange(event) {
    this.uploadImage(event, 'test');
  }

  private onFileChange(files) {
    this.uploadImage(files, 'ttest')
  }

  uploadImage(event, name) {
    this.isLoading = true;
    const files = event.dataTransfer || event.target;
    this.uploadFile(files.files[0], name);
  }

  /**
   * Create body for saving image, get image size
   */
  uploadFile(file, field) {
    this.isImageTooBig = file.size > ((1024*1024) * 16);
    if (this.isImageTooBig) {
      this.isLoading = false;
      return;
    }

    let formdata = new FormData();
    formdata.append("file", file, file.name);
    this.filesService.uploadVideo(formdata)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(uploadedFile => {
        this.uploadedFiles = null;
        setTimeout(() => {
          this.isLoading = false;
          this.uploadedFiles = uploadedFile;
          this.fileUpload.emit(uploadedFile);
          this.progressValue = 0;
          this.timeUpdated.emit(0);
          this.currentVideoTime = 0;
        }, 0);
        },
        err => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(err.error.message, true);
        });
  }

  /**
   * Delete uploaded file
   */
  deleteVideo() {
    this.uploadedFiles = null;
    this.fileUpload.emit(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
  }

  /**
   *  Listen event when video is loaded on view
   */
  onVideoLoad() {
    if (this.currentVideoTime) {
      this.videoPlayer.nativeElement.currentTime = this.currentVideoTime;
      this.progressValue = (this.currentVideoTime  * 100) / this.videoPlayer.nativeElement.duration;
    }
    this.isVideoUploaded = true;
  }

  /**
   *  Send event when slider postion is changed
   */
  timeUpdate(e) {
    this.progressValue = e;
    const time = ( e / 100 ) * this.videoPlayer.nativeElement.duration;
    this.videoPlayer.nativeElement.currentTime = time;
    this.timeUpdated.emit(time);
  }
}
