import {APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {ManyErrorSnackBarComponent} from "./common/components/many-error-snack-bar/many-error-snack-bar.component";
import {AppRoutingModule} from "./app-routing.module";
import {AuthGuard} from "./core/guards/auth.guard";
import {authInterceptorProviders} from "./core/interseptors/auth.interceptor";
import {UriConfig} from "./app.config";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {CoreModule} from "./core/core.module";
import {CommonModule} from "@angular/common";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular";
import {MaterialModule} from "./material/material.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { NgxMatMomentModule,
} from "@angular-material-components/moment-adapter";
import {PipesModule} from "./common/modules/pipes/pipes.module";
import {MatIconModule} from "@angular/material/icon";
import {CreateNewRoundModalComponent} from "./create-new-round-modal/create-new-round-modal.component";
import {GameDetailsComponent} from './create-new-round-modal/game-details/game-details.component';
import {QuestionsComponent} from './create-new-round-modal/questions/questions.component';
import {CommonComponentsModule} from "./common/components/common-components.module";
import {
  SingleQuestionItemComponent
} from './create-new-round-modal/questions/single-question-item/single-question-item.component';
import {DirectivesModule} from "./common/modules/directives/directives.module";
import {DialogComponent} from "./common/components/dialog/dialog.component";
import {StreakPrizeComponent} from './create-new-round-modal/streak-prize/streak-prize.component';
import {
  SingleStreakPrizeComponent
} from './create-new-round-modal/streak-prize/single-streak-prize/single-streak-prize.component';
import {
  TextStreakQuestionComponent
} from './create-new-round-modal/text-streak-question/text-streak-question.component';
import {
  SingleTextStreakQuestionComponent
} from './create-new-round-modal/text-streak-question/single-text-streak-question/single-text-streak-question.component';
import {
  SetCorrectQuestionComponent
} from './create-new-round-modal/set-correct-question/set-correct-question.component';
import {
  SingleCorrectQuestionComponent
} from './create-new-round-modal/set-correct-question/single-correct-questtion/single-correct-question.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { initializeAppFactory } from './initializeAppFactory';
import {SaasSettingsService} from "./core/services/saas-settings.service";
import { AuthService } from './core/services/auth.service';
import {LocalStorageService} from "./core/services/local-storage.service";

@NgModule({
  declarations: [
    AppComponent,
    ManyErrorSnackBarComponent,
    CreateNewRoundModalComponent,
    GameDetailsComponent,
    QuestionsComponent,
    SingleQuestionItemComponent,
    StreakPrizeComponent,
    SingleStreakPrizeComponent,
    TextStreakQuestionComponent,
    TextStreakQuestionComponent,
    SingleTextStreakQuestionComponent,
    SetCorrectQuestionComponent,
    SingleCorrectQuestionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    NgxMatMomentModule,
    CommonComponentsModule,
    PipesModule,
    DirectivesModule,
    MatTooltipModule
  ],
  providers: [
    AuthGuard,
    authInterceptorProviders,
    UriConfig,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [SaasSettingsService, HttpClient, AuthService, LocalStorageService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule {
}
