import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberInputDirective} from './directives/number-input.directive';
import {TrimDirective} from "./directives/trim.directive";


@NgModule({
  declarations: [
    NumberInputDirective,
    TrimDirective
  ],
  exports: [
    NumberInputDirective,
    TrimDirective
  ],
  imports: [
    CommonModule,
  ]
})
export class DirectivesModule {
}
