import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {EventDetailsModel} from "../../common/models/EventDetailsModel";
import {TeamDetailsModel} from "../../common/models/TeamDetailsModel";
import {CreateEventDetailsModel} from "../../common/models/CreateEventDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  isNeedUpdateTeamList$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  //TODO when implement events fix it
  getEventsList(requestBody =  {}): Observable<{records: EventDetailsModel[], total: number }> {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param])
    })
    return this.http.get<{records: EventDetailsModel[], total: number }>(this.uriConfig.sportEvents, { params });
  }

  createNewEvent(event: CreateEventDetailsModel): Observable<EventDetailsModel> {
    return this.http.post<any>(this.uriConfig.sportEvents, event);
  }

  deleteEventById(eventId:number) {
    return this.http.delete<any>(this.uriConfig.sportEvents + '/' + eventId);
  }

  updateEvent(eventId: number, event: CreateEventDetailsModel): Observable<EventDetailsModel> {
    return this.http.patch<any>(this.uriConfig.sportEvents + '/' + eventId, event);
  }

  getTeamsList(): Observable<TeamDetailsModel[]> {
    return this.http.get<TeamDetailsModel[]>(this.uriConfig.teams);
  }

  createNewTeam (name:string): Observable<TeamDetailsModel> {
    return this.http.post<any>(this.uriConfig.teams, {name});
  }

  deleteTeamById(teamId:number) {
    return this.http.delete<any>(this.uriConfig.teams + '/' + teamId);
  }

  updateTeam(name: string, teamId: number): Observable<TeamDetailsModel> {
    return this.http.patch<any>(this.uriConfig.teams + '/' + teamId, {name});
  }


  needUpdateEventList() {
    this.isNeedUpdate$.next(true);
  }

  eventListUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getEventListStatus() {
    return this.isNeedUpdate$;
  }


  needUpdateTeamList() {
    this.isNeedUpdateTeamList$.next(true);
  }

  teamListUpdated() {
    this.isNeedUpdateTeamList$.next(false);
  }

  getTeamListStatus() {
    return this.isNeedUpdateTeamList$;
  }
}
