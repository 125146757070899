<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">{{title}}</h2>
        <status-chip [status]="data.state"  *ngIf="data">
          {{textService.capitalizeFirstLetter(data.state)}}
        </status-chip>
      </div>
      <div class="control-header">
        <button
          [disabled]="(!this.vendorDataFormGroup?.valid || !isLoaded) && isButtonWasClicked"
          mat-raised-button
          color="primary"
          (click)="saveVendor()">
          Save
        </button>
      </div>
    </div>
  </div>
  <div class="game-builder-body">
    <div class="game-builder-content">
      <div class="prize-details">
        <form [formGroup]="vendorDataFormGroup">
          <!--          Contacts group-->

          <div class="form-field-group">
            <div class="form-field-with-label">
              <mat-label>Phone</mat-label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  trim
                  onkeypress="return /[0-9+]/i.test(event.key)"
                  matInput
                  placeholder="Enter phone"
                  [formControlName]="'phone'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('phone', 'required') && isShowError('phone','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_32}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('phone', 'required') && isShowError('phone','invalidPhone')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.PHONE_ERROR}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Email*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  placeholder="Enter email"
                  [formControlName]="'email'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('email', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('email', 'required') && isShowError('email','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_70}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('email', 'required') && isShowError('email','email')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.EMAIL_ERROR}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="isShowError('email','server')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{serverErrorMassage}}</span>
              </mat-error>
            </div>
          </div>

          <!--          Name group-->
          <div class="form-field-group">
            <div class="form-field-with-label">
              <mat-label>First Name*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  placeholder="Enter first name"
                  [formControlName]="'firstName'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('firstName', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_15}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','minlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MIN_LENGHT_3}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Last Name*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  placeholder="Enter last name"
                  [formControlName]="'lastName'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('lastName', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_15}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','minlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MIN_LENGHT_3}}</span>
              </mat-error>
            </div>
          </div>

          <!--          Wallet address group-->
          <div class="form-field-group">
            <div class="form-field-with-label" *ngIf="data">
              <mat-label>Hunch Wallet Address</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Enter Hunch wallet Address"
                  [formControlName]="'walletAddress'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('walletAddress','pattern')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.WALLET}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Personal wallet address</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  placeholder="Enter personal wallet address"
                  [formControlName]="'personalWalletAddress'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('personalWalletAddress','pattern')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.WALLET}}</span>
              </mat-error>
            </div>
          </div>

          <div class="form-field-with-label">
            <mat-label>Brand Name*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                placeholder="Enter brand name"
                [formControlName]="'name'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('name', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('name', 'required') && isShowError('name','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('name', 'required') && isShowError('name','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Brand Description*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                placeholder="Enter brand description"
                [formControlName]="'description'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('description', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('description', 'required') && isShowError('description','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_200}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('description', 'required') && isShowError('description','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>

          <!--          Brand images group-->
          <div class="form-field-group">
            <drag-and-drop-file
              class="brand-image-popup"
              [isRequired]="true"
              [_isMandatoryError]="isButtonWasClicked && !vendorDataFormGroup.get('imageId').value"
              [tooltipText]="'We recommend using a 270px x 200px image resolution (minimum). Max image size is 4MB'"
              [isDisabledDelete]="true"
              [image]="{id: image?.id, url: image?.url}"
              [title]="'Brand Image'"
              (file)="onCreateFile($event, 'brandImage')"
              (fileUpload)="uploadFile($event, 'image')"
            ></drag-and-drop-file>

            <drag-and-drop-file
              class="background-image-popup"
              [isRequired]="false"
              [isDisabledDelete]="true"
              [tooltipText]="'We recommend using a 1000px x 200px image resolution (minimum). Max image size is 4MB'"
              [title]="'Brand Background Image'"
              [image]="{id: backgroundImage?.id, url: backgroundImage?.url}"
              (file)="onCreateFile($event, 'brandBackgroundImage')"
              (fileUpload)="uploadFile($event, 'backgroundImage')"
            ></drag-and-drop-file>
          </div>
          <div class="form-field-with-label">
            <mat-label>Brand Upsell Title</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                placeholder="Enter brand upsell title"
                [formControlName]="'upsellTitle'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellTitle','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_52}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellTitle','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Brand Upsell Description</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                placeholder="Enter brand upsell description"
                [formControlName]="'upsellDescription'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellDescription','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_255}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellDescription','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Brand Upsell Source Url</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                placeholder="Enter brand upsell source url"
                [formControlName]="'upsellSourceUrl'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellSourceUrl','url')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.URL}}</span>
            </mat-error>
          </div>

          <drag-and-drop-file
            class="upsell-image-popup"
            [isRequired]="false"
            [isDisabledDelete]="true"
            [tooltipText]="'We recommend using a 500px x 205px image resolution (minimum). Max image size is 4MB'"
            [title]="'Brand Upsell Image'"
            [image]="{id: upsellImage?.id, url: upsellImage?.url}"
            (file)="onCreateFile($event, 'upsellImage')"
            (fileUpload)="uploadFile($event, 'upsellImage')"
          ></drag-and-drop-file>
        </form>

      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
