import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CustomValidators} from 'ng2-validation';
import { parsePhoneNumber } from 'awesome-phonenumber'
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {APP_DATA} from "../../../general.app.config";
import {ProfileService} from "../../../core/services/profile.service";
import {AuthService} from "../../../core/services/auth.service";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {AdminsService} from "../../../core/services/admins.service";
import {VendorSettingDetailsModel} from "../../models/VendorSettingDetailsModel";
import {VendorStatusEnum} from '../../Enums/VendorStatusEnum';
import {TextService} from "../../../core/services/text.service";
import {b2cAvatar} from "../../models/ExpandedB2CUserItemDetailsModel";
import {FormService} from "../../../core/services/form.service";

@Component({
  selector: 'create-vendor-modal',
  templateUrl: './create-vendor-modal.component.html',
  styleUrls: ['./create-vendor-modal.component.scss']
})
export class CreateVendorModalComponent implements OnInit, OnDestroy {
  /**
   * Indicator to show loader
   */
  isLoaded = true;

  title = 'New Vendor';

  vendorDataFormGroup: FormGroup;

  appData = APP_DATA;

  image: b2cAvatar;

  backgroundImage: b2cAvatar;

  upsellImage: b2cAvatar;

  isButtonWasClicked = false;

  serverErrorMassage: string;

  formErrors$ = new BehaviorSubject<{ massage, field }>(null);

  isServerError = false;

  fileImages: {
    upsellImage?:any
    brandImage?: any
    brandBackgroundImage?:any
  }


  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CreateVendorModalComponent>,
    private profileService: ProfileService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private adminsService: AdminsService,
    public textService: TextService,
    private formsService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.isLoaded = false;
    this.buildForm();
    this.formErrors$
      .subscribe((errorObject) => {
        if (!errorObject) return;

        if (errorObject.massage) {
          const {massage, field} = errorObject;
          this.isServerError = true;
          this.formsService.bindErrors(massage, this.vendorDataFormGroup, field);
          this.serverErrorMassage = massage;
        } else {
          const {field} = errorObject;
          this.isServerError = false;
          this.formsService.bindErrors(null, this.vendorDataFormGroup, field);
          this.serverErrorMassage = null;
        }
      });

    this.vendorDataFormGroup.get('email').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.isServerError) {
          this.formErrors$.next(null);
        }
      });
  }

  buildForm() {
    this.vendorDataFormGroup = new FormGroup({
      email: new FormControl({value: null, disabled: !!this.data}, [Validators.required, Validators.email, Validators.maxLength(70)]),
      phone: new FormControl(null, [
        (control) => {
          if (!control.value) return ;
          const valueE164 = "+" + control.value?.replace(/\D/g, "");

          const phoneNumber = parsePhoneNumber(valueE164);
          if (phoneNumber.valid) {
            return null;
          } else {
            return { invalidPhone: { value: control.value } };
          }
        },
        Validators.maxLength(32)]),
      firstName: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(3)]),
      lastName: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(3)]),
      upsellTitle: new FormControl('', [Validators.maxLength(52), Validators.minLength(2)]),
      upsellDescription: new FormControl('', [Validators.maxLength(255), Validators.minLength(2)]),
      upsellSourceUrl: new FormControl('', [CustomValidators.url]),
      upsellImageUrl: new FormControl(''),
      upsellImageId: new FormControl(null),
      name: new FormControl('', [Validators.maxLength(20), Validators.required, Validators.minLength(2)]),
      description: new FormControl('', [Validators.maxLength(200), Validators.required, Validators.minLength(2)]),
      imageId: new FormControl(null,[Validators.required]),
      imageUrl: new FormControl('',),
      backgroundImageId: new FormControl(null,),
      backgroundImageUrl: new FormControl('',),
      personalWalletAddress: new FormControl({value: null, disabled: !!this.data}, [Validators.pattern(/^0[xX][0-9a-fA-F]{40}$/)]),
      walletAddress: new FormControl({value: null, disabled: true}, [Validators.pattern(/^0[xX][0-9a-fA-F]{40}$/)]),
    });

    this.vendorDataFormGroup.touched
    if (this.data) {
      this.title = 'Edit Vendor';
      this.adminsService.getVendorDetailsById(this.data.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userData: VendorSettingDetailsModel) => {
            this.fillForm(userData);
            this.isLoaded = true;
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            this.isLoaded = true;

          })
    } else {
      this.isLoaded = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }

  fillForm(userData) {
    const brand = userData.brands && userData.brands[0];
    this.vendorDataFormGroup.get('email').patchValue(userData.email);
    this.vendorDataFormGroup.get('phone').patchValue(userData.phone);
    this.vendorDataFormGroup.get('firstName').patchValue(userData.firstName);
    this.vendorDataFormGroup.get('lastName').patchValue(userData.lastName);
    this.vendorDataFormGroup.get('personalWalletAddress').patchValue(userData.personalWalletAddress);
    this.vendorDataFormGroup.get('walletAddress').patchValue(userData.walletAddress);
    if (!brand) return;
    this.vendorDataFormGroup.get('upsellTitle').patchValue(brand.upsellTitle);
    this.vendorDataFormGroup.get('upsellDescription').patchValue(brand.upsellDescription);
    this.vendorDataFormGroup.get('upsellSourceUrl').patchValue(brand.upsellSourceUrl);
    this.vendorDataFormGroup.get('upsellImageId').patchValue(brand.upsellImageId);
    this.vendorDataFormGroup.get('upsellImageUrl').patchValue(brand.upsellImageUrl);
    this.vendorDataFormGroup.get('name').patchValue(brand.name);
    this.vendorDataFormGroup.get('description').patchValue(brand.description);
    this.vendorDataFormGroup.get('imageId').patchValue(brand.imageId);
    this.vendorDataFormGroup.get('imageUrl').patchValue(brand.imageUrl);
    this.vendorDataFormGroup.get('backgroundImageId').patchValue(brand.backgroundImageId);
    this.vendorDataFormGroup.get('backgroundImageUrl').patchValue(brand.backgroundImageUrl);
    this.upsellImage = {id: brand.upsellImageId, url: brand.upsellImageUrl};
    this.image = {id: brand.imageId, url: brand.imageUrl};
    this.backgroundImage = {id: brand.backgroundImageId, url: brand.backgroundImageUrl};
  }

  saveVendor() {
    this.isButtonWasClicked = true;
    if (!this.vendorDataFormGroup.valid) {
      this.vendorDataFormGroup.markAllAsTouched();
      return;
    }
    this.isLoaded = false;
    let request$;
    const {
      email,
      phone,
      firstName,
      lastName,
      upsellTitle,
      upsellDescription,
      upsellSourceUrl,
      upsellImageId,
      name,
      description,
      imageId,
      backgroundImageId,
      personalWalletAddress
    } = this.vendorDataFormGroup.value;

    if (this.data) {
      const profile = {email, phone, firstName, lastName};

      const brand = {
        name,
        description,
        upsellTitle,
        upsellDescription,
        upsellSourceUrl,
        imageId,
        backgroundImageId,
        upsellImageId
      };
      request$ = this.adminsService.updateVendorProfile({profile, brand}, this.data.id)
    } else {
      const createVendorBody = {
        brandName: name,
        brandDescription: description,
        ...this.vendorDataFormGroup.value,
        ...this.fileImages
      }
      delete createVendorBody.name;
      delete createVendorBody.description;
      request$ = this.adminsService.createVendor(createVendorBody);
    }

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
          this.isLoaded = true;
          this.adminsService.needUpdateVendorsTable();
          const massage = this.data ? 'Vendor profile updated successfully' : 'Vendor profile created successfully';
          this.snackBarService.showSnackBar(massage);
          this.dialogRef.close();
      },
        error => {
          this.isLoaded = true;

          if (error.status === 409) {
            const massage = error.error.message;
            this.formErrors$.next({massage, field: 'email'});
          } else {
            this.snackBarService.showSnackBar(error.error.message, true);
          }
        })
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.vendorDataFormGroup.get(fieldName).touched) return false;
    return this.vendorDataFormGroup.get(fieldName).hasError(errorName);
  }

  uploadFile(event, name) {
    if (event) {
      const {id, url} = event;
      this[name] = {id, url}
    } else {
      this[name] = null;
    }
    this.vendorDataFormGroup.get(name + 'Id').patchValue(event ? event.id : null);
  }

  /**
   * As this popup using for creating vendor, image must be send as file, save this file
   * @param file - uploaded file
   * @param name - form field name
   */
  onCreateFile(file, name) {
    if (!this.fileImages) {
      this.fileImages = {};
    }
    this.fileImages[name] = file;
  }
}
