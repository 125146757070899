import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PrizeDetailsModel} from "../../../models/PrizeDetailsModel";
import {PrizeTypeEnum} from "../../../Enums/PrizeTypeEnum";
import {TooltipPositionEnum} from 'src/app/common/Enums/TooltipPositionEnum';
import {PersonalPrizeDitailsModel} from "../../../models/PersonalPrizeDitailsModel";
import {PrizeService} from "../../../../core/services/prize.service";
import { Subject, takeUntil } from 'rxjs';
import {SnackBarService} from "../../../../core/services/snack-bar.service";
import {PrizeStatusEnum} from "../../../Enums/PrizeStatusEnum";

@Component({
  selector: 'single-prize-item',
  templateUrl: './single-prize-item.component.html',
  styleUrls: ['./single-prize-item.component.scss']
})
export class SinglePrizeItemComponent implements OnInit, OnDestroy {

  TooltipPositionEnum = TooltipPositionEnum;

  PrizeTypeEnum = PrizeTypeEnum;

  @Input() prizeItem: PrizeDetailsModel;

  @Input() selectedPrizeId: number;

  @Input() selectedPrizesArray: PersonalPrizeDitailsModel[];

  @Input() isAnyPrizeSelected: boolean;

  /**
   * Is multiselect available on page where component using
   */
  @Input() multiSelect: boolean;

  @Input() personalPrizes: boolean;

  @Input() prizeMenuPage: boolean;

  @Input() overlayHeight: string;

  @Input() imageHeight: number;

  @Input() width: string;

  @Input() isEditingDisable: boolean;

  @Output() selectedPrize = new EventEmitter<{ prize: PrizeDetailsModel | PersonalPrizeDitailsModel, isSelect: boolean }>();

  isPrizeSelected = false;

  PrizeStatusEnum = PrizeStatusEnum;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private prizeService: PrizeService,
    private snackBarService: SnackBarService

  ) {
  }

  ngOnInit(): void {
    if (this.selectedPrizeId && this.selectedPrizeId === this.prizeItem.id) {
      this.selectedPrize.emit({prize: this.prizeItem, isSelect: true});
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Send event with selected prize
   * @param prize - seelcted prize item
   */
  onSelectPrize(prize) {
    if (this.isEditingDisable) return;
    this.isPrizeSelected = !this.isPrizeSelected;
    if (this.multiSelect) {
      this.selectedPrize.emit({prize, isSelect: this.isPrizeSelected});
    } else {
      this.selectedPrize.emit({prize, isSelect: true});
    }
  }

  /**
   * Check if prize item selected and show icon on it
   * @param prizeItem -  prize item
   */
  isSelectedItem(prizeItem) {
    if (this.multiSelect) {
      return this.selectedPrizesArray && this.selectedPrizesArray.some(prize => prize.id === prizeItem.id && prize.type === prizeItem.type);
    }
    return (this.prizeItem.id === this.selectedPrizeId);
  }

  /**
   * If this is not multi select, when one item selected other must be blured
   * as result current prize will be grayed out
   * @param prizeItem - selected prize item
   */
  isBluredItem(prizeItem) {
    if (this.multiSelect) return false;
    return this.isAnyPrizeSelected && prizeItem.id !== this.selectedPrizeId;
  }

  /**
   * On click archive prize, send request to BE to archive current prize,
   * as result current prize will be grayed out
   * @param prize - prize need to be archived
   */
  archivePrize(prize) {
    if (prize.status === PrizeStatusEnum.ARCHIVED) return;
    this.prizeService.archivePrize(prize.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
          this.prizeService.needUpdatePrizeList();
      },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true);
        })
  }
}
