<div
  class="prize"
  [style.width]="width"
  (click)="onSelectPrize(prizeItem)"
  [ngClass]="{
  'selected': isSelectedItem(prizeItem),
  'any-selected': isBluredItem(prizeItem)}">
  <div class="image-container">
    <image-with-loader
      [spinnerSize]="60"
      [overlayHeight]="overlayHeight"
      [imageSrc]="prizeItem.imageUrl">

    </image-with-loader>
    <div class="label-toolbar" [ngClass]="{'persona-prize': personalPrizes}">
      <div class="label" *ngIf="!personalPrizes"
           [ngClass]="{'single': prizeItem.type === PrizeTypeEnum.NFT, 'multi': prizeItem.type === PrizeTypeEnum.SFT}">
        {{prizeItem.type === PrizeTypeEnum.NFT ? 'Single win' : 'Multi win'}}
      </div>
      <div class="icon" *ngIf="!prizeMenuPage">
        <mat-icon
          class="material-icons material-icons-outlined">done
        </mat-icon>
      </div>

    </div>

  </div>
  <div class="prize-info-conttainer">
    <div>
      <div class="name body-big-bold">{{prizeItem.name}}</div>
      <div class="available" *ngIf="!personalPrizes">Available: {{prizeItem.totalAmount}}</div>
      <div class="used" *ngIf="!personalPrizes">Used: {{prizeItem.usedInGamesCount}}</div>
      <div class="remaining" *ngIf="!personalPrizes">Remaining:
        {{prizeItem.availableCount}}
        <i
          class="fa-solid fa-circle-info"
          [matTooltipPosition]="TooltipPositionEnum.right"
          #tooltip="matTooltip"
          [matTooltip]="'The amount of NFTs remaining in this collection that can be used as prizes'"
          [matTooltipClass]="TooltipPositionEnum.right"></i>
      </div>
    </div>
    <div *ngIf="prizeMenuPage" class="single-prize-action" [ngClass]="{'archived' : prizeItem.status === PrizeStatusEnum.ARCHIVED}">
      <div
        [matMenuTriggerFor]="prizeItem.status === PrizeStatusEnum.ARCHIVED ? null : menu"
        class="menu-item-icon"
        matRipple
        [matRippleDisabled]="prizeItem.status === PrizeStatusEnum.ARCHIVED"
        [matRippleUnbounded]="true"
        [matRippleCentered]="true">
        <mat-icon
          class="menu material-icons material-icons-outlined">more_vert
        </mat-icon>
      </div>

      <mat-menu #menu="matMenu" class="archive-menu">
        <div
          matRipple
          >
          <div
            class="menu-item archive"
            (click)="archivePrize(prizeItem)">
            Archive
          </div>
        </div>
      </mat-menu>

    </div>
  </div>
</div>
