  <div class="prize-line" [ngClass]="{'disabled': prize.disabled}">
    <mat-slide-toggle
      (toggleChange)="onTooglePrize()"
      [disabled]="prize.disabled"
      color="primary"
      [(ngModel)]="prize.selected">
      Day {{prize.day}}
    </mat-slide-toggle>
    <div class="item-end">
      <div *ngIf="prize.selected">
        {{prize.prizeType === 'POINTS' ?
        prize.points ? prize.points + 'pts' : 'pts' :
        prize.prizeType === 'NFT' ? 'Single win' : 'Multi win, Balance: ' + (prize.reservedBalance || 0)}}
      </div>
      <div class="icon-container" [ngClass]="{'hide': !prize.selected}">
        <mat-icon
          *ngIf="prize.isCollapse; else expanded"
          (click)="toggleExpand(false, prize)"
          class="material-icons material-icons-outlined">expand_more
        </mat-icon>
        <ng-template #expanded>
          <mat-icon
            (click)="toggleExpand(true, prize)"
            class="material-icons material-icons-outlined">expand_less
          </mat-icon>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="expanded-pannel" *ngIf="!prize.isCollapse">
    <div class="section-header body-small-medium">Prize</div>

    <div class="prize-type">
      <form [formGroup]="streakPrizeFormGroup">
        <mat-radio-group color="primary" [formControlName]="'type'" aria-label="Select an option" [value]="selectedType"
                         (change)="onChangeType($event, prize)">
          <mat-radio-button *ngFor="let type of prizeTypes" [value]="type">{{type}}</mat-radio-button>
        </mat-radio-group>
        <ng-container [ngSwitch]="selectedType">
          <div *ngSwitchCase="StreakPrizeTypeEnum.NFT" class="nft-selector-prize">
            <div class="form-field-with-label">
              <mat-label>Choose one prize</mat-label>
              <mat-form-field appearance="outline" class="autocomplete-selector-item">
                <input
                  matInput
                  placeholder="Name"
                  [matAutocomplete]="homeTeamAuto"
                  [formControlName]="'nft'">
                <div matPrefix *ngIf="selectedNftOption">
                  <img class="prefix-image" [src]="selectedNftOption?.imageUrl">
                </div>
                <mat-icon
                  class="drop-arrow"
                  *ngIf="!streakPrizeFormGroup.get('nft').value" matSuffix>arrow_drop_down
                </mat-icon>
                <mat-icon
                  matRipple
                  [matRippleRadius]="15"
                  [matRippleUnbounded]="true"
                  [matRippleCentered]="true"
                  *ngIf="streakPrizeFormGroup.get('nft').value"
                  (click)="clearPrize(StreakPrizeTypeEnum.NFT)"
                  matSuffix>
                  clear
                </mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="openPannel()"
                  (closed)="closePannel()"
                  (optionSelected)="onOptionSelected($event)"
                  #homeTeamAuto="matAutocomplete">

                  <mat-option
                    *ngFor="let team of onlyNft|filter: streakPrizeFormGroup.get('nft').value:'name'"
                    [value]="team"
                    class="prize-option"
                    [id]="team.id">
                    <div class="mat-option-item prize">
                      <div class="text-item-part body-medium-regular ">
                        <img [src]="team.imageUrl" class="prize-image">
                        {{ team.name }}
                      </div>
                      <div class="available body-small-regular">
                        Remaining: {{team.availableCount || 1}}
                      </div>
                    </div>
                  </mat-option>
                  <mat-option class="create-new-item" (click)="addNewPrize()">
                    <mat-icon class="material-icons">add_circle_outline</mat-icon>
                    Add New Prize
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error *ngIf="isShowError('nft', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY_SELECT}}</span>
              </mat-error>
            </div>
          </div>
          <div *ngSwitchCase=StreakPrizeTypeEnum.POINTS class="points-selector-prize">
            <div class="form-field-with-label">
              <mat-label>Points</mat-label>

              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Choose an option from the list"
                  [(value)]="selectedPoints"
                  [formControlName]="'points'">
                  <mat-option
                    *ngFor="let point of pointNumber"
                    [value]="point">
                    {{point}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="isShowError('points', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY_SELECT}}</span>
              </mat-error>
            </div>

          </div>
          <div *ngSwitchCase="StreakPrizeTypeEnum.SFT" class="sft-selector-prize">
            <div class="form-field-with-label">
              <mat-label>Balance</mat-label>
              <mat-form-field appearance="outline" [ngClass]="{'with-error': streakPrizeFormGroup.hasError('maxBalance')}">
                  <input
                    matInput
                    id="balance"
                    type="number"
                    min="0"
                    placeholder="Reserved balance"
                    [formControlName]="'reservedBalance'">
              </mat-form-field>
              <mat-error *ngIf="isShowError('reservedBalance', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error *ngIf="isShowError('reservedBalance', 'min')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MIN_RANGE}}</span>
              </mat-error>
              <mat-error *ngIf="streakPrizeFormGroup.hasError('maxBalance') && isSavedPrize()">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.PRIZE_REMAINING_OVER}} {{selectedSftOption?.availableCount}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Choose one SFT prize</mat-label>
              <mat-form-field appearance="outline" class="autocomplete-selector-item">
                <input
                  matInput
                  placeholder="Name"
                  [matAutocomplete]="homeTeamAuto"
                  [formControlName]="'nft'">
                <div matPrefix *ngIf="selectedSftOption">
                  <img class="prefix-image" [src]="selectedSftOption?.imageUrl">
                </div>
                <mat-icon
                  class="drop-arrow"
                  *ngIf="!streakPrizeFormGroup.get('nft').value" matSuffix>arrow_drop_down
                </mat-icon>
                <mat-icon
                  matRipple
                  [matRippleRadius]="15"
                  [matRippleUnbounded]="true"
                  [matRippleCentered]="true"
                  *ngIf="streakPrizeFormGroup.get('nft').value"
                  (click)="clearPrize(StreakPrizeTypeEnum.SFT)"
                  matSuffix>
                  clear
                </mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="openPannel()"
                  (closed)="closePannel()"
                  (optionSelected)="onSftOptionSelected($event)"
                  #homeTeamAuto="matAutocomplete">
                  <mat-option
                    *ngFor="let team of onlySft|filter: streakPrizeFormGroup.get('nft').value:'name'"
                    [value]="team"
                    class="prize-option"
                    [id]="team.id">
                    <div class="mat-option-item prize">
                      <div class="text-item-part body-medium-regular ">
                        <img [src]="team.imageUrl" class="prize-image">
                        {{ team.name }}
                      </div>
                      <div class="available body-small-regular">
                        Remaining: {{team.availableCount || streakPrizeFormGroup.get('reservedBalance').value}}
                      </div>
                    </div>
                  </mat-option>
                  <mat-option class="create-new-item body-medium-medium" (click)="addNewPrize()">
                    <mat-icon class="material-icons">add_circle_outline</mat-icon>
                    Add New Prize
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error *ngIf="isShowError('nft', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY_SELECT}}</span>
              </mat-error>
            </div>
          </div>

        </ng-container>
      </form>
    </div>
  </div>
