import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {TextService} from "./text.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {

  currentPage$ = new BehaviorSubject<string>('');

  currentChapter$ = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private textService: TextService

  ) { }

  setCurrentPage (pageName) {
    this.currentPage$.next(pageName);
  }

  getCurrentPage () {
    return this.currentPage$;
  }

  setCurrentChapterName (chapterName) {
    this.currentChapter$.next(chapterName);
  }

  getCurrentChapterName () {
    return this.currentChapter$;
  }

  /**
   * Listen router changes and set current page and current chapter data
   */
  getCurrentLocation() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.activatedRoute.firstChild) {
          // @ts-ignore
          const segments = this.activatedRoute.firstChild.snapshot._urlSegment.segments;
          if (segments.length > 2) {
            const chapterName = this.textService.createNameWithSpaces(segments[1].path);
            this.setCurrentChapterName(chapterName);
          } else {
            this.setCurrentChapterName('');
          }
          const pagePath = segments[segments.length - 1].path;

          this.setCurrentPage(pagePath);
        } else {
          this.setCurrentPage('pagePath');
        }

      });
  }
}
