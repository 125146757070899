<div class="game-details-container">
  <div class="game-details-header body-big-bold">GAME DETAILS</div>
  <div *ngIf="isTryToSave && excludedSportType && excludedSportType.length" class="general-prize-error" class="general-prize-error">
    <mat-icon class="material-icons">task_alt</mat-icon>
    <span class="text">{{appData.VIDEO_WITHOUT_QUESTION}}{{excludedSportType.join(', ')}}</span>
  </div>
  <div class="form-group">
    <form [formGroup]="newRoundDetailsForm">

<!--      Name-->
      <div class="form-field-with-label">
        <mat-label>
          Game name*
          <i
            class="fa-solid fa-circle-info"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="tooltipsTexts.name"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            trim
            placeholder="Name"
            [formControlName]="'name'">
        </mat-form-field>
        <mat-error *ngIf="isShowError('name', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'maxlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_52}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'minlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MIN_LENGHT_1}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'doubleSpace')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.DOUBLE_SPACE}}</span>
        </mat-error>
      </div>

<!--      Game type-->
      <div class="form-field-with-label no-error-field">
        <mat-label>Game type*</mat-label>

        <mat-radio-group
          color="primary"
          (change)="selectionGeneralTypeChange($event)"
          [(value)]="generalRoundType"
          [formControlName]="'type'">
          <mat-radio-button
            [disabled]="!!editableRound"
            [value]="type.beVariable"
            *ngFor="let type of generalTypeList">
            {{type.generalSportTypeLabel}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

<!--      Dates-->
      <div class="dates-controllers">

        <div class="form-field-with-label">
          <mat-label>
            Open Date & Time*
            <i
              class="fa-solid fa-circle-info"
              [matTooltipPosition]="TooltipPositionEnum.right"
              #tooltip="matTooltip"
              [matTooltip]="tooltipsTexts.openDate"
              [matTooltipClass]="TooltipPositionEnum.right"></i>
          </mat-label>
          <mat-form-field class="date-time" appearance="outline">
            <input
              matInput
              [ngxMatDatetimePicker]="openDatePicker"
              placeholder="Choose a date"
              formControlName="openDate">
            <mat-datepicker-toggle matSuffix [for]="openDatePicker">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker

              #openDatePicker>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <mat-error *ngIf="isShowError('openDate', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY}}</span>
          </mat-error>
          <mat-error *ngIf="!isShowError('openDate', 'required') && isShowError('openDate', 'invalidOpenDate')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.OPEN_DATE_ERROR}}</span>
          </mat-error>
        </div>


        <div class="form-field-with-label">
          <mat-label>
            Close Date & Time*
            <i
              class="fa-solid fa-circle-info"
              [matTooltipPosition]="TooltipPositionEnum.right"
              #tooltip="matTooltip"
              [matTooltip]="tooltipsTexts.closeDate"
              [matTooltipClass]="TooltipPositionEnum.right"></i>
          </mat-label>
          <mat-form-field class="date-time" appearance="outline">
            <input matInput [ngxMatDatetimePicker]="closeDatePicker" placeholder="Choose a date"
                   formControlName="closeDate">
            <mat-datepicker-toggle
              matSuffix [for]="closeDatePicker">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              [hideTime]="(!isPrediction)"
              #closeDatePicker>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <mat-error *ngIf="isShowError('closeDate', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY}}</span>
          </mat-error>
          <mat-error
            class="select-error"
            *ngIf="newRoundDetailsForm.errors?.datesOrderWrong && !isShowError('closeDate', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span class="text">{{appData.DATES_ERROR}}</span>
          </mat-error>
          <mat-error
            class="select-error"
            *ngIf="!isShowError('closeDate', 'required') && isShowError('closeDate', 'invalidCloseDate')">
            <i class="fas fa-exclamation-circle"></i>
            <span class="text">{{appData.CLOSE_DATE_ERROR}}</span>
          </mat-error>
        </div>
      </div>

<!--      One round type-->
      <div class="form-field-with-label" *ngIf="isPrediction">
        <mat-label>
          Type*
          <i
            class="fa-solid fa-circle-info"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="tooltipsTexts.type"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </mat-label>

        <mat-form-field appearance="outline">
          <mat-select
            [disabled]="!!editableRound"
            (selectionChange)="onRoundTypeChange($event)"
            [(value)]="oneRoundSportType"
            [formControlName]="'type'">
            <mat-option
              *ngFor="let type of oneRoundSportTypeList"
              [disabled]="!!editableRound"
              [value]="type.beVariable">
              {{type.oneRoundSportTypeLabel}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error
          class="select-error"
          *ngIf="isShowError('type', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span class="text">{{appData.MANDATORY_SELECT}}</span>
        </mat-error>

      </div>

<!--      Description-->
      <div class="form-field-with-label">
        <mat-label>
          Description*
          <i
            class="fa-solid fa-circle-info"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="tooltipsTexts.description"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            required
            trim
            placeholder="Description"
            [formControlName]="'description'">
        </mat-form-field>
        <mat-error *ngIf="isShowError('description', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('description', 'required') && isShowError('description', 'maxlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_255}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('description', 'required') && isShowError('description', 'minlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MIN_LENGHT_2}}</span>
        </mat-error>
      </div>

<!--      Background desctop-->
      <drag-and-drop-file
        [isDisabled]="isEditingDisable"
        [isRequired]="false"
        [tooltipText]="tooltipsTexts.backgroundImage"
        [title]="'Background Image Desktop'"
        [image]="{id: editableRound?.backgroundImageDesktopId, url: editableRound?.backgroundImageDesktopUrl}"
        (fileUpload)="uploadFile($event, 'backgroundImageDesktop')"
      ></drag-and-drop-file>

<!--      Background mobile-->
      <drag-and-drop-file
        [isDisabled]="isEditingDisable"
        [isRequired]="false"
        [tooltipText]="tooltipsTexts.backgroundImage"
        [title]="'Background Image Mobile'"
        [image]="{id: editableRound?.backgroundImageMobileId, url: editableRound?.backgroundImageMobileUrl}"
        (fileUpload)="uploadFile($event, 'backgroundImageMobile')"
      ></drag-and-drop-file>

<!--      Category-->
      <div class="form-field-with-label" *ngIf="!isOtherRound && newRoundDetailsForm.get('type').value !== RoundTypesEnum.Text_streak">
        <mat-label>
          Category*
          <i
            class="fa-solid fa-circle-info"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="tooltipsTexts.sport"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </mat-label>

        <mat-form-field appearance="outline">
          <ng-container *ngIf="isPrediction; else strekMulti">
            <mat-select
              placeholder="Choose the option from drop-down"
              floatPlaceholder="never"
              [formControlName]="'sport'">
              <mat-option *ngFor="let sport of sportTypes" [value]="sport">
                {{sport}}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-template #strekMulti>
            <mat-select
              placeholder="Choose the option from drop-down"
              floatPlaceholder="never"
              multiple
              [formControlName]="'sport'">
              <mat-option *ngFor="let sport of sportTypes" [value]="sport">
                {{sport}}
              </mat-option>
            </mat-select>
          </ng-template>
        </mat-form-field>
        <mat-error
          class="select-error"
          *ngIf="isShowError('sport', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span class="text">{{appData.MANDATORY_SELECT}}</span>
        </mat-error>

      </div>

    </form>
  </div>

</div>
