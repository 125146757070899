import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable } from 'rxjs';
import {UriConfig} from "../../app.config";
import {VideoQuestionModel} from "../../common/models/VideoQuestionModel";
import {PrizeDetailsModel} from "../../common/models/PrizeDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }


  getVideoQuestions(requestBody): Observable<{records: VideoQuestionModel[], total: number}>  {
    let params = new HttpParams()
    if (requestBody) {
      Object.keys(requestBody).forEach(param => {
        if (requestBody[param]) {
          if (param === "sportType") {
            requestBody[param].forEach(p => {
              params = params.append(param, p)
            })
          } else {
            params = params.set(param, requestBody[param])
          }
        }
      });
      return this.http.get<{records: VideoQuestionModel[], total: number}>(this.uriConfig.videoQuestion, { params });
    } else {
      return this.http.get<{records: VideoQuestionModel[], total: number}>(this.uriConfig.videoQuestion);
    }
  }

  deleteVideo(videoId) {
    return this.http.delete<any>(this.uriConfig.videoQuestion + '/' + videoId);
  }

  uploadVideo(body) {
    return this.http.post<any>(this.uriConfig.videoQuestion + '/video', body);
  }

  createVideoQuestion(videoBody: VideoQuestionModel) {
    return this.http.post<any>(this.uriConfig.videoQuestion, {items:[videoBody]});
  }

  updateVideoQuestion(videoBody: VideoQuestionModel, id: number) {
    return this.http.patch<any>(this.uriConfig.videoQuestion + '/' + id, videoBody);
  }

  needUpdateVideoList() {
    this.isNeedUpdate$.next(true);
  }

  getVideoListStatus() {
    return this.isNeedUpdate$;
  }
}
