import { Injectable } from '@angular/core';
import {SettingsConfigItemModel} from "../../common/models/SettingsConfigItemModel";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private TOKEN_KEY = 'auth-token';
  private REFRESHTOKEN_KEY = 'auth-refreshtoken';
  private USER_KEY = 'auth-user';
  private HASH = 'hash';
  private SETTINGS = 'settings';

  constructor() { }

  setItem(prop: string, value: any): void {
    localStorage.setItem(prop, JSON.stringify(value));
  }

  getItem(prop: string): any {
    return JSON.parse(localStorage.getItem(prop));
  }

  removeItem(prop: string) {
    localStorage.removeItem(prop);
  }

  clear() {
    localStorage.clear();
  }

  getToken(): string {
    return this.getItem(this.TOKEN_KEY)
  }

  getRefreshToken(): string {
    return this.getItem(this.REFRESHTOKEN_KEY)
  }

  setToken(token: string) {
    this.setItem(this.TOKEN_KEY, token)
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(this.REFRESHTOKEN_KEY, refreshToken)
  }

  logOut() {
    this.removeItem(this.TOKEN_KEY)
    this.removeItem(this.REFRESHTOKEN_KEY)
  }

  getSettingsConfig() {
    return this.getItem(this.SETTINGS);
  }

  setSettingsConfig(config: SettingsConfigItemModel[]) {
    this.setItem(this.SETTINGS, config)
  }
  getHash() {
    return this.getItem(this.HASH);
  }

  setHash(hash: string) {
    this.setItem(this.HASH, hash)
  }
}
