<div class="streak-prize-details-container">
  <div class="streak-prize-details-header body-big-bold">PRIZES</div>
  <div class="streak-prize-body-list">
    <div class="body-header">
      Select which days you’d like to offer a prize.
    </div>
    <div class="streak-prize-list">
      <div class="single-prize-item" *ngFor="let prize of prizes; let i = index">
        <single-streak-prize
          [index]=i
          [_nftPrizeList]="nftPrizeList"
          [_prize]="prize"
          [_isUnsavedPrize]="isUnsavedPrize"
          [savedPrizes]="savedPrizes"
          [manuallyUsed]="manuallyUsedPrizeId"
          (validPrize)="handleValidPrize($event, i)"
          (changedPrize)="onPrizeChanged($event, i)">

        </single-streak-prize>


      </div>

    </div>

  </div>
</div>
