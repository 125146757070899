import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {filter, Subject, takeUntil} from "rxjs";
import {EventsService} from "../../../core/services/events.service";
import {TeamDetailsModel} from "../../models/TeamDetailsModel";
import {NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import {CreateEventDetailsModel} from "../../models/CreateEventDetailsModel";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {orderBy} from "lodash";
import {APP_DATA} from "../../../general.app.config";

export const CUSTOM_MOMENT_FORMATS = {
  parse: {
  },
  display: {
    dateInput: 'DD MMM YYYY, HH:MM',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss'],
  providers: [
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
  ],
})
export class CreateEventModalComponent implements OnInit, OnDestroy {

  isNewItemEntered = false;

  eventDetailsForm: FormGroup;

  private unsubscribe$: Subject<void> = new Subject<void>();

  editedId: number;
  teamList: TeamDetailsModel[];

  filteredTeamList: TeamDetailsModel[];

  userTeamId = [];

  buttonText = 'Create Event'

  homeTeamName: string;

  awayTeamName: string;

  appData = APP_DATA;

  showEditTeamRequiredError = false;

  showEditTeamMaxLenghtError = false;

  selectedHomeTeam: any;

  selectedAwayTeam: any;

  header = 'CREATE A NEW EVENT';

  newTeamItemValueEntering = {};

  editingTeam = false;

  isShowAnyError = false;

  constructor(
    public dialogRef: MatDialogRef<CreateEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventsService,
    private snackBarService: SnackBarService

  ) {
  }


  ngOnInit(): void {
    this.eventsService.needUpdateTeamList();
    this.eventsService.getTeamListStatus()
      .pipe(
        filter(respose => !!respose),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.fetchTeamList());

    this.buildForm();

    this.eventDetailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.userTeamId = [val.awayTeamId.id, val.homeTeamId.id]


        this.filteredTeamList = this.userTeamId.length ?
          this.teamList.filter(team => !this.userTeamId.some(id => id === team.id)) :
          this.teamList;
      })
  }

  fetchTeamList() {
    this.eventsService.getTeamsList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamList: TeamDetailsModel[]) => {
        this.teamList = orderBy(teamList, ['id'], ['asc'])
        this.filteredTeamList = this.userTeamId.length ?
          this.teamList.filter(team => !this.userTeamId.some(id => id === team.id)) :
          this.teamList;
        if (this.data) {
          this.header = 'EVENT DETAILS';
          this.fillForm()
        }
      })
  }

  getOptionText(option) {
    return option.name;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm() {
    this.eventDetailsForm = new FormGroup({
      homeTeamId: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(52)]),
      awayTeamId: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(52)]),
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      startedAt: new FormControl(new Date(), [Validators.required]),
    })
  }

  fillForm() {
    this.eventDetailsForm.get('homeTeamId').patchValue(this.data.homeTeamSportEvents);
    this.eventDetailsForm.get('awayTeamId').patchValue(this.data.awayTeamSportEvents);
    this.eventDetailsForm.get('name').patchValue(this.data.name);
    this.eventDetailsForm.get('startedAt').patchValue(this.data.startedAt);
    this.selectedAwayTeam = this.data.awayTeamSportEvents;
    this.selectedHomeTeam = this.data.homeTeamSportEvents;
    this.userTeamId = [this.selectedAwayTeam.id,  this.selectedHomeTeam.id];
    this.buttonText = 'Update Event';
  }


  focusOut() {
  }

  addNewTeam(event, controlName) {
    if (this.showEditTeamMaxLenghtError || this.showEditTeamRequiredError || this['isShowAnyError' + controlName]) return;
    event.stopImmediatePropagation();
    const newTeamName = this.eventDetailsForm.get(controlName).value;
    if (typeof newTeamName !== 'string') return;
    this.eventsService.createNewTeam(newTeamName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.eventsService.needUpdateTeamList();
        this.editingTeam = false;
      },
        error => {
        this.snackBarService.showSnackBar(error.error.message, true)
        })
  }

  onCloseAnswerSelector() {
    this.editedId = null;
    this.showEditTeamMaxLenghtError = false;
    this.showEditTeamRequiredError = false;
  }

  onAutocompleteInputEvent(event, formName) {
    this.editingTeam = true;
    this.newTeamItemValueEntering[formName] = !!event.srcElement.value;
  }

  isShowDoneEnterIcon(fieldName) {
    return this.eventDetailsForm.get(fieldName).value && this.newTeamItemValueEntering[fieldName] && this.editingTeam;
  }

  prevent(e) {
    e.stopPropagation();
  }

  saveTeam(event, input) {
    if (this.showEditTeamMaxLenghtError || this.showEditTeamRequiredError) return;

    event.stopImmediatePropagation();

    this.eventsService.updateTeam(input, this.editedId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.eventsService.needUpdateTeamList();
        this.editedId = null;
      })
  }

  editTeam(event, team) {
    this.editedId = team.id;
    event.stopPropagation();
  }

  deleteTeam(event, team) {
    event.stopPropagation();
    this.eventsService.deleteTeamById(team.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.eventsService.needUpdateTeamList());
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  saveEvent() {
    if (this.eventDetailsForm.invalid) {
      this.eventDetailsForm.markAllAsTouched();
      return;
    }
    if (this.showEditTeamMaxLenghtError || this.showEditTeamMaxLenghtError) {
      this.eventDetailsForm.markAllAsTouched();
      return;
    }
    const eventBody = new CreateEventDetailsModel();
    const eventValue = this.eventDetailsForm.value;
    eventBody.name = eventValue.name;
    eventBody.homeTeamId = eventValue.homeTeamId.id;
    eventBody.awayTeamId = eventValue.awayTeamId.id;
    eventBody.startedAt = typeof eventValue.startedAt === 'string' ? eventValue.startedAt : eventValue.startedAt.toISOString();
    const request$ = this.data ?
      this.eventsService.updateEvent(this.data.id, eventBody) :
      this.eventsService.createNewEvent(eventBody);

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.eventsService.needUpdateEventList()
          this.dialogRef.close(true);
        },
        error => this.snackBarService.showSnackBar(error.error.message, true))
  }

  createEventName(homeTeam, awayTeam) {
    const eventName = homeTeam + ' vs ' + awayTeam;
    this.eventDetailsForm.get('name').patchValue(eventName);
  }

  changeTeam(event, fildName) {
    const teamValue = event.option.value
    this[fildName + 'Name'] = teamValue.name;
    this.newTeamItemValueEntering[fildName + 'Id'] = false
    if (this.homeTeamName && this.awayTeamName) {
      this.createEventName(this.homeTeamName, this.awayTeamName);
    }
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.eventDetailsForm.get(fieldName).touched) return false;
    return this.eventDetailsForm.get(fieldName).hasError(errorName);
  }

  onTeamInputChange(e) {
    this.showEditTeamRequiredError = !(!!e.value);
    this.showEditTeamMaxLenghtError = e.value.length > 52;
  }

  clearTeam(name, event) {
    event.stopImmediatePropagation();

    this.eventDetailsForm.get(name).patchValue(null);
  }

  test(name) {
    return this.eventDetailsForm.get(name).value
  }

  isShowAddingError (name) {
    this['isShowAnyError' + name] = this.eventDetailsForm.get(name).value.length > 52;
    return this.eventDetailsForm.get(name).value.length > 52;
  }

}
