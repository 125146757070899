<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onCloseRoundWizard()">close</mat-icon>
        <h2 class="popup-header">{{header}} Game</h2>
      </div>
      <div class="control-header buttons-container">
        <button
          mat-stroked-button
          color="primary"
          (click)="onSaveDraft()"
          *ngIf="isShowDraftButton()">
          Save Draft
        </button>

        <button
          mat-raised-button
          color="primary"
          (click)="onSaveRound()"
          *ngIf="isShowPublishButton()">
          Preview and Publish
        </button>

        <button
          mat-raised-button
          color="primary"
          [disabled]="!valueWasChanged"
          (click)="onSubmitCorrectAnswers()"
          *ngIf="isShowSubmitButton()">
          Submit correct result
        </button>
      </div>

    </div>
    <div class="tabs-pannel">
      <div
        class="tab body-medium-medium"
        [ngClass]="{'active-tab': tabsName[0].isActive}"
        (click)="changeSelection(tabsName[0].name, gameDetails)">
        {{tabsName[0].name}}
      </div>
      <div
        *ngIf="showPrizeTab()"
        class="tab body-medium-medium"
        [ngClass]="{'active-tab': tabsName[1].isActive}"
        (click)="changeSelection(tabsName[1].name, prizes)">
        {{tabsName[1].name}}
      </div>
      <div
        *ngIf="roundType !== RoundTypesEnum.Video_streak && showTextQuestionTab()"
        class="tab body-medium-medium"
        [ngClass]="{'active-tab':  tabsName[2].isActive}"
        (click)="changeSelection(tabsName[2].name, questions)">
        {{tabsName[2].name}}
      </div>
    </div>

  </div>
  <div class="game-builder-body" (scroll)="onScroll($event)">
    <div class="game-builder-content">
      <div #gameDetails class="space"></div>
      <game-details
        [id]="tabsName[0].anchorName"
        [_editableRound]="editableRound"
        [isSaveButtonClicked]="isSaveButtonClicked"
        [isEditingDisable]="isEditingDisable"
        [excludedSportType]="excludedSportType"
        (isDataChanged)="onDataChanged($event)"
        (formValues)="onFormValueChanges($event)"
        (isValidForm)="onFormValidCheck($event)"
        (isSportTypeChanged)="onSportChanged($event)"
      ></game-details>

      <div #prizes class="space"></div>
      <ng-container
        *ngIf="roundType === RoundTypesEnum.Text_streak ||
        roundType === RoundTypesEnum.Video_streak; else prediciton">
        <streak-prize
          *ngIf="editableRound || (roundDetails.openDate && roundDetails.closeDate)"
          [id]="tabsName[1].anchorName"
          [roundDetails]="roundDetails"
          [editableRound]="editableRound"
          [isDataChanged]="isDataChanged"
          [_isUnsavedPrize]="isUnsavedPrize"
          (changedPrezies)="onStreakPrizeChanges($event)"
        >
        </streak-prize>
      </ng-container>

      <ng-template #prediciton>
        <prizes
          *ngIf="isLoadedData "
          [id]="tabsName[1].anchorName"
          [isAnyPrizeSelected]="isAnyPrizeSelected"
          [editableRoundPrize]="editableRound?.prizeId || editableRound?.prize?.id"
          [isEditingDisable]="isEditingDisable"
          [predictionSelectedPrize]="predictionSelectedPrize"
          (selectedPrize)="onSelectedPrize($event)"
        ></prizes>
      </ng-template>

      <div #questions class="space"></div>
      <div class="close-round-massage" *ngIf="editableRound && editableRound?.status === RoundStatusEnum.CLOSED && !roundType.toLowerCase().includes('streak')">
        <mat-icon class="material-icons">task_alt</mat-icon>

        <span>This game has now ended, please result it by selecting which were the correct answers below. Be careful to record results accurately as they cannot be corrected later.</span>
      </div>
      <div class="close-round-massage" *ngIf="editableRound && isStreakWithCloseDate && roundType === RoundTypesEnum.Text_streak">
        <mat-icon class="material-icons">task_alt</mat-icon>

        <span>Some questions has now ended, please result it by selecting which were the correct answers below. Be careful to record results accurately as they cannot be corrected later.</span>
      </div>
      <ng-container *ngIf="roundType === RoundTypesEnum.Text_streak; else textStreak">
        <text-streak-question
          [id]="tabsName[2].anchorName"
          *ngIf="editableRound || (roundDetails?.openDate && roundDetails?.closeDate)"
          [isDataChanged]="isDataChanged"
          [roundDetails]="roundDetails"
          [editableRound]="editableRound"
          [_isUnsavedQuestion]="isUnsavedStreakQuestion"
          (changedQuestions)="onChnageStreakQuestion($event)"
        >

        </text-streak-question>
      </ng-container>

      <ng-template #textStreak>
        <ng-container *ngIf="
        !editableRound ||
        (editableRound?.status !== RoundStatusEnum.CLOSED &&
        editableRound?.status !== RoundStatusEnum.COMPLETED &&
        editableRound?.status !== RoundStatusEnum.RESULT_PROCESSING &&
        roundType !== RoundTypesEnum.Video_streak); else correctAnswer">
          <questions
            *ngIf="roundType !== RoundTypesEnum.Video_streak && (!data || editableRound)"
            [id]="tabsName[2].anchorName"
            [roundDetails]="roundDetails"
            [editableRound]="editableRound"
            [unsavedQuestions]="isUnsavedQuestions"
            [isEditingDisable]="isEditingDisable"
            (allQuestion)="handleQuestionList($event)"
            (isAnyQuestionEditedOutput)="handleEditedQuestion()"
          >
          </questions>
        </ng-container>
        <ng-template #correctAnswer>
          <set-correct-question
            *ngIf="roundType !== RoundTypesEnum.Video_streak"
            [roundId]="editableRound.id"
            [isCompleted]="editableRound.status === RoundStatusEnum.COMPLETED || editableRound.status === RoundStatusEnum.RESULT_PROCESSING"
            [unsetAnswers]="unsetAnswers"
            (correctAnswers) = "handleCorrectAnswers($event)"
            (valueWasChanged)="handleChangesValue($event)"
          >

          </set-correct-question>
        </ng-template>

      </ng-template>

    </div>
  </div>

</div>
<div *ngIf="!isLoadedData" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
