<div class="create-event-container">
  <div class="create-event-header body-medium-medium">{{header}}</div>
  <div class="create-event-body">
    <form [formGroup]="eventDetailsForm">
      <div class="form-field-with-label">
        <mat-label>Home Team*</mat-label>
        <mat-form-field appearance="outline" class="autocomplete-selector-item">
          <input
            matInput
            placeholder="Select a team"
            [matAutocomplete]="homeTeamAuto"
            [formControlName]="'homeTeamId'"
            (input)="onAutocompleteInputEvent($event, 'homeTeamId')"
            (focusout)="focusOut()"
            (keyup.enter)="addNewTeam($event,'homeTeamId')">
          <mat-icon class="drop-arrow" *ngIf="!isShowDoneEnterIcon('homeTeamId')" matSuffix>arrow_drop_down</mat-icon>

          <mat-icon
            matSuffix
            *ngIf="isShowDoneEnterIcon('homeTeamId')"
            (click)="addNewTeam($event, 'homeTeamId')">
            done
          </mat-icon>

          <mat-autocomplete
            (closed)="onCloseAnswerSelector()"
            [displayWith]="getOptionText"
            (optionSelected)="changeTeam($event, 'homeTeam')"
            #homeTeamAuto="matAutocomplete">
            <mat-option
              *ngFor="let team of filteredTeamList|filter: eventDetailsForm.get('homeTeamId').value:'name'"
              [value]="team"
              [id]="team.id">
              <div *ngIf="team.id === editedId; else regularItem" class="edit-input">
                <div class="div-with-error" [ngClass]="{'invalid': showEditTeamRequiredError || showEditTeamMaxLenghtError}">
                  <div class="team-input-container">
                    <input
                      (input)="onTeamInputChange(teamName)"
                      [value]="team.name"
                      (keydown)="$event.stopPropagation()"
                      #teamName
                      (click)="prevent($event)"
                      (keyup.enter)="saveTeam($event, teamName.value)">

                  </div>
                  <mat-icon matSuffix (click)="saveTeam($event, teamName.value)">done</mat-icon>
                </div>
                <mat-error *ngIf="showEditTeamRequiredError">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY}}</span>
                </mat-error>
                <mat-error *ngIf="showEditTeamMaxLenghtError">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MAX_LENGHT_52}}</span>
                </mat-error>
              </div>
              <ng-template #regularItem>
                <div class="mat-option-item team">
                  <div class="text-item-part">
                    {{ team.name }}
                  </div>

                  <div class="icons-part">
                    <mat-icon matSuffix class="material-icons material-icons-outlined"
                              (click)="editTeam($event, team)">edit
                    </mat-icon>
                    <mat-icon matSuffix class="material-icons material-icons-outlined"
                              (click)="deleteTeam($event, team)">delete_outline
                    </mat-icon>
                  </div>
                </div>
              </ng-template>

            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="isShowError('homeTeamId', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY_SELECT}}</span>
        </mat-error>
        <mat-error *ngIf="isShowAddingError('homeTeamId')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_52}}</span>
        </mat-error>
      </div>

      <div class="form-field-with-label">
        <mat-label>Away Team*</mat-label>
        <mat-form-field appearance="outline" class="autocomplete-selector-item">
          <input
            matInput
            placeholder="Select a team"
            [matAutocomplete]="awayTeamAuto"
            [formControlName]="'awayTeamId'"
            (input)="onAutocompleteInputEvent($event, 'awayTeamId')"
            (focusout)="focusOut()"
            (keyup.enter)="addNewTeam($event, 'awayTeamId')">
          <mat-icon matSuffix *ngIf="!isShowDoneEnterIcon('awayTeamId')">arrow_drop_down</mat-icon>

          <mat-icon
            matSuffix
            *ngIf="isShowDoneEnterIcon('awayTeamId')"
            (click)="addNewTeam($event, 'awayTeamId')">
            done
          </mat-icon>

          <mat-autocomplete
            (closed)="onCloseAnswerSelector()"
            [displayWith]="getOptionText"
            (optionSelected)="changeTeam($event, 'awayTeam')"
            #awayTeamAuto="matAutocomplete">
            <mat-option
              *ngFor="let team of filteredTeamList|filter: eventDetailsForm.get('awayTeamId').value:'name'"
              [value]="team"
              [id]="team.id">
              <div *ngIf="team.id === editedId; else regularItem" class="edit-input">
                <div class="div-with-error" [ngClass]="{'invalid': showEditTeamRequiredError || showEditTeamMaxLenghtError}">
                  <div class="team-input-container">
                    <input
                      (input)="onTeamInputChange(teamName)"
                      (keydown)="$event.stopPropagation()"
                      [value]="team.name"
                      #teamName
                      (click)="prevent($event)"
                      (keyup.enter)="saveTeam($event, teamName.value)">

                  </div>
                  <mat-icon matSuffix (click)="saveTeam($event, teamName.value)">done</mat-icon>
                </div>
                <mat-error *ngIf="showEditTeamRequiredError">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY}}</span>
                </mat-error>
                <mat-error *ngIf="showEditTeamMaxLenghtError">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MAX_LENGHT_52}}</span>
                </mat-error>
              </div>
              <ng-template #regularItem>
                <div class="mat-option-item team">
                  <div class="text-item-part">
                    {{ team.name }}
                  </div>
                  <div class="icons-part">
                    <mat-icon matSuffix class="material-icons material-icons-outlined"
                              (click)="editTeam($event, team)">edit
                    </mat-icon>
                    <mat-icon matSuffix class="material-icons material-icons-outlined"
                              (click)="deleteTeam($event, team)">delete_outline
                    </mat-icon>
                  </div>
                </div>
              </ng-template>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="isShowError('awayTeamId', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY_SELECT}}</span>
        </mat-error>
        <mat-error *ngIf="isShowAddingError('awayTeamId')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_52}}</span>
        </mat-error>
      </div>

      <div class="form-field-with-label">
        <mat-label>
          Start Date & Time*
        </mat-label>
        <mat-form-field class="date-time" appearance="outline">
          <input matInput [ngxMatDatetimePicker]="datePicker" placeholder="Choose a date"
                 formControlName="startedAt">
          <mat-datepicker-toggle matSuffix [for]="datePicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datePicker>
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-error *ngIf="isShowError('startedAt', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
      </div>

      <div class="form-field-with-label">
        <mat-label>
          Event Name*
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Name"
            [formControlName]="'name'">
        </mat-form-field>
        <mat-error *ngIf="isShowError('name', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'maxlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_100}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'minlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MIN_LENGHT_2}}</span>
        </mat-error>
      </div>

    </form>
  </div>
  <div class="buttons">
    <button
      id="stayButton"
      class="regular-button secondary-button"
      mat-stroked-button
      color="primary"
      (click)="closeDialog()"
      mat-dialog-close>Cancel</button>
    <button
      class="regular-button"
      mat-flat-button
      color="primary"
      (click)="saveEvent()"
    >{{buttonText}}</button>
  </div>
</div>
