import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {AnswerDetailsModel} from "../../common/models/AnswerDetailsModel";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  isNewItemEntered$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  getAnswersList(): Observable<AnswerDetailsModel[]> {
    return this.http.get<AnswerDetailsModel[]>(this.uriConfig.answers);
  }

  createNewAnswers(answer): Observable<AnswerDetailsModel> {
    return this.http.post<any>(this.uriConfig.answers, answer);
  }

  deleteAnswersById(answer: AnswerDetailsModel) {
    const {text, id} = answer
    const body = {
      text,
      isHidden: true
    }
    return this.http.patch<any>(this.uriConfig.answers + '/' + id, body);
  }

  updateAnswer(body, id) {
    return this.http.patch<any>(this.uriConfig.answers + '/' + id, body);
  }

  submitCorrectAnswer(correctAnswers) {
    return this.http.post(this.uriConfig.correctAnswers, correctAnswers);
  }

  resubmitCorrectAnswer(correctAnswers, answerId) {
    return this.http.patch(this.uriConfig.correctAnswers + '/' + answerId, correctAnswers);
  }

  bulkSubmitCorrectAnswers(correctAnswers) {
    return this.http.post(this.uriConfig.correctAnswers, correctAnswers);
  }

  needUpdateAnswerList() {
    this.isNeedUpdate$.next(true);
  }

  answerListUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getAnswerListStatus() {
    return this.isNeedUpdate$;
  }


  setAnswerItemStatus(status) {
    this.isNewItemEntered$.next(status);
  }

  getAnswerItemStatus() {
    return this.isNewItemEntered$;
  }
}
