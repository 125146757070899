import {QuestionTypes} from "../../common/Enums/QuestionTypesEnum";

export const questionTypesConfig = {
  commonFields: [
    {
      fieldName: 'text',
      filedLabel: 'Question text',
      type: 'input',
      required: true
    },
    {
      fieldName: 'sportEvent',
      filedLabel: 'Sport Event',
      type: 'selector',
      required: false
    },
  ],
  [QuestionTypes.SCORE.label]: [],
  [QuestionTypes.RANGE.label]: [
    {
      fieldName: 'minValue',
      filedLabel: 'Min Value',
      type: 'input',
      required: true,
      inputType: 'number'
    },
    {
      fieldName: 'maxValue',
      filedLabel: 'Max Value',
      type: 'input',
      required: true,
      inputType: 'number'
    },
    {
      fieldName: 'displayValue',
      filedLabel: 'Display Value',
      type: 'input',
      required: true
    },
    {
      fieldName: 'incrementValue',
      filedLabel: 'Increment Value',
      type: 'input',
      required: true,
      inputType: 'number'
    },
  ],
  [QuestionTypes.OPTIONS.label]: [
    {
      fieldName: 'answers',
      filedLabel: 'Answers',
      type: 'chips',
      required: true
    },

  ],
  [QuestionTypes.LIST.label]: [
    {
      fieldName: 'answers',
      filedLabel: 'Answers',
      type: 'chips',
      required: true
    },

  ],
  [QuestionTypes.LISTS.label]: [
    {
      fieldName: 'answers1st',
      filedLabel: '1st Answers',
      type: 'chips',
      required: true
    },
    {
      fieldName: 'answers2nd',
      filedLabel: '2nd Answers',
      type: 'chips',
      required: true
    },
  ],
}
