<div class="body-small-medium input-label">
  {{title}}{{isRequired ? '*' : ''}}
  <i
    *ngIf="tooltipText"
    class="fa-solid fa-circle-info"
    [matTooltipPosition]="TooltipPositionEnum.right"
    #tooltip="matTooltip"
    [matTooltip]="tooltipText"
    [matTooltipClass]="TooltipPositionEnum.right"></i>
</div>

<div class="dropdiv" [class.processing]="processing"
     *ngIf="!(isDisabled && uploadedFiles)"
     [ngClass]="{disabled: isDisabled}"
     (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
  <label class="custom-file-upload">
    <input (change)="onChange($event)" type="file" onclick="this.value=null" [disabled]="isDisabled"/>
    <img src="assets/images/icons/upload.svg">
    Drag and drop or browse to choose a file
  </label>
  <div *ngIf="isLoading" class="disabled-overlay">
    <mat-spinner [diameter]="30" color="primary"></mat-spinner>
  </div>
</div>
<mat-error *ngIf="isImageTooBig">
  <i class="fas fa-exclamation-circle"></i>
  <span>{{appData.BIG_VIDEO}}</span>
</mat-error>
<ng-container *ngIf="uploadedFiles">
  <div class="video-uploaded-line">
    <div class="video-description">
      Use the slider to set the desired stop moment for the video question to appear
    </div>
    <div class="video-info-container">
      <div class="video-control">
        <mat-icon *ngIf="!isDisabled" (click)="deleteVideo()" class="material-icons material-icons-outlined">clear</mat-icon>
      </div>
      <div class="video-info">
        <video
          #player
          height="320"
          (loadedmetadata)="onVideoLoad()">
          <source [src]="uploadedFiles.link" type="video/mp4" />
        </video>
        <div id='controls'>
          <div class="body-small-medium">
            Stop moment
          </div>
          <mat-slider color="red">
            <input
              [(ngModel)]="progressValue"
              matSliderThumb
              (valueChange)="timeUpdate($event)">
          </mat-slider>
        </div>
        <div
          *ngIf="!isVideoUploaded"
          class="disabled-overlay video"
          [style.height]="'320px'"
          [style.width]="'100%'"
        >
          <mat-spinner [diameter]="30" color="primary"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>

