import {Component, Inject, Input} from '@angular/core';
import { TooltipPositionEnum } from '../../Enums/TooltipPositionEnum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fromPositionValidator } from './fromPosition.validator';
import { toPositionValidator } from './toPosition.validator';
import { APP_DATA } from 'src/app/general.app.config';
import { prizeValidator } from './prize-validator.validator';
import { BehaviorSubject, Observable, map } from 'rxjs';
import moment from 'moment';
import { HerosRewardModel } from '../../models/HerosRewardModel';
import { PrizeTypeEnum } from '../../Enums/PrizeTypeEnum';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'create-reward-modal',
  templateUrl: './create-reward-modal.component.html',
  styleUrls: ['./create-reward-modal.component.scss']
})
export class CreateRewardModalComponent {

  @Input() onSave: (prize) => void;

  @Input() onClose: () => void;

  _revard: HerosRewardModel;

  tooltipPositionEnum = TooltipPositionEnum;

  rewardForm: FormGroup;

  appData = APP_DATA;

  months$ = new BehaviorSubject([
    {
      value: moment().add(1,'months').format('YYYY-MM'),
      label: moment().add(1,'months').format('MMMM YYYY')
    },
    {
      value: moment().add(2,'months').format('YYYY-MM'),
      label: moment().add(2,'months').format('MMMM YYYY')
    },
    {
      value: moment().add(3,'months').format('YYYY-MM'),
      label: moment().add(3,'months').format('MMMM YYYY')
    },
    {
      value: moment().add(4,'months').format('YYYY-MM'),
      label: moment().add(4,'months').format('MMMM YYYY')
    },
    {
      value: moment().add(5,'months').format('YYYY-MM'),
      label: moment().add(5,'months').format('MMMM YYYY')
    },
    {
      value: moment().add(6,'months').format('YYYY-MM'),
      label: moment().add(6,'months').format('MMMM YYYY')
    },
  ]);

  prizeType$: Observable<PrizeTypeEnum>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.rewardForm = this.formBuilder.group({      
      fromPosition: new FormControl(undefined, [Validators.required,  Validators.max(1000000)]),
      toPosition: new FormControl(undefined, [Validators.required,  Validators.max(1000000)]),
      prizeId: new FormControl(undefined, [Validators.required]),
      assignDate: new FormControl(undefined, [Validators.required]),
      prize: new FormControl(undefined)
    });
    this.rewardForm.addValidators([fromPositionValidator, toPositionValidator]);
    if (data.reward) {
      this.rewardForm.controls['fromPosition'].setValue(data.reward.fromPosition);
      this.rewardForm.controls['toPosition'].setValue(data.reward.toPosition);
      this.rewardForm.controls['assignDate'].setValue( moment(data.reward.assignDate).format('YYYY-MM'));
      this.rewardForm.controls['prizeId'].setValue(data.reward.prizeId);
      this.rewardForm.addValidators(prizeValidator(data.reward));
    } else {
      this.rewardForm.addValidators(prizeValidator());
    }
    this.prizeType$ = this.rewardForm.valueChanges.pipe(
      map(data => {
        if (data.fromPosition !== undefined && data.toPosition !== undefined && data.fromPosition != data.toPosition) {
          return PrizeTypeEnum.SFT;
        } else {
          return undefined;
        }
      })
    )
  }  

  close() { 
    this.onClose();
  }

  saveForm() {
    this.rewardForm.markAllAsTouched();
    if (this.onSave && this.rewardForm.valid) {
      const reward = {
        fromPosition: Number(this.rewardForm.controls['fromPosition'].value),
        toPosition: Number(this.rewardForm.controls['toPosition'].value),
        assignDate: `${moment(this.rewardForm.controls['assignDate'].value).format("YYYY-MM-DDTHH:mm:ss.SSS")}Z`,
        prizeId: this.rewardForm.controls['prizeId'].value,
      }
      this.onSave(reward);
    }
  }

  selectPrize(prize) {
    this.rewardForm.controls['prizeId'].setValue(prize.id);
    this.rewardForm.controls['prize'].setValue(prize);
  }
}
