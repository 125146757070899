import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[numberInput]'
})
export class NumberInputDirective {

  value: string;

  constructor(
    public ref: ElementRef,
    public ngControl: NgControl) {
  }

  @HostListener('input', ['$event']) onInput($event) {
    let wholeValue = $event.target.value;
    let input = $event.data
    if (input === '+' || input === '-' || input === ',' || input === '.') {
      $event.data = ''
      return;
    }
    if (!wholeValue) {
      return;
    }

    $event.preventDefault();
  }

  @HostListener('focusout', ['$event'])
  onFocusOut($event) {
    if (!$event.target.value) {
      $event.target.value = 0;
    }
  }

}
