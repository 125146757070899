import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[trim]'
})
export class TrimDirective {
  value: string;

  constructor(
    public ref: ElementRef,
    public ngControl: NgControl) {
  }

  @HostListener('input', ['$event']) onInput($event) {
    const value = $event.target.value;
    let updateValue = value;

    while (updateValue.startsWith(' ')) {
      updateValue = value.replace(' ', '');
    }

    $event.target.value = updateValue;
    if (this.ngControl.name !== 'title' && this.ngControl.name) {
      this.ngControl.control.setValue(updateValue);
    }

    this.value = updateValue;
    $event.preventDefault();
  }
}
