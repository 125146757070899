<ng-container *ngIf="userDetails$ | async  as userDetails">

  <div class="brand-container" *ngIf="!isAdmin; else admin">
    <img class="brand-logo"
         [src]="userDetails.brand.imageUrl ? userDetails.brand.imageUrl : '../../../../assets/images/only-logo.svg'">
    <div class="user-brand-info-container">
      <div class="brand body-big-bold">{{userDetails.brand.name}}</div>
      <div class="user">{{userDetails.firstName + ' ' + userDetails.lastName}}</div>
    </div>
    <mat-icon (click)="logOut()" class="material-icons logout">logout</mat-icon>
  </div>
  <ng-template #admin>
    <div class="brand-container admin">
      <div class="general-container">
        <div class="image-logo">
          <div class="admin-indicator body-small-medium">A</div>
          <img *ngIf="userDetails.imageUrl; else default" class="brand-logo" [src]="userDetails.imageUrl ? userDetails.imageUrl : '../../../../assets/images/only-logo.svg'">
          <ng-template #default>
            <div class="default-admin-logo"></div>
          </ng-template>
        </div>

        <div class="user-brand-info-container">
          <div class="brand body-big-bold">Admin</div>
          <div class="user">{{userDetails.firstName + ' ' + userDetails.lastName}}</div>
        </div>
      </div>

      <mat-icon (click)="logOut()" class="material-icons logout">logout</mat-icon>
    </div>
  </ng-template>
</ng-container>
