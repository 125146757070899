import { Component, EventEmitter, Input, Output } from "@angular/core";
import { VendorStatusEnum } from "../../Enums/VendorStatusEnum";
import { RoundStatusEnum } from "../../Enums/RoundStatusEnum";

@Component({
  selector: 'status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent {
  @Input() status: VendorStatusEnum | RoundStatusEnum;
    
  getClass(userStatus) {
    switch (userStatus) {
      case RoundStatusEnum.PENDING:
      case VendorStatusEnum.PENDING:
        return 'on-hold';
      case RoundStatusEnum.COMPLETED:
      case RoundStatusEnum.DRAFT:
        return 'archive';
      case VendorStatusEnum.ACTIVE:
      case RoundStatusEnum.OPENED:
        return 'active';
      case VendorStatusEnum.LOCKED:
      case RoundStatusEnum.CLOSED:
        return 'disabled';
      case VendorStatusEnum.VERIFIED:
      case RoundStatusEnum.RESULT_PROCESSING:
        return 'waiting';
      default: 
        return '';
    }
  }
}