import {ChangeDetectorRef, Component, ContentChild, ElementRef, Input, QueryList} from '@angular/core';
import {ButtonTypesEnum} from "../../Enums/ButtonTypesEnum";

@Component({
  selector: 'button-with-icon',
  templateUrl: './button-with-icon.component.html',
  styleUrls: ['./button-with-icon.component.scss']
})
export class ButtonWithIconComponent {

  @Input() isBeforeIconPosition: boolean;

  @Input() disabled = false;

  @Input() iconName: string;

  @Input() buttonText: string;

  @Input() buttonType = ButtonTypesEnum.raised;

  @Input() onlyIcon: boolean;

  @ContentChild('button-with-icon') content: QueryList<ElementRef>;

  ButtonTypesEnum = ButtonTypesEnum;

  getClass() {
    let classes = this.onlyIcon ? 'only-icon' : '';
    switch (this.buttonType) {
      case ButtonTypesEnum.basic:
        classes += ' mat-flat-button';
      case ButtonTypesEnum.raised:
        classes += ' mat-raised-button mat-mdc-raised-button';
    }
    return classes;
  }

  constructor(    private cdr: ChangeDetectorRef
  ) {
  }

}
