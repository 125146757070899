import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormService {


  /**
   * Bind server errors to specific form field on specific form
   * @param errors - error to shoe
   * @param form - form where error must be shown
   * @param fieldName - in which field error must be shown
   */
  bindErrors(errors: any, form: FormGroup, fieldName: string) {
    if (!fieldName) return;

    const input = form.get(fieldName);
    if (!errors && fieldName)  {
      input.setErrors(null);
      return;
    }

    if (typeof errors === 'string') {
      input.setErrors({server: errors});
    } else {
      input.setErrors({server: errors.join(', ')});
    }
  }
}
