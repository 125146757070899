import {
  Component,
  DoCheck,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {APP_DATA} from "../../../general.app.config";
import {questionTypesConfig} from "../question-types-config";
import {QuestionTypes} from "../../../common/Enums/QuestionTypesEnum";
import {pairwise, startWith, Subject, takeUntil} from "rxjs";
import {EventDetailsModel} from "../../../common/models/EventDetailsModel";
import {AnswerDetailsModel} from "../../../common/models/AnswerDetailsModel";
import {EventsService} from "../../../core/services/events.service";
import {AnswerService} from "../../../core/services/answer.service";
import {RoundTypesEnum} from "../../../common/Enums/RoundTypesEnum";
import {CreateQuestionDetailModel, QuestionDetailsModel} from "../../../common/models/QuestionDetailsModel";
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS
} from "@angular/material/autocomplete";
import {QuestionService} from "../../../core/services/question.service";
import {MoveDirectionEnum} from "../../../common/Enums/MoveDirectionEnum";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {MatDialog} from '@angular/material/dialog';
import {CreateEventModalComponent} from "../../../common/components/create-event-modal/create-event-modal.component";
import {DateFormats} from "../../../common/Enums/date-formats";

@Component({
  selector: 'single-question-item',
  templateUrl: './single-question-item.component.html',
  styleUrls: ['./single-question-item.component.scss']
})
export class SingleQuestionItemComponent implements OnInit, OnDestroy, OnChanges, DoCheck {

  /**
   * List of answers
   */
  answerList: AnswerDetailsModel[];


  /**
   * List of events
   */
  sportEvents: EventDetailsModel[];

  /**
   * Filtered answer list, updated on search
   */
  filteredList: AnswerDetailsModel [];

  /**
   * Prevent using specific characters on number field
   */
  @HostListener("keydown", ["$event"]) onMouseOut(event: Event) {
    // @ts-ignore
    if (event.target.id === 'minValue' || event.target.id === 'maxValue' || event.target.id === 'incrementValue') {
      // @ts-ignore
      return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 190 && event.keyCode !== 188
    }
  }

  /**
   * Round details
   */
  @Input() round: any;

  /**
   * Flag if this question is single one in round, need to disable move and delete buttons
   */
  @Input() isSingleQuestion: boolean;

  //TODO need recheck and delete if unuse
  @Input() isLastQuestion: boolean;

  /**
   * Flag if round and question can be edited
   */
  @Input() isEditingDisable: boolean;

  /**
   * Question list
   */
  @Input() question: QuestionDetailsModel;

  /**
   * Save event and selected event
   */
  @Input() set _sportEvents(events) {
    this.sportEvents = events;
    if (this.sportEvents && this.question.id && this.question.sportEvent && this.question.sportEvent.id) {
      const event = this.sportEvents.find(e => e.id === this.question.sportEvent.id)
      this.questionFormGroup.get('sportEventId').patchValue(event);
      this.selecteEvent = event
    }
  };

  /**
   * Flag if this question is not saved
   */
  @Input() isUnsavedQuestions: boolean;

  /**
   * Save answers list, and filter already used answers in this question
   */
  @Input() set _answerList(answers) {
    this.answerList = answers;
    this.filteredList = this.usedId.length ?
      this.answerList.filter(answer => !this.usedId.some(id => id === answer.id)) :
      this.answerList;
  }

  /**
   * Current question ID
   */
  @Output() questionId = new EventEmitter<any>();

  /**
   * Output event for adding question
   */
  @Output() onAddNewQuestion = new EventEmitter<void>();

  /**
   * Output event for deleting question
   */
  @Output() onDeleteQuestion = new EventEmitter<void>();

  /**
   * Output event for moving question
   */
  @Output() moveQuestion = new EventEmitter<MoveDirectionEnum>();

  /**
   * Output event for check is current question was edited
   */
  @Output() isQuestionEdited = new EventEmitter<boolean>();

  /**
   * Output event for add new answer to current question
   */
  @Output() onAddNewAnswer = new EventEmitter<any>();

  /**
   * Autocomplete directive
   */
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  isAnyQuestionEdited: boolean;
  questionFormGroup: FormGroup;

  appData = APP_DATA;

  formField: any;

  /**
   * Contain all setting config for all question type
   */
  questionConfig = questionTypesConfig;

  /**
   * List of question types
   */
  questionTypes = QuestionTypes;

  isOther = false;

  /**
   * Selected event for question
   */
  selecteEvent: any;

  /**
   * Selected question type for question
   */
  questionType: any;

  dateFormats = DateFormats;

  private unsubscribe$: Subject<void> = new Subject<void>();

  questionTypeSelector: any[];

  /**
   * Editing answer ID
   */
  editedId: number;

  //TODO recheck and delete if unuse
  isNewItemEntered = false;

  /**
   * List of used in current question answers id
   */
  usedId = [];

  confirmButtonClicked = false;

  newAnswersImages: {[key: number]: { id: number, url: string }} = {};

  /**
   * List of validation rule for LIST and OPTION question type
   */
  validationRules = {
    LIST: {
      count: 3,
      strict: false,
      massage: this.appData.THREE_ANSWERS
    },
    LISTS: {
      count: 2,
      strict: false,
      massage: this.appData.TWO_ANSWERS
    },
    OPTIONS: {
      count: 2,
      strict: true,
      massage: this.appData.TWO_ANSWERS_ONLY
    },
  }

  /**
   * Show error when answer was edited and from it answer deleted all character
   */
  showEditAnswerRequiredError = false;

  /**
   * Show error when answer was edited and max lenght was entered
   */
  showEditAnswerMaxLenghtError = false;

  /**
   * Is current question was changed
   */
  isChangedQuestion = false;

  /**
   * Saving autocomplete, need for LISTS
   */
  newAnswerItemValueEntering = [];

  /**
   * General flag for any error
   */
  isShowAnyError = false;

  /**
   * Flag for control min max number of selected answers for different question types
   */
  showedAddingError = false;

  imageErrorMassege = false;

  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private answerService: AnswerService,
    private questionService: QuestionService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
  ) {
    this.buildForm();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isOther = this.round?.type === RoundTypesEnum.Other;

    if (changes.round && changes.round.currentValue && (!changes.round.previousValue || (changes.round.previousValue && changes.round.previousValue.type !== changes.round.currentValue.type))) {
      this.questionTypeSelector = this.isOther ?
        Object.values(this.questionTypes).filter(v => v.label !== QuestionTypes.SCORE.label) :
        Object.values(this.questionTypes);
      const oldQuestionType = this.questionType;
      this.questionType = this.isOther ? this.questionTypes.RANGE.label : this.questionTypes.SCORE.label;
      this.createFormFieldsByQuestionType(this.questionType, oldQuestionType);
    }
  }

  ngDoCheck() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.questionTypeSelector = this.isOther ? Object.values(this.questionTypes).filter(v => v.label !== QuestionTypes.SCORE.label) : Object.values(this.questionTypes);
    this.questionType = this.question?.type ? QuestionTypes[this.question.type].label : this.isOther ? this.questionTypes.RANGE.label : this.questionTypes.SCORE.label;
    this.questionFormGroup.get('type').patchValue(this.questionType);
    if (this.questionType === QuestionTypes.SCORE.label && !this.isOther) {
      this.questionFormGroup.get('sportEventId').addValidators(Validators.required)
    }

    if (this.question.id) {
      this.createFormFieldsByQuestionType(this.questionType);

      this.fillForm();
    }

    if (this.isOther && !this.question.id) {
      this.createFormFieldsByQuestionType(QuestionTypes.RANGE.label);
    }
    if (this.isEditingDisable) {
      this.questionFormGroup.disable();
    }

    this.questionFormGroup.get('type').valueChanges
      .pipe(
        startWith(this.questionFormGroup.get('type').value),
        pairwise(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([oldValue, newValue]) => {
        this.filteredList = this.answerList;
        this.usedId = [];
        this.editedId = null;
        this.createFormFieldsByQuestionType(newValue, oldValue);
      });
  }

  //TODO Temporary commented, was implemented to prevent scroll when autocomplete pannel opened, need find another solution
  openPannel(additionalStyle = '') {
    // var iDiv = document.createElement('div');
    // iDiv.className = 'cdk-overlay-backdrop cdk-overlay-transparent-backdrop cdk-overlay-backdrop-showing';
    // iDiv.id = 'additional-back';
    // const element = document.getElementsByClassName('cdk-overlay-container');
    // element[0].insertBefore(iDiv, element[0].childNodes[2]);
    // if (additionalStyle) {
    //   element[0].classList.add(additionalStyle);
    // }
  }

  //TODO Temporary commented, was implemented to prevent scroll when autocomplete pannel opened, need find another solution
  closePannel(additionalStyle = '') {
    // const elementToDelete = document.getElementById('additional-back');
    // elementToDelete.parentNode.removeChild(elementToDelete);
    // const element = document.getElementsByClassName('cdk-overlay-container');
    // if (additionalStyle) {
    //   element[0].classList.remove(additionalStyle);
    // }
  }

  /**
   * Fill form in case of editing round, if question type id LIST, LISTS or OPTION, add forms for answers
   */
  fillForm() {
    Object.keys(this.question)
      .forEach(property => {
        if (this.questionFormGroup.get(property) && property !== 'answers') {
          this.questionFormGroup.get(property).patchValue(this.question[property]);
        }

        if (property === 'type') this.questionType = this.questionFormGroup.get(property).value;

        if (property === 'answers') {
          this.usedId = this.question.answers.map(answer => answer.id);
          this.filteredList = this.answerList.filter(answer => !this.usedId.some(id => id === answer.id));

          if (this.questionType === QuestionTypes.OPTIONS.label || this.questionType === QuestionTypes.LIST.label) {

            for (let i = 0; i < this.question.answers.length - 1; i++) {
              this.createAnswerForm('answers');
            }

            this.pathAnswerValue(this.question.answers, property);
          } else {
            // @ts-ignore
            const firstAnswerGroup = this.question.answers.filter(answer => answer.groupName === 'group_0');

            for (let i = 0; i < firstAnswerGroup.length - 1; i++) {
              this.createAnswerForm('answers1st');
            }

            this.pathAnswerValue(firstAnswerGroup, 'answers1st');

            // @ts-ignore
            const secondAnswerGroup = this.question.answers.filter(answer => answer.groupName === 'group_1');

            for (let i = 0; i < secondAnswerGroup.length - 1; i++) {
              this.createAnswerForm('answers2nd');
            }

            this.pathAnswerValue(secondAnswerGroup, 'answers2nd');
          }
        }
        if (property === 'attributes') {
          const props = Object.keys(this.question[property]);
          props.forEach(pro => {
            if (this.questionFormGroup.get(pro)) {
              this.questionFormGroup.get(pro).patchValue(this.question[property][pro]);
            }
          });
        }
      });
  }

  /**
   * Create form for answers
   * @param formName form name
   */
  createAnswerForm(formName) {
    const answerForm = this.formBuilder.group({
      title: [''],
      imageId: [''],
      imageUrl: ['']
    });
    this[formName].push(answerForm);
  }

  /**
   * Patch answer value to the form
   * @param answers answers list
   * @param property custom property name
   */
  pathAnswerValue(answers, property) {
    const answerBody = answers.map(answer => {
      return {title: answer}
    });
    this.questionFormGroup.get(property).patchValue(answerBody);
  }

  buildForm() {
    this.questionFormGroup = new FormGroup({
      type: new FormControl(this.questionType, [Validators.required]),
      text: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      sportEventId: new FormControl(null),
      stateCtrl: new FormControl('')
    });
    this.checkIfQuestionChanged();
  }

  /**
   * Emit current question to the parent component to save it
   * Create question body, mark as touched and trying to save
   * Check if any error are present and return if it is
   * If value is fill and valid save question
   */
  saveQuestion() {
    this.confirmButtonClicked = true;
    this.questionFormGroup.markAllAsTouched();
    const questionBody = this.getQuestionBody();
    if (this.question && this.question.id) {
      questionBody.id = this.question.id;
    }
    if (this.showEditAnswerMaxLenghtError || this.showEditAnswerRequiredError || this.isShowAnyError) return;
    if (this['answersSelectedError'] || this['answers1stSelectedError'] || this['answers2ndSelectedError']) return;
    if (!this.questionFormGroup.valid) return;
    this.questionId.emit(questionBody);
  }

  /**
   * Check if current question was edited, it need for show error for unsaved question
   */
  checkIfQuestionChanged() {
    this.questionFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        if (!this.isAnyQuestionEdited) {
          const propArray = Object.keys(val).filter(prop => prop !== 'type');
          propArray.forEach(prop => {
            if (prop.includes('answer')) {
              if (val[prop].some(property => property.title)) {
                this.isAnyQuestionEdited = true;
                this.isQuestionEdited.emit();
              }
            } else {
              if (val[prop]) {
                this.isAnyQuestionEdited = true;
                this.isQuestionEdited.emit();
              }
            }
          })
        }
      });
  }

  /**
   * Generate question body based on quation type
   */
  getQuestionBody() {
    const questionValue = this.questionFormGroup.value;
    const newQuestionBody = new CreateQuestionDetailModel;
    newQuestionBody.type = questionValue.type;
    newQuestionBody.text = questionValue.text;
    newQuestionBody.sportEventId = questionValue.sportEventId;
    if (questionValue.sportEventId) {
      newQuestionBody.sportEventId = questionValue.sportEventId.id;
    }
    switch (questionValue.type) {
      case QuestionTypes.RANGE.label :
        const {maxValue, displayValue, minValue, incrementValue} = questionValue;
        const attributes = {maxValue, displayValue, minValue, incrementValue}
        newQuestionBody.attributes = attributes;
        break;
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
        const answerCheck = questionValue.answers.find(v => !v.title.id)
        this['answersSelectedError'] = !!answerCheck;
        if (answerCheck) {
          return;
        }
        newQuestionBody.answers = questionValue.answers.map(answer => {
          const answerId = answer.title.id;
          return {answerId};
        });
        break;
      case QuestionTypes.LISTS.label:
        const firstAnswerCheck = questionValue.answers1st.find(v => !v.title.id)
        const secondAnswerCheck = questionValue.answers2nd.find(v => !v.title.id)
        if (firstAnswerCheck || secondAnswerCheck) {
          this['answers1stSelectedError'] = !!firstAnswerCheck;
          this['answers2ndSelectedError'] = !!secondAnswerCheck;
          return;
        }
        const firstGroupAnswers = questionValue.answers1st.map(answer => {
          const answerId = answer.title.id;
          return {answerId, groupName: 'group_0'};
        });
        const secondGroupAnswers = questionValue.answers2nd.map(answer => {
          const answerId = answer.title.id;
          return {answerId, groupName: 'group_1'};
        });
        newQuestionBody.answers = [...firstGroupAnswers, ...secondGroupAnswers];
    }
    return newQuestionBody;
  }

  get answer1st() {
    return this.questionFormGroup.controls['answer1st'] as FormArray;
  }

  /**
   * Generate question form based on type
   * If this is first build, oldQuestionType will not present and just new fields will build
   * If this question type was reselected, field for old question type will be deleted, and new created
   * @param newQuestionType user data, type of User, gets from form
   * @param oldQuestionType user data, type of User, gets from form, optional parametr
   */
  createFormFieldsByQuestionType(newQuestionType, oldQuestionType?) {
    this.formField = this.questionConfig[newQuestionType];
    const validators = []

    if (oldQuestionType) {
      const oldFields = this.questionConfig[oldQuestionType];
      oldFields.forEach(field => {
        this.questionFormGroup.removeControl(field.fieldName);
      });

      this.formField.forEach(field => {
        const validators = [];

        if (field.type === 'chips') {
          this.questionFormGroup.addControl(field.fieldName, this.formBuilder.array([]));
          this[field.fieldName] = this.questionFormGroup.controls[field.fieldName] as FormArray;
          const answerForm = this.formBuilder.group({
            title: [''],
            imageId: [''],
            imageUrl: ['']
          });
          this[field.fieldName].push(answerForm);
        } else {
          if (field.fieldName === 'displayValue') {
            validators.push(Validators.maxLength(20));
            validators.push(Validators.minLength(2))
          } else {
            validators.push(Validators.max(9999))
          }
          validators.push(field.fieldName === 'incrementValue' ? Validators.min(1) : Validators.min(0))

          this.questionFormGroup.addControl(field.fieldName, this.formBuilder.control(''));
        }
        if (field.required) validators.push(Validators.required);


        if (field.type !== 'chips') {
          this.questionFormGroup.get(field.fieldName).setValidators(validators);
        }
        // @ts-ignore
        this.checkIfQuestionChanged();
        this.questionFormGroup.valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(val => {
            const answersSelectedKeys = Object.keys(val).filter(key => key.toLowerCase().includes('answer'));
            let ids = []
            answersSelectedKeys.forEach(key => {
              const answerCheck = val[key].find(v => !v.title.id);
              this[key + 'SelectedError'] = !!answerCheck && this.confirmButtonClicked;

              val[key].forEach(val => {
                if (val.title.id) ids.push(val.title.id)
              })
            })
            this.filteredList = this.answerList.filter(answer => !ids.some(id => id === answer.id));
          })
        this.questionFormGroup.get(field.fieldName).valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(val => {
            if (field.fieldName.toLowerCase().includes('answer')) {
              this[field.fieldName + 'Selected'] = val
                .map(item => item.title && item.title.id)
                .filter(value => !!value);
              this.usedId = val.map(selected => selected.title.id);
              this.filteredList = this.answerList.filter(answer => !this.usedId.some(id => id === answer.id));
            }
          });
      });
    } else {
      this.formField.forEach(field => {
        if (field.type === 'chips') {
          this.questionFormGroup.addControl(field.fieldName, this.formBuilder.array([]));
          this[field.fieldName] = this.questionFormGroup.controls[field.fieldName] as FormArray;
          const answerForm = this.formBuilder.group({
            title: [''],
            imageId: [''],
            imageUrl: ['']
          });
          this[field.fieldName].push(answerForm);
        } else {
          if (field.fieldName !== 'displayValue') {
            validators.push(Validators.max(9999))
            validators.push(Validators.min(0))
          }
        }
        if (field.required) validators.push(Validators.required)
        this.questionFormGroup.addControl(field.fieldName, this.formBuilder.control('', validators));
      });
      this.checkIfQuestionChanged();
    }
    if (this.isOther) {
      this.questionFormGroup.get('sportEventId').setValidators(null);
      this.questionFormGroup.get('sportEventId').setErrors(null);
    } else {
      if (this.questionType === this.questionTypes.SCORE.label) {
        this.questionFormGroup.get('sportEventId').addValidators(Validators.required);
        this.questionFormGroup.get('sportEventId').updateValueAndValidity();
      } else {
        this.questionFormGroup.get('sportEventId').setValidators(null);
        this.questionFormGroup.get('sportEventId').setErrors(null);
      }
    }
    this.isShowAnyError = false;
    this['answersSelectedError'] = false;
    this['answers1stSelectedError'] = false;
    this['answers2ndSelectedError'] = false;

    if (newQuestionType === QuestionTypes.RANGE.label) {
      this.questionFormGroup.setValidators(this.validateRangeValue.bind(this));
    }
  }

  /**
   * Validate all data for range question type
   * - minValue, maxValue and incrementValue must be present
   * - incrementValue must be greater than zero
   * - maxValue must be greater than minValue (maxValue > minValue => true)
   * - incrementValue must be less than difference between maxValue and  minValue (incrementValue < maxValue - minValue => true)
   * - incrementValue: sequential adding of an incrementValue to the Min Value should be equal to Max Value
   */
  validateRangeValue() {
    if (!this.questionFormGroup.get('minValue') ||
      !this.questionFormGroup.get('maxValue') ||
      !this.questionFormGroup.get('incrementValue')) return;

    const stringMinValue = this.questionFormGroup.get('minValue').value;
    const stringMaxValue = this.questionFormGroup.get('maxValue').value;
    const stringIncrementValue = this.questionFormGroup.get('incrementValue').value;

    const minValue = stringMinValue.length ? +stringMinValue : null;
    const maxValue = stringMaxValue.length ? +stringMaxValue : null;
    const incrementValue = stringIncrementValue.length ? +stringIncrementValue : null;

    if (minValue === null && maxValue === null && incrementValue === null) return;

    if (stringIncrementValue && incrementValue === 0) {
      if (!this.questionFormGroup.get('incrementValue').invalid) {
        this.questionFormGroup.get('incrementValue').setErrors({incrementValue: true});
      }
    }

    if ((stringMinValue && stringMaxValue)) {
      if (minValue >= maxValue) {
        if (!this.questionFormGroup.get('minValue').invalid) {
          this.questionFormGroup.get('minValue').setErrors({minValue: true});
        }
      } else {
        if (this.questionFormGroup.get('minValue').getError('minValue')) {
          this.questionFormGroup.get('minValue').setErrors({minValue: null});
          this.questionFormGroup.get('minValue').updateValueAndValidity({emitEvent: false})

        }
      }
    }
    if ((stringMinValue && stringMaxValue && stringIncrementValue)) {
      if (incrementValue > maxValue - minValue) {
        if (!this.questionFormGroup.get('incrementValue').invalid) {
          this.questionFormGroup.get('incrementValue').setErrors({incrementValue: true});
        }
      } else {
        if (this.questionFormGroup.get('incrementValue').getError('incrementValue')) {
          this.questionFormGroup.get('incrementValue').setErrors({incrementValue: null});
          this.questionFormGroup.get('incrementValue').updateValueAndValidity({emitEvent: false})
        }
      }

      if ((maxValue - (minValue + incrementValue)) % incrementValue !== 0) {
        if (!this.questionFormGroup.get('incrementValue').invalid) {
          this.questionFormGroup.get('incrementValue').setErrors({incrementValueAliquot: true});
        }
      } else {
        if (this.questionFormGroup.get('incrementValue').getError('incrementValueAliquot')) {
          this.questionFormGroup.get('incrementValue').setErrors({incrementValueAliquot: null});
          this.questionFormGroup.get('incrementValue').updateValueAndValidity({emitEvent: false})
        }
      }
    }
  }

  getOptionText(option) {
    return option.text;
  }

  getOptionTextEvent(option) {
    return option?.name;
  }

  /**
   * Send output event with new answer to te parent component with new answer data
   */
  addNewAnswer(name, i) {
    if (this.showEditAnswerMaxLenghtError || this.showEditAnswerRequiredError || this.showedAddingError) return;
    const text = this.questionFormGroup.get(name).value[i].title;
    const imageId = this.newAnswersImages[i]?.id;
    if (typeof text === 'string') this.onAddNewAnswer.emit({text, imageId});
  }

  /**
   * Select new answer to the answers list,validate available number of answers for current question type
   */
  addAnswer(event, name) {
    this.isChangedQuestion = true;
    event.stopPropagation();

    const answerForm = this.formBuilder.group({
      title: ['', Validators.maxLength(100)],
      imageId: [''],
      imageUrl: ['']
    });
    this[name].push(answerForm);
    const controlLength = this[name].controls.length;
    switch (this.questionType) {
      case QuestionTypes.OPTIONS.label:
        if (controlLength === 2) {
          this.showShackBar(2, name)
        }
      case QuestionTypes.LIST.label:
      case QuestionTypes.LISTS.label:
        if (controlLength === 10) {
          this.showShackBar(10, name)
        }
    }
  }

  /**
   * Show snack bar error when answers limit is achived
   */
  showShackBar(answersCount, name) {
    const massage = ` to the ${name} list`;
    this.snackBarService.showSnackBar(
      `You have added ${answersCount} of  ${answersCount} ability answers ${massage}`
    );
  }

  /**
   * Delete answer from the list of selected answers
   */
  deleteAnswerItem(e, index, name) {
    this.isChangedQuestion = true;
    const deletedId = this[name].controls[index].value.title.id;
    const idIndex = this.usedId.findIndex(id => id === deletedId);
    this.usedId.splice(idIndex, 1);
    this.filteredList = this.answerList.filter(answer => !this.usedId.some(id => id === answer.id));
    this[name].markAsTouched();

    this[name].removeAt(index);
    e.stopPropagation();
    this[name.fieldName + 'Selected'] = this.questionFormGroup.get(name).value
      .map(item => item.title && item.title.id)
      .filter(value => !!value);
  }


  prevent(e) {
    e.stopPropagation();
  }

  /**
   * Add new empty question
   */
  addNewQuestion() {
    if (this.isEditingDisable) return
    this.onAddNewQuestion.emit();
  }

  /**
   * Move question up
   */
  moveQuestionUp() {
    if (this.isSingleQuestion || this.isEditingDisable) return;
    this.moveQuestion.emit(MoveDirectionEnum.UP);
  }

  /**
   * Move question down
   */
  moveQuestionDown() {
    if (this.isSingleQuestion || this.isEditingDisable) return;
    this.moveQuestion.emit(MoveDirectionEnum.DOWN);
  }

  /**
   * Delete question card
   */
  deleteQuestion() {
    if (this.isSingleQuestion || this.isEditingDisable) return;
    this.onDeleteQuestion.emit();
  }

  /**
   * Start edit answer item event
   */
  editAnswer(event, answer) {
    this.imageErrorMassege = false;
    this.editedId = answer.id;
    event.stopPropagation();
    this.showEditAnswerRequiredError = false;
    this.showEditAnswerMaxLenghtError = false;
  }

  /**
   * Delete answer form BE
   */
  deleteAnswer(event, answer) {
    event.stopPropagation();

    this.answerService.deleteAnswersById(answer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.answerService.needUpdateAnswerList();
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)
        });
  }

  /**
   * Start edit event item event
   */
  editEvent(event, sportEvent) {
    event.stopPropagation();
    this.openEventModel(sportEvent)
  }

  /**
   * Delete event form BE
   */
  deleteEvent(event, sportEvent) {
    event.stopPropagation();

    this.eventsService.deleteEventById(sportEvent.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.eventsService.needUpdateEventList();
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)
        });
  }

  /**
   * Save edited answer
   */
  saveAnswer(event, input, index) {
    if (this.showEditAnswerMaxLenghtError || this.showEditAnswerRequiredError) return;
    let imageId = this.answerList.find(answer => answer.id === this.editedId).imageId;
    if (this.newAnswersImages[index]?.id) {
        imageId = this.newAnswersImages[index]?.id

    } else {
      if (!imageId) {
        imageId = null
      }
    }
    const body = {
      text: input,
      imageId,
      isHidden: false
    }

    this.answerService.updateAnswer(body, this.editedId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.answerService.needUpdateAnswerList();
          this.editedId = null;
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)
        })
    this.answerService.setAnswerItemStatus(false);
    event.stopPropagation();
  }

  /**
   * On edit answer input focus out send it event to service to prevent saving
   */
  focusOut() {
    this.answerService.setAnswerItemStatus(false);
  }

  /**
   * Process only LIST, LISTS and OPTION questions,
   * and control selected items and show error if it is not correspond with validation
   */
  isShowError(fieldName) {
    // this[fieldName].length != this.validationRules[this.questionType].count ?
    //   this[fieldName].setErrors({'count': true}) :
    //   this[fieldName].setErrors(null);
    let isError = false;

    switch (this.questionType) {
      case QuestionTypes.OPTIONS.label:
        if (!this[fieldName].touched) return false;
        this[fieldName].length != this.validationRules[this.questionType].count ?
          this[fieldName].setErrors({'count': true}) :
          this[fieldName].setErrors(null);
        isError = this[fieldName].length != this.validationRules[this.questionType].count;
        this.isShowAnyError = isError;
        return isError;
      case QuestionTypes.LISTS.label:
        if (!this['answers1st'].touched && !this['answers2nd'].touched) return false;
        this['answers1st'].length < this.validationRules[this.questionType].count &&
        this['answers2nd'].length < this.validationRules[this.questionType].count ?
          this[fieldName].setErrors({'count': true}) :
          this[fieldName].setErrors(null);
        isError = this[fieldName].length < this.validationRules[this.questionType].count;
        this.isShowAnyError = isError;
        return isError;
      case QuestionTypes.LIST.label:
        if (!this[fieldName].touched) return false;
        this[fieldName].length < this.validationRules[this.questionType].count ?
          this[fieldName].setErrors({'count': true}) :
          this[fieldName].setErrors(null);
        isError = this[fieldName].length < this.validationRules[this.questionType].count;
        this.isShowAnyError = isError;
        return isError;
    }
  }

  /**
   * Deleted edited answer if selector was closed without saving
   */
  onCloseAnswerSelector() {
    this.editedId = null;
    this.imageErrorMassege = false;
  }

  /**
   * Show general error for all field
   * @param fieldName field name
   * @param errorName error name
   */
  isShowGeneralError(fieldName, errorName) {
    if (!this.questionFormGroup.get(fieldName).touched) return false;
    return this.questionFormGroup.get(fieldName).hasError(errorName);
  }

  /**
   * Validate new answer adding or editing present answer
   */
  onAnswerInputChange(e) {
    this.showEditAnswerRequiredError = !(!!e.value);
    this.showEditAnswerMaxLenghtError = e.value.length > 100;
  }

  /**
   * Open add/edit event modal
   * @param event optional parametr, present if edit button was clicked
   */
  openEventModel(event?) {
    this.dialog
      .open(CreateEventModalComponent, {
        panelClass: ['event-builder'],
        data: event,
        autoFocus: false
      });
  }

  /**
   * Validate if this is allowed to add one more answer for answers list,
   * using in LIST, LISTS and OPTION question type
   * @param fieldName answer field name
   */
  isAddingAvailable(fieldName) {
    const fielValueLenght = this[fieldName].controls.length;
    switch (this.questionType) {
      case QuestionTypes.OPTIONS.label:
        return fielValueLenght < 2;
        break;
      case QuestionTypes.LIST.label:
      case QuestionTypes.LISTS.label:
        return fielValueLenght < 10;

    }
  }

  /**
   * Validate if done button must be shown on answer item
   * @param fieldName answer field name
   * @param index index of edited answer
   */
  isShowDoneEnterIcon(fieldName, index) {
    return this[fieldName].controls[index].value.title && this.newAnswerItemValueEntering[index];
  }

  /**
   * Processe event on snaser select
   * @param index index of edited answer
   */
  //TODO refactor unused parameter
  answerSelect(index, name) {
    this.isChangedQuestion = true;
    this.newAnswerItemValueEntering[index] = false;
  }

  /**
   * Search value for one of the list dependens of index
   * @param event autocomplete event
   * @param index index of edited answer
   */
  onAutocompleteInputEvent(event, index) {
    this.newAnswerItemValueEntering[index] = !!event.srcElement.value;
  }


  /**
   * Validate if it is need to show error when new answer adding
   * @param fieldName form name for answers
   * @param i index of edited answer
   */
  isShowAddingError(fieldName, i) {
    this.showedAddingError = this.questionFormGroup.get(fieldName).value[i].title.length > 100;
    return this.questionFormGroup.get(fieldName).value[i].title.length > 100;
  }

  /**
   * Clear selected event
   * @param e click event
   */
  clearEvent(e) {
    this.isChangedQuestion = true;
    e.stopPropagation();
    e.preventDefault();
    this.questionFormGroup.get('sportEventId').patchValue(null)
  }

  uploadAnswerImage(image, index) {
    this.newAnswersImages[index] = image;
    event.stopPropagation();
  }
}

