import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DialogComponent} from "./dialog.component";
import {DialogModel} from "../../models/DialogModel";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogRef: MatDialogRef<DialogComponent>;

  constructor(
    public dialog: MatDialog

  ) { }

  open(dialogConfig: DialogModel) {
    const defaultConfig = new DialogModel;
    const config = {...defaultConfig, ...dialogConfig}
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }
}
