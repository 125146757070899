import {StreakPrizeTypeEnum} from "../Enums/StreakPrizeTypeEnum";

export class StreakDayItemModel {
  day: number
  questionOneText: string
  questionTwoText: string
  prizeType: StreakPrizeTypeEnum
  prizeId: number
  reservedBalance: number
  points: number
}
