<div class="form-container" [ngClass]="{'unset': isUnset}">
  <form [formGroup]="answerFormGroup">
    <div class="form-field-container">
      <ng-container [ngSwitch]="questionType">
        <div class="question-text-label">{{question.text}}</div>
        <div *ngSwitchCase="'double input'" class="double-input-fields">
          <div class="form-field-with-label">
            <mat-label>{{question?.sportEvent ? question?.sportEvent?.teams?.home : 'Home team'}}:</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                required
                [placeholder]="'Enter correct answer for home team'"
                type="number"
                [formControlName]="'homeTeam'">
            </mat-form-field>
            <mat-error *ngIf="isShowError('homeTeam', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>{{question?.sportEvent ? question?.sportEvent?.teams?.away : 'Away team'}}:</mat-label>

            <mat-form-field appearance="outline">
              <input
                matInput
                required
                [placeholder]="'Enter correct answer for away team'"
                type="number"
                [formControlName]="'awayTeam'">
            </mat-form-field>
            <mat-error *ngIf="isShowError('awayTeam', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY}}</span>
            </mat-error>
          </div>

        </div>
        <div *ngSwitchCase="'input'" class="single-input-fields">
          <div class="form-field-with-label">
            <mat-form-field appearance="outline">
              <input
                matInput
                required
                [placeholder]="'Enter correct value'"
                type="number"
                [formControlName]="'answer'">
            </mat-form-field>
            <mat-error *ngIf="isShowError('answer', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY}}</span>
            </mat-error>
          </div>
        </div>

        <div *ngSwitchCase="'select'" class="select-fields">
          <div class="form-field-with-label">
            <div class="form-field-with-label">
              <mat-radio-group
                color="primary"
                [formControlName]="'answer'">
                <mat-radio-button
                  [value]="answer"
                  *ngFor="let answer of answerList">
                  {{answer.text}}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="isShowError('answer', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY_SELECT}}</span>
              </mat-error>
            </div>


<!--            <mat-form-field appearance="outline">-->
<!--              <mat-select-->
<!--                [(value)]="selectedItem"-->
<!--                required-->
<!--                [formControlName]="'answer'">-->
<!--                <mat-option-->
<!--                  *ngFor="let option of answerList"-->
<!--                  [value]="option">-->
<!--                  {{ option.text }}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--            <mat-error *ngIf="isShowError('answer', 'required')">-->
<!--              <i class="fas fa-exclamation-circle"></i>-->
<!--              <span>{{appData.MANDATORY}}</span>-->
<!--            </mat-error>-->
          </div>

        </div>

      </ng-container>
    </div>
  </form>
  <div class="warning-massage" *ngIf="isUnset">Please, set up correct answers for this question</div>
</div>
