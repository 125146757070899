import { AbstractControl } from "@angular/forms";

export const fromPositionValidator = (control: AbstractControl) => {
  if (!control.get('fromPosition')?.pristine && !Number.isNaN(control.get('fromPosition')?.value)) {
    if (Number(control.get('fromPosition')?.value) <= 0) {
      control.get('fromPosition')
        .setErrors({fromPositionErorr: 'From Place must not be less than 1'});
    } else  if (Number(control.get('fromPosition')?.value) > Number(control.get('toPosition')?.value)) {
      control.get('fromPosition')
        .setErrors({fromPositionErorr: 'From Place must always be equal or lower than To Place'});
    } else {
      control.get('fromPosition').setErrors(null);
    }
  }
  return null;
}