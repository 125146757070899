<ng-container *ngIf="(selectedValues$  | async) as selectedValues">
  <div class="type-selector" [matMenuTriggerFor]="menu">
    <div class="type">
      {{title}}<ng-content></ng-content>
    </div>
    <div class="indicator" *ngIf="selectedValues.length">
      {{selectedValues.length}}
    </div>
    <mat-icon class="material-icons material-icons-outlined">expand_more</mat-icon>
  </div>
  <mat-menu #menu="matMenu" class="filter-menu">
    <div class="menu-items">
      <div 
        class="menu-item" 
        *ngFor="let option of options"
        [ngClass]="{'selected': selectedValues.includes(option.value)}"
        (click)="selectValue(option.value)">
        <mat-icon class="material-icons selected material-icons-outlined">done</mat-icon>
        {{option.label}}
      </div>
      <ng-container *ngIf="cleanable">
        <mat-divider></mat-divider>
        <div class="menu-item clear" (click)="clear()">
          <mat-icon class="material-icons material-icons-outlined rollback">refresh</mat-icon>
          Clear filter
        </div>
      </ng-container>
    </div>
  </mat-menu>
</ng-container>
