import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {filter, Subject, takeUntil, throwError} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {APP_DATA} from "../../general.app.config";
import {EventsService} from "../../core/services/events.service";
import {AnswerService} from "../../core/services/answer.service";
import {RoundTypesEnum} from "../../common/Enums/RoundTypesEnum";
import {EventDetailsModel} from "../../common/models/EventDetailsModel";
import {AnswerDetailsModel} from "../../common/models/AnswerDetailsModel";
import {QuestionService} from "../../core/services/question.service";
import {QuestionDetailsModel} from "../../common/models/QuestionDetailsModel";
import {MoveDirectionEnum} from "../../common/Enums/MoveDirectionEnum";
import {orderBy} from "lodash";
import {SnackBarService} from "../../core/services/snack-bar.service";


@Component({
  selector: 'questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit, OnDestroy, OnChanges {

  round: any;

  /**
   * Save flag if round can be edited, calculated on round status
   */
  @Input() editableRound: any;

  /**
   * Save if current question was not saved
   */
  @Input() unsavedQuestions: boolean;

  /**
   * Round details for editing round
   */
  @Input() roundDetails: any;

  /**
   * Flag if round and question can be edited
   */
  @Input() isEditingDisable: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  appData = APP_DATA;

  /**
   * List of sport events
   */
  sportEvents: EventDetailsModel[];

  isOther = false;

  /**
   * List of answers
   */
  answerList: any;

  /**
   * Send if any of question field was edited to parent component,
   */
  @Output() isAnyQuestionEditedOutput = new EventEmitter<boolean>();

  /**
   * All question list, will contain saved round question, and new added question
   */
  questionList = []

  /**
   * Output all question to parent component
   */
  @Output() allQuestion = new EventEmitter<any>()

  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private answerService: AnswerService,
    private questionService: QuestionService,
    private snackBarService: SnackBarService,

  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.answerService.needUpdateAnswerList();
    this.answerService.getAnswerListStatus()
      .pipe(
        filter(response => response),
        takeUntil(this.unsubscribe$))
      .subscribe(() => this.getAnswersList());

    if (this.round?.id) {
      this.questionService.getQuestionList(this.round.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(( questions : QuestionDetailsModel[]) => {
          this.processQuestionList(questions);
        },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true)
          })
    } else {
      this.questionList.push(new QuestionDetailsModel());
      this.allQuestion.emit(this.questionList);
      this.isAnyQuestionEditedOutput.emit(true);
    }

    this.eventsService.needUpdateEventList();
    this.eventsService.getEventListStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchEvents());
  }

  /**
   * Fetch all events from BE
   */
  fetchEvents() {
    this.eventsService.getEventsList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((events: { total: number, records: EventDetailsModel[] }) => {
        this.sportEvents = events.records;
      },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)
        });
  }

  /**
   * Fetch all answers from BE
   */
  getAnswersList() {
    this.answerService.getAnswersList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((answers: AnswerDetailsModel[]) => {
        this.processAnswerList(answers);
      },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)

        })
  }

  //TODO need to recheck and delete if no use
  getQuestionList() {
    this.questionService.getQuestionList(this.round.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((questions: QuestionDetailsModel[]) => {
          this.processQuestionList(questions);
        },
        (error) => {
          throwError(error);
          this.questionService.questionListUpdated();
        })
  }

  /**
   * Add question to the list
   */
  processQuestionList(questions) {
    if (!questions.length) {
      this.questionList.push(new QuestionDetailsModel());
    }

    this.questionList = [...this.questionList, ...questions];
    this.allQuestion.emit(this.questionList)

    this.questionService.questionListUpdated();
  }
  /**
   * Sort answers by id
   */
  processAnswerList(answers) {
    this.answerList = orderBy(answers, ['id'], ['asc']);
  }

  /**
   * Save or create question
   */
  //TODO need to rename
  onAddQuestionId(questionBody, index) {
    const {id} = questionBody;
    delete questionBody.id;

    const request$ = id ?
      this.questionService.updateQuestion(id, questionBody) :
      this.questionService.createNewQuestion(questionBody);

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(question => {
          this.questionList[index] = question;
          this.allQuestion.emit(this.questionList);
          this.snackBarService.showSnackBar('Question saved successfully')
        },
        error => {
          this.snackBarService.showSnackBar(typeof error.message === "string" ? error.message : error.message.message, true);
        });
  }

  /**
   * Add new question to list
   */
  handleAddNewQuestion() {
    this.questionList.push(new QuestionDetailsModel());
    this.allQuestion.emit(this.questionList);
  }

  /**
   * Create new answer on BE
   */
  handleAddNewAnswer (newAnswer) {
    this.answerService.createNewAnswers(newAnswer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getAnswersList();
      },
        error => {
        if (typeof error.error.message === 'string'){
          this.snackBarService.showSnackBar(error.error.message, true)
        } else {
          this.snackBarService.showSnackbarWithTemplate(error.error.message, true)
        }
        });
  }

  /**
   * Delete question
   */
  handleDeleteQuestion(index) {
    this.questionList.splice(index, 1);
    this.allQuestion.emit(this.questionList);
    this.getAnswersList();
  }

  /**
   * Change question order
   */
  handleMoveQuestion(direction: MoveDirectionEnum, currentIndex: number) {
    if (this.questionList.length < 2) return;
    if (direction === MoveDirectionEnum.DOWN && currentIndex === this.questionList.length - 1) {
      this.questionList.unshift(this.questionList[currentIndex]);
      this.questionList.pop();
      return;
    }
    if (direction === MoveDirectionEnum.UP && currentIndex === 0) {
      this.questionList.push(this.questionList[currentIndex]);
      this.questionList.shift();
      return;
    }

    const newIndex = direction === MoveDirectionEnum.DOWN ? currentIndex + 1 : currentIndex - 1;
    this.questionList = this.arrayMove(this.questionList, currentIndex, newIndex);
    this.allQuestion.emit(this.questionList);
  }

  /**
   * On event edit question send to the parent component
   */
  handleEditQuestion() {
    this.isAnyQuestionEditedOutput.emit(true);
  }

  /**
   * Function to change order in array using for question change order
   */
  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };


  ngOnChanges(changes: SimpleChanges): void {
    if (!this.editableRound) {
      this.round = this.roundDetails;
    } else {
      this.round = this.editableRound;
    }
    this.isOther = this.round?.type === RoundTypesEnum.Other;
  }
}
