import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";
import {DateFormats} from "../../Enums/date-formats";

@Pipe({
  name: 'datesFormatPipe'
})
export class DatesFormatPipePipe implements PipeTransform {

  transform(date: string | Date, monthFormat = 'long'): string {
    const formattedDate = new Date(date);
    const offset = formattedDate.getTimezoneOffset() / 60;
    const hours = formattedDate.getHours() + offset;
    formattedDate.setHours(hours);
    const shortMonth = formattedDate.toLocaleString('en-GB', {month: 'short'});
    const longMonth = formattedDate.toLocaleString('en-GB', {month: 'long'});
    const monthNumber = formattedDate.getMonth() + 1;
    const lastDayOfMonth = moment(formattedDate).endOf('month').date();
    const shortYear = formattedDate.getFullYear() % 100;
    const longYear = formattedDate.getFullYear();
    const timeLocale = formattedDate.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true }).replace(' ', '')
    const day = formattedDate.getDate();

    const numericMonth = monthNumber < 10 ? '0' + monthNumber : monthNumber;
    const dateEnding = lastDayOfMonth === 31 ? 'st' : 'th';

    switch (monthFormat) {

      case (DateFormats.DD_MMM):
        return  `${day}-${shortMonth}`;

      case(DateFormats.MMM_YY):
        return `${shortMonth}-${shortYear}`;

      case(DateFormats.MMM_YYYY):
        return `${shortMonth}-${longYear}`;

      case(DateFormats.MMMM_YY):
        return `${longMonth}-${shortYear}`;

      case(DateFormats.MMMM_YYYY):
        return `${longMonth} ${longYear}`;

      case(DateFormats.MM_YYYY_slash):
        return `${numericMonth}/${longYear}`;

      case(DateFormats.MM_YY_slash):
        return `${numericMonth}/${shortYear}`;

      case(DateFormats.MM_YYYY):
        return `${numericMonth}-${longYear}`;

      case(DateFormats.MM_YY):
        return `${numericMonth}-${shortYear}`;

      case(DateFormats.DD_MMMM_YYYY):
        return `${lastDayOfMonth}${dateEnding} ${longMonth} ${longYear}`;

      case(DateFormats.DD_MMMM):
        return `${lastDayOfMonth}${dateEnding} ${longMonth}`;

      case (DateFormats.YYYY_MM_DD):
        const fullMonthNumber = monthNumber.toString().length === 1 ? '0' + monthNumber.toString() : monthNumber.toString();
        const fullDayNumber = day.toString().length === 1 ? '0' + day.toString() : day.toString();
        return `${longYear}-${fullMonthNumber}-${fullDayNumber}`;

      case (DateFormats.DD_MMMM_YY):
        return `${day} ${longMonth} ${shortYear}`;

      case (DateFormats.DD_MMMM_YYYY_hh_mmtz):
        return `${day} ${shortMonth} ${longYear}, ${timeLocale}`;

      case (DateFormats.MMM_DD_YYYY_at_hh_mmtz):
        return `${shortMonth} ${day}, ${longYear} at ${timeLocale}`;

      case (DateFormats.DD_MMM_YYYY):
        return  `${day} ${shortMonth}, ${longYear}`;

      default:
        return `${shortMonth}-${shortYear}`;
    }

  }

}
