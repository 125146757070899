import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PrizeTypeEnum} from "../../Enums/PrizeTypeEnum";
import {PrizeService} from "../../../core/services/prize.service";
import {filter, Subject, takeUntil} from "rxjs";
import {PrizeDetailsModel} from "../../models/PrizeDetailsModel";
import {APP_DATA} from "../../../general.app.config";
import {PersonalPrizeDitailsModel} from "../../models/PersonalPrizeDitailsModel";
import {cloneDeep} from "lodash";

export interface PrizeRequest {
  pageNumber?: number
  pageSize : number
  name: string
  status?: string
  pageKey?: string
  includeIds?: number[]
  type?: PrizeTypeEnum
}

@Component({
  selector: 'prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss']
})
export class PrizesComponent implements OnInit, OnDestroy {
  selectedPrizeId: number;

  selected: PrizeDetailsModel;

  selectedPrizesArray = [];

  @Input() set prizeType(prizeType: PrizeTypeEnum) {
    if (this._prizeType != prizeType) {
      this._prizeType = prizeType;
      this.fetchPrizesList();
    }
  }

  _prizeType: PrizeTypeEnum;

  @Input() label: string;

  @ViewChild('search') search: any;

  @Input() errorMessages: string;

  @Input() set editableRoundPrize (id) {
      this.selectedPrizeId = id;
  }

  @Input() hideTitle: boolean;

  @Input() isAnyPrizeSelected: boolean;

  @Input() personalPrizes: boolean;

  @Input() isEditingDisable: boolean;

  @Input() predictionSelectedPrize: any;

  @Input() multiSelect: boolean;

  @Output() selectedPrize = new EventEmitter< PrizeDetailsModel |PersonalPrizeDitailsModel>();

  @Output() selectedPrizes = new EventEmitter< PersonalPrizeDitailsModel[]>();

  /**
   * Store page size (cant be changed by user)
   */
  pageSize = 6;

  pageNumber = 1;

  pageKeyArray = [''];

  name = '';

  status = 'READY';

  prizeList: PrizeDetailsModel[] |PersonalPrizeDitailsModel[];

  /**
   * Indicator to show loader
   */
  isLoading = false;

  totalPrizeLenght: number;

  appData = APP_DATA;

  isPrizeSelected = false;

  isShowErrorTime = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private prizeService: PrizeService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.prizeService.needUpdatePrizeList();
    this.prizeService.getPrizeListStatus()
      .pipe(
        filter(response => response),
        takeUntil(this.unsubscribe$))
      .subscribe(val => this.fetchPrizesList())
    setTimeout(() => {
      this.isShowErrorTime = true;
    }, 1000)
  }
  /**
   * Fetch all prize data based on request body
   */
  fetchPrizesList() {
    this.isLoading = true;
    const body = this.getRequestBody(!!this.personalPrizes);
    const request$ = this.personalPrizes ?
      this.prizeService.getPersonalPrizeList(body) :
      this.prizeService.getPrizeList(body);
    request$
      // @ts-ignore
      .pipe(takeUntil(this.unsubscribe$))
      // @ts-ignore
      .subscribe((prizeInfo) => {
        if (this.selectedPrizesArray.length) {
          this.selectedPrizesArray.shift();
        }
        if (this.personalPrizes) {
          if (!this.pageKeyArray[this.pageNumber] && prizeInfo.pageKey) {
            this.pageKeyArray[this.pageNumber] = prizeInfo.pageKey;
          }
          if (!prizeInfo.records.length && this.pageNumber !== 1) {
            this.pageNumber--;
            this.fetchPrizesList();
            return;
          }
          this.prizeList = prizeInfo.records.map(prize => {
            const newPrize = cloneDeep(prize);
            newPrize.id = prize.tokenId;
            return newPrize;
          });
        } else {
          this.prizeList = prizeInfo.records;
        }
        this.totalPrizeLenght = this.personalPrizes ?  prizeInfo.pageKey ? prizeInfo.records.length  + 1 : prizeInfo.records.length : prizeInfo.total;
        this.isLoading = false;
      });
  }

  /**
   * Create request body based on page number, page size, filtering and searching and pageKeyArray
   */
  getRequestBody(isPersonalPrize) {
    const {pageNumber, pageSize, name, pageKeyArray, status} = this;
    const pageKey = pageKeyArray[pageNumber-1];
    if (isPersonalPrize) {
      return {
        pageKey,
        pageSize,
        name
      }
    }
    const body: PrizeRequest = {
      pageNumber,
      pageSize,
      name,
      status
    }
    if (this.predictionSelectedPrize && this.predictionSelectedPrize.id) {
      body.includeIds = [this.predictionSelectedPrize.id];
    }
    if (this._prizeType) {
      body.type = this._prizeType;
    }
    return body;
  }

  /**
   * Listening pagination and ping prizes request to update data
   */
  onPaginateChange(event) {

    event.pageIndex > event.previousPageIndex ?
      event.pageIndex - event.previousPageIndex > 1 ? this.pageNumber = event.pageIndex + 1 : this.pageNumber++ :
      this.pageNumber === 1 ? this.pageNumber : event.previousPageIndex - event.pageIndex >= 1 ? this.pageNumber = event.pageIndex + 1 : this.pageNumber--;
    this.fetchPrizesList();
  }

  /**
   * Listening search event and send request to BE
   */
  onSearch() {
    this.name = this.search.nativeElement.value;
    this.fetchPrizesList();
  }

  /**
   * Listening event from child component and store selected prize
   * @param prizeInfo -  prize item
   */
  onSelectedPrize(prizeInfo) {
    this.selectedPrizeId = prizeInfo.prize.id;
    this.selected = prizeInfo.prize;
    if (this.multiSelect) {
      if (prizeInfo.isSelect) {
        if (!this.selectedPrizesArray.some(p => p.id === prizeInfo.prize.id && p.type === prizeInfo.prize.type)) {
          this.selectedPrizesArray.push(prizeInfo.prize);
        }
      } else {
        const index = this.selectedPrizesArray.findIndex(p => p.id === prizeInfo.prize.id && p.type === prizeInfo.prize.type);
        this.selectedPrizesArray.splice(index, 1)
      }
      this.selectedPrizes.emit(this.selectedPrizesArray);
      this.isPrizeSelected = !!this.selectedPrizesArray.length;

    } else {
      this.selectedPrize.emit(prizeInfo.prize);
      this.isPrizeSelected = true;

    }
  }
}
