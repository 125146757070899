<div class="create-modal-container">
  <div class="modal-title body-medium-medium">
    {{data?.question.text}}
  </div>
  <div class="dialog-box-content">
    <form [formGroup]="answerFormGroup" (ngSubmit)="submitRightAnswers()">
      <div class="form-field-container">
        <ng-container [ngSwitch]="questionType">
          <div *ngSwitchCase="'double input'" class="double-input-fields">
            <div class="form-field-with-label">
              <mat-label>{{sportEvent ? sportEvent.teams.home : 'Home team'}}:</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  required
                  [placeholder]="'Enter correct answer for home team'"
                  type="number"
                  [formControlName]="'homeTeam'">
              </mat-form-field>
              <mat-error *ngIf="isShowError('homeTeam', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>{{sportEvent ? sportEvent.teams.away : 'Away team'}}:</mat-label>

              <mat-form-field appearance="outline">
                <input
                  matInput
                  required
                  [placeholder]="'Enter correct answer for away team'"
                  type="number"
                  [formControlName]="'awayTeam'">
              </mat-form-field>
              <mat-error *ngIf="isShowError('homeTeam', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY}}</span>
              </mat-error>
            </div>

          </div>
          <div *ngSwitchCase="'input'" class="single-input-fields">
            <div class="form-field-with-label">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  required
                  [placeholder]="'Enter correct value'"
                  type="number"
                  [formControlName]="'answer'">
              </mat-form-field>
              <mat-error *ngIf="isShowError('answer', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY}}</span>
              </mat-error>
            </div>
          </div>

          <div *ngSwitchCase="'select'" class="select-fields">
            <div class="form-field-with-label">
              <mat-label>Select answer:</mat-label>

              <mat-form-field appearance="outline">
                <mat-select
                  [(value)]="selectedItem"
                  required
                  [formControlName]="'answer'">
                  <mat-option
                    *ngFor="let option of answerList"
                    [value]="option">
                    {{ option.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="isShowError('answer', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{appData.MANDATORY}}</span>
              </mat-error>
            </div>

          </div>

        </ng-container>
      </div>

      <div class="buttons-container">
        <button
          id="stayButton"
          class="regular-button secondary-button"
          mat-stroked-button
          color="primary" mat-dialog-close>Close
        </button>
        <button
          type="submit"
          class="regular-button"
          mat-flat-button
          color="primary">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
