// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";

@Injectable()
export class UriConfig {
  baseApiUrl = environment.apiUrl;

  auth = `${this.baseApiUrl}auth`;

  logout = `${this.baseApiUrl}auth/logout`;

  refresh = `${this.baseApiUrl}auth/refresh`;

  brands = `${this.baseApiUrl}brands`;

  user = `${this.baseApiUrl}vendors`;

  files = `${this.baseApiUrl}files`;

  login = `${this.baseApiUrl}auth/local/signin`;

  signUp = `${this.baseApiUrl}auth/local/signup`;

  validateResetToken = `${this.baseApiUrl}auth/validate-reset-password`;

  resetPassword = `${this.baseApiUrl}auth/reset-password`;

  restorePassword = `${this.baseApiUrl}auth/restore-password`;

  changePassword = `${this.baseApiUrl}auth/change-password`;

  rounds = `${this.baseApiUrl}rounds`;

  sportEvents = `${this.baseApiUrl}sports-events`;

  prizes = `${this.baseApiUrl}prizes`;

  personalPrizes = `${this.baseApiUrl}prizes/personal`;

  answers = `${this.baseApiUrl}answers`;

  questionsList = `${this.baseApiUrl}questions/list`;

  questions = `${this.baseApiUrl}questions`;

  teams = `${this.baseApiUrl}teams`;

  streaks = `${this.baseApiUrl}streaks`;

  questionsManual = `${this.baseApiUrl}questions/resulting`;

  correctAnswers = `${this.baseApiUrl}answers/correct`;

  videoQuestion = `${this.baseApiUrl}video-questions`;

  analytics = `${this.baseApiUrl}analytics`;

  admins = `${this.baseApiUrl}admins`;

  vendors = `${this.baseApiUrl}vendors`;

  settings = `${this.baseApiUrl}settings`;

  leaderboards = `${this.baseApiUrl}leaderboards`;

  vendorAnalytic = `${this.baseApiUrl}analytics/vendor`;
  
  heroesRewards = `${this.baseApiUrl}heroes-rewards`;

}
