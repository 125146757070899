<div class="question-details-container">
  <div class="question-details-header body-big-bold">QUESTIONS</div>
  <div class="question-grid">
    <div *ngFor="let question of questionList; let i = index" class="question-container">
      <single-question-item
        [round]="round"
        [isSingleQuestion]="questionList.length === 1"
        [isLastQuestion]="i === questionList.length - 1"
        [question]="question"
        [_answerList]="answerList"
        [_sportEvents]="sportEvents"
        [isUnsavedQuestions]="unsavedQuestions"
        [isEditingDisable]="isEditingDisable"
        (questionId)="onAddQuestionId($event, i)"
        (onAddNewAnswer)="handleAddNewAnswer($event)"
        (onAddNewQuestion)="handleAddNewQuestion()"
        (onDeleteQuestion)="handleDeleteQuestion(i)"
        (moveQuestion)="handleMoveQuestion($event, i)"
        (isQuestionEdited)="handleEditQuestion()">
      </single-question-item>
    </div>
  </div>
</div>
