import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  uploadImage(file: any) {
    return this.http.post<any>(this.uriConfig.files + '/image', file);
  }

  uploadVideo(file: any) {
    return this.http.post<any>(this.uriConfig.files + '/video', file);
  }

  getFileById(id: number) {
    return this.http.get<any>(this.uriConfig.files + '/' + id);
  }
}
