import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {BrandDetailsModel} from "../../common/models/BrandDetailsModel";
import {UserDetailsModel} from "../../common/models/UserDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  currentUser$ = new BehaviorSubject<UserDetailsModel>(null);
  currentBrand$ = new BehaviorSubject<BrandDetailsModel>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  fetchUserInfo():Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.user);
  }

  fetchAdminInfo():Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.admins);
  }


  fetchBrandInfo(): Observable<BrandDetailsModel[]> {
    return  this.http.get<BrandDetailsModel[]>(this.uriConfig.brands)
  }

  updateVendorUser(body) {
    return this.http.patch<any>(this.uriConfig.user, body);
  }

  updateBrandInfo(body, id) {
    return this.http.patch<any>(this.uriConfig.brands + '/' + id, body);
  }

  createBrand(body) {
    return this.http.post<any>(this.uriConfig.brands, body);
  }
}
