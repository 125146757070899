import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {catchError, map, Observable, of, switchMap } from 'rxjs';
import {AuthService} from "../services/auth.service";
import {LocalStorageService} from "../services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    return this.getCurrentUser().pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    )
  }
  /**
   * check if user token is set, navigate to dashboard, if no, navigate to login for admin
   */
  getCurrentUser(): Observable<any> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (!user) {
          this.router.navigate(['/auth/login']);
        } else {
          const role = this.authService.getCurrentRole();
          if (!role) {
            this.router.navigate(['/auth/login']);
            this.authService.logOut()
            return;
          }
          if (role === 'ADMIN') {
            return user;
          } else {
            this.router.navigate(['/v/']);
          }
        }
      })
    );
  }

}
