import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionTypes} from "../../../common/Enums/QuestionTypesEnum";
import {APP_DATA} from "../../../general.app.config";
import {Subject, takeUntil} from 'rxjs';
import {AnswersRequest, QuestionResultDetailsModel} from "../../../common/models/QuestionResultDetailsModel";

@Component({
  selector: 'single-correct-question',
  templateUrl: './single-correct-question.component.html',
  styleUrls: ['./single-correct-question.component.scss']
})
export class SingleCorrectQuestionComponent implements OnInit {

  /**
   * Current question
   */
  @Input() question: QuestionResultDetailsModel;

  /**
   * List of answers used in current question
   */
  @Input() answerList: AnswersRequest[];

  /**
   * Flag if current round was completed (cant be edited)
   */
  @Input() isCompleted: boolean;

  /**
   * Is correct answer unset for current question
   */
  @Input() isUnset: boolean;

  /**
   * Output event for setted correct answer
   */
  @Output() correctAnswer = new EventEmitter<any>();

  /**
   * Output event is selected correct answers was changed
   */
  @Output() valueWasChanged = new EventEmitter<boolean>();

  answerFormGroup: FormGroup;

  questionType: string;

  appData = APP_DATA;

  selectedItem: AnswersRequest;

  private unsubscribe$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.questionType = this.getFormType();
    this.createFormField();
    if (this.question.correctAnswer) {
      const questionId = this.question.id;
      const {answerId, score, value, id} = this.question.correctAnswer;
      this.fillForm();
      if (score) {
        this.correctAnswer.emit({score, questionId, id});
      }
      if (answerId) {
        this.correctAnswer.emit({answerId, questionId, id});
      }
      if (value) {
        this.correctAnswer.emit({value, questionId, id});
      }
      if (this.isCompleted) {
        this.answerFormGroup.disable();
      }
    }

    this.answerFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.valueWasChanged.emit(true);
        this.valueChangeProcess(value);
      });
  }

  /**
   * Fill current question form depending of question type
   */
  fillForm() {
    switch (this.question.type) {
      case QuestionTypes.SCORE.label:
        const score = this.question.correctAnswer.score.split(' : ')
        this.answerFormGroup.get('homeTeam').patchValue(+score[0]);
        this.answerFormGroup.get('awayTeam').patchValue(+score[1]);
        break;
      case QuestionTypes.RANGE.label: {
        this.answerFormGroup.get('answer').patchValue(this.question.correctAnswer.value);
        break
      }
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
      case QuestionTypes.LISTS.label:
        const answer = this.answerList.find(a => a.id === this.question.correctAnswer.answerId);
        this.answerFormGroup.get('answer').patchValue(answer);
        this.selectedItem = answer;
    }
  }

  /**
   * Is value for current question was changed.
   * Save is edited flag for active button indicator and sat selected value
   */
  valueChangeProcess(value) {
    const isEdited = !!this.question.correctAnswer;
    const questionId = this.question.id;
    const id = this.question?.correctAnswer?.id;
    switch (this.question.type) {
      case QuestionTypes.SCORE.label:
        if (Number.isInteger(value.homeTeam) && Number.isInteger(value.awayTeam)) {
          this.correctAnswer.emit({score: `${value.homeTeam} : ${value.awayTeam}`, questionId, isEdited, id});
        } else {
          this.correctAnswer.emit({score: '', questionId, isEdited, id});
        }
        break;
      case QuestionTypes.RANGE.label: {
        if (value !== undefined && value !== null) {
          this.correctAnswer.emit({value: value.answer, questionId, isEdited, id});
        }
        break
      }
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
      case QuestionTypes.LISTS.label:
        this.correctAnswer.emit({answerId: value.answer.id, questionId, isEdited, id});
    }
  }

  /**
   * Get form type depending of question type, use for questionType property
   */
  getFormType(): string {
    switch (this.question.type) {
      case QuestionTypes.SCORE.label:
        return 'double input';
      case QuestionTypes.RANGE.label:
        return 'input';
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
      case QuestionTypes.LISTS.label:
        return 'select';
    }
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.answerFormGroup.get(fieldName).touched) return false;
    return this.answerFormGroup.get(fieldName).hasError(errorName);
  }

  /**
   * Create form fields depending on questionType property
   */
  createFormField() {
    if (this.questionType === 'double input') {
      this.answerFormGroup = new FormGroup({
        homeTeam: new FormControl('', [Validators.required]),
        awayTeam: new FormControl('', [Validators.required]),
      });
    } else {
      this.answerFormGroup = new FormGroup({
        answer: new FormControl('', [Validators.required]),
      });
    }
  }
}
