import {Component, Input} from '@angular/core';
import {ProfileService} from "../../../core/services/profile.service";
import {Observable} from "rxjs";
import {UserDetailsModel} from "../../models/UserDetailsModel";
import {AuthService} from "../../../core/services/auth.service";

@Component({
  selector: 'brand-sidenav',
  templateUrl: './brand-sidenav.component.html',
  styleUrls: ['./brand-sidenav.component.scss']
})
export class BrandSidenavComponent  {

  @Input() isAdmin: boolean;

  userDetails$: Observable<UserDetailsModel> = this.profileService.currentUser$;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService
  ) {
  }

  logOut() {
    this.authService.logOut();
  }

}
