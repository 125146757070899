export enum QuestionTypesEnum {
  CORRECT_SCORE_FORMAT = 'Correct score format',
  SELECT_NUMBER_FROM_SLIDER = '\Select number from slider',
  SELECT_FROM_2_ANSWERS_OPTION = '\Select from 2 answers option',
  ONE_FROM_1_LIST = '1 from 1 list',
  ONE_FROM_2_LIST = '1 from 2 list',
}

export const QuestionTypes = {
  SCORE: {text: 'Correct score format', label: 'SCORE'},
  RANGE: {text: 'Select number from slider', label: 'RANGE'},
  OPTIONS: {text: 'Select from 2 answers option', label: 'OPTIONS'},
  LIST: {text: '1 from 1 list', label: 'LIST'},
  LISTS: {text: '1 from 2 list', label: 'LISTS'},
}

