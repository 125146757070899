import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ManyErrorSnackBarComponent} from "../../common/components/many-error-snack-bar/many-error-snack-bar.component";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }
  showSnackBar(message: string, isError = false, action = 'ok', duration: number = 5000): void {
    const classes = ['snackbar'];
    if (isError) {
      classes.push('error-massage');
    }
    this.snackBar.open(message, action, {
      duration,
      panelClass: classes
    });
  }

  showSnackbarWithTemplate(massages: string[],  isError = false, duration: number = 5000): void {
    const classes = ['snackbar'];
    if (isError) {
      classes.push('error-massage');
    }
    this.snackBar.openFromComponent(ManyErrorSnackBarComponent, {
      data: massages,
      panelClass: classes,
      duration
    });
  }
}
