import {RoundTypesEnum} from "../Enums/RoundTypesEnum";
import {RoundSportEnum} from "../Enums/RoundSportEnum";
import {RoundStreakEnum} from "../Enums/RoundStreakEnum";
import {StreakPrizeDetailsModel} from "./StreakPrizeDetailsModel";

export class SingleRoundItemDetailsModel {
  name: string
  description: string
  type: RoundTypesEnum
  sport: RoundSportEnum | RoundStreakEnum []
  openDate: string
  closeDate: string
  prizeId: number
  backgroundImageDesktopId: number
  backgroundImageMobileId: number

  questionsIds?: number[]

  streakDays?:StreakPrizeDetailsModel[];
}
