import {Component, EventEmitter, HostListener, OnChanges, Output, SimpleChanges} from '@angular/core';
import {StreakPrizeDetailsModel} from "../../../common/models/StreakPrizeDetailsModel";
import {Input, OnInit} from '@angular/core';
import {StreakPrizeTypeEnum} from "../../../common/Enums/StreakPrizeTypeEnum";
import {FormControl, FormGroup, FormBuilder, Validators, ValidatorFn} from '@angular/forms';
import {APP_DATA} from "../../../general.app.config";
import {Subject, takeUntil} from "rxjs";
import {PrizeService} from "../../../core/services/prize.service";
import {cloneDeep} from "lodash";
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {PrizeTypeEnum} from "../../../common/Enums/PrizeTypeEnum";
import {PrizeDetailsModel} from "../../../common/models/PrizeDetailsModel";
import {CreatePrizeComponent} from "../../../common/components/create-prize/create-prize.component";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {RemainingCount} from "./remainingCount";

@Component({
  selector: 'single-streak-prize',
  templateUrl: './single-streak-prize.component.html',
  styleUrls: ['./single-streak-prize.component.scss']
})
export class SingleStreakPrizeComponent implements OnInit, OnChanges {

  /**
   * Current prize
   */
  prize: StreakPrizeDetailsModel;

  /**
   * set current prize
   */
  @Input() set _prize(prize) {
    this.prize = prize;
  }


  /**
   * Index of current prize in prizes array
   */
  @Input() index: number;

  /**
   * Process achived array of prizes used in current game, but not saved, filter based on this logic nftPrizeList
   * And split between nft and sft
   */
  @Input() set manuallyUsed(prizeList) {
    if (this.nftPrizeList) {
      this.nftPrizeList  = this.allPrizesList.filter(prize => this.prizeFiltering(prize, prizeList));

      this.onlyNft = this.nftPrizeList.filter(p => p.type === PrizeTypeEnum.NFT);
      this.onlySft = this.nftPrizeList.filter(p => p.type === PrizeTypeEnum.SFT);
    }
  }

  /**
   * List of selected prize id
   */
  @Input() savedPrizes: number[];

  /**
   * Is current prize is not saved
   */
  isUnsavedPrize: boolean

  /**
   * Set is current prize not saved
   */
  @Input() set _isUnsavedPrize (flag) {
    this.isUnsavedPrize = flag;
  }

  /**
   * Selected nft prize for current day
   */
  selectedNftOption: any;

  /**
   * Selected sft prize for current day
   */
  selectedSftOption: any;

  /**
   * Prizes which are not used(data from be)
   */
  nftPrizeList: PrizeDetailsModel[];

  /**
   * Prizes which are not used, but not saved
   */
  allPrizesList: PrizeDetailsModel[];

  /**
   * List of nft prizes
   */
  onlyNft: PrizeDetailsModel[];

  /**
   * List of sft prizes
   */
  onlySft: PrizeDetailsModel[];

  /**
   * Set full list of prizes and split it to sft or nft
   */
  @Input() set _nftPrizeList(nftPrizeList: PrizeDetailsModel[]) {
    if (nftPrizeList) {
      this.allPrizesList = nftPrizeList;
      this.nftPrizeList  = nftPrizeList.filter(prize => prize.availableCount > 0 || prize.id === this.prize?.prizeId);

      this.onlyNft = this.nftPrizeList.filter(p => p.type === PrizeTypeEnum.NFT);
      this.onlySft = this.nftPrizeList.filter(p => p.type === PrizeTypeEnum.SFT);
    }
  }

  /**
   * Output event if prize was changed
   */
  @Output() changedPrize = new EventEmitter<StreakPrizeDetailsModel>();

  /**
   * Output event for check validity of prize
   */
  @Output() validPrize = new EventEmitter<boolean>();

  /**
   * List of prizes types(hardcoded)
   */
  prizeTypes = Object.keys(StreakPrizeTypeEnum);

  streakPrizeFormGroup: FormGroup;

  /**
   * Selected type of prize
   */
  selectedType: StreakPrizeTypeEnum;

  StreakPrizeTypeEnum = StreakPrizeTypeEnum;

  /**
   * Available points is hardcoced value
   */
  pointNumber = [5, 10, 20];

  appData = APP_DATA;

  /**
   * Selected point number
   */
  selectedPoints: number;

  /**
   * Prevent using specific character (+ - = , .)
   */
  @HostListener("keydown", ["$event"]) onMouseOut(event: Event) {
    // @ts-ignore
    if (event.target.id === 'balance') {
      // @ts-ignore
      return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 190 && event.keyCode !== 188
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Form field for specific prize
   */
  formFieldConfig = {
    [StreakPrizeTypeEnum.NFT]: ['nft'],
    [StreakPrizeTypeEnum.SFT]: ['nft', 'reservedBalance'],
    [StreakPrizeTypeEnum.POINTS]: ['points'],
  }

  constructor(
    private formBuilder: FormBuilder,
    private prizeService: PrizeService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private remainingCount: RemainingCount
  ) {
  }

  /**
   * save selected nft option
   * @param event - selected prize info
   */
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedNftOption = event.option.value;
  }

  /**
   * save selected sft option
   * @param event - selected prize info
   */
  onSftOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedSftOption = event.option.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.nftPrizeList && this.prize) {
      setTimeout(() => {
        if ((this.prize.prizeType === StreakPrizeTypeEnum.NFT ||
            this.prize.prizeType === StreakPrizeTypeEnum.SFT) &&
          this.prize.prizeId) {
          const prize = this.nftPrizeList.find(p => p.id === this.prize.prizeId)
          if (!this.streakPrizeFormGroup.get('nft').value) this.streakPrizeFormGroup.get('nft').patchValue(prize);

          if (this.prize.prizeType === StreakPrizeTypeEnum.SFT) {
            this.selectedSftOption = prize;
            if (!this.streakPrizeFormGroup.get('reservedBalance').value) this.streakPrizeFormGroup.get('reservedBalance').patchValue(this.prize.reservedBalance);
          }

          if (this.prize.prizeType === StreakPrizeTypeEnum.NFT) {
            this.selectedNftOption = prize;
          }

        }
        this.streakPrizeFormGroup.valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(response => {
            this.prize.prizeType = response.type;
            this.prize.prizeId = response.nft?.id;
            this.prize.reservedBalance = response.reservedBalance;
            this.prize.points = response.points;
            this.validPrize.emit(this.streakPrizeFormGroup.valid);
            this.changedPrize.emit(this.prize);
          })

      }, 100);
    }
    if (this.isUnsavedPrize && this.streakPrizeFormGroup){
      switch (this.prize.prizeType){
        case StreakPrizeTypeEnum.NFT:
          if (this.prize.selected && !this.prize.prizeId) this.streakPrizeFormGroup.markAllAsTouched();
          break;
        case StreakPrizeTypeEnum.SFT:
          if (this.prize.selected && (!this.prize.prizeId || !this.prize.reservedBalance)) this.streakPrizeFormGroup.markAllAsTouched();
          break
        case StreakPrizeTypeEnum.POINTS:
          if (this.prize.selected && !this.prize.points) this.streakPrizeFormGroup.markAllAsTouched();
          break
      }
    }
  }

  ngOnInit() {
    this.streakPrizeFormGroup = new FormGroup({
      type: new FormControl(this.prize.prizeType)
    })
    this.getPrizeValue();
    this.selectedType = this.prize.prizeType;
  }

  /**
   * Build form for prize depending on different prize type
   * Set saved value
   * Set validators
   * Mark as touched if unsaved prize
   * @param event - selected prize info
   */
  getPrizeValue() {
    this.streakPrizeFormGroup.clearValidators();
    switch (this.prize.prizeType) {
      case StreakPrizeTypeEnum.SFT:
        this.formFieldConfig[StreakPrizeTypeEnum.SFT].forEach(control => {
          this.streakPrizeFormGroup.addControl(
            control,
            this.formBuilder.control(control === 'nft' ? '' : this.prize.reservedBalance, [Validators.required, Validators.min(1)]))
        });
        this.streakPrizeFormGroup.addValidators([this.remainingCount.validatePrize()]);
        break;
      case StreakPrizeTypeEnum.NFT:
        this.formFieldConfig[StreakPrizeTypeEnum.NFT].forEach(control => {
          this.streakPrizeFormGroup.addControl(
            control,
            this.formBuilder.control('', [Validators.required]))
        });
        this.streakPrizeFormGroup.addValidators([this.remainingCount.validatePrize()])
        break;
      case StreakPrizeTypeEnum.POINTS:
        this.formFieldConfig[StreakPrizeTypeEnum.POINTS].forEach(control => {
          this.streakPrizeFormGroup.addControl(
            control,
            this.formBuilder.control(this.prize.points, [Validators.required]));
        });
        break;
    }
    if (this.isUnsavedPrize){
      switch (this.prize.prizeType){
        case StreakPrizeTypeEnum.NFT:
          if (this.prize.selected && !this.prize.prizeId) this.streakPrizeFormGroup.markAllAsTouched();
          break;
        case StreakPrizeTypeEnum.SFT:
          if (this.prize.selected && (!this.prize.prizeId || !this.prize.reservedBalance)) {
            // this.streakPrizeFormGroup.get('reservedBalance').markAsTouched();
            // this.streakPrizeFormGroup.get('nft').markAsTouched();
            this.streakPrizeFormGroup.markAllAsTouched();
          }
          break
        case StreakPrizeTypeEnum.POINTS:
          if (this.prize.selected && !this.prize.points) this.streakPrizeFormGroup.markAllAsTouched();
          break
      }
    }
  }

  /**
   * Turn on(activate)/turn of(deactivate) prize
   * If this is deactivate, set selected nft or sft to null, remove control for deactivated prize
   * set prize type to default(nft), set valid as true
   * If this prize was already saved send request to be to deactivate this prize
   * If this is activate build form, set selected type
   * In all cases set collapse state(collapse if inactive, uncolapse if active) and set change event
   */
  onTooglePrize() {
    if (this.prize.selected) {
      this.selectedNftOption = null;
      this.selectedSftOption = null;
      this.formFieldConfig[this.prize.prizeType].forEach(control => {
        this.streakPrizeFormGroup.removeControl(control);
      });
      this.prize.prizeType = StreakPrizeTypeEnum.NFT
      this.prize.isValid = true;
    }
    if (this.prize.id && this.prize.selected && (this.prize.points || this.prize.prizeId)) {
      const body = cloneDeep(this.prize)
      body.points = null;
      body.prizeId = null;
      delete body.status;
      delete body.id;
      delete body.day;
      delete body.prizeAddress;
      delete body.prizeAmount;
      delete body.prizeImageUrl;
      delete body.prizeTokenId;
      delete body.selected;
      delete body.isCollapse;
      delete body.prizeType;
    }
    this.prize.isCollapse = this.prize.selected;

    if (!this.prize.prizeType) {
      this.prize.prizeType = StreakPrizeTypeEnum.NFT;
    }
    if (!this.prize.selected ) {
      this.selectedType = this.prize.prizeType;
      this.buildFormByType(this.prize.prizeType);
      this.streakPrizeFormGroup.get('type').patchValue(this.selectedType);
    }
    this.prize.selected = !this.prize.selected;
    this.changedPrize.emit(this.prize);
  }

  /**
   * Collapse, uncollapse prize
   */
  toggleExpand(state, prize) {
    if (prize.disabled) return;

    if (prize.isCollapse && !prize.prizeType) {
      prize.type = StreakPrizeTypeEnum.NFT;
    }
    if (!prize.isCollapse && !prize.points && !prize.prizeId) prize.selected = false;
    this.prize.isCollapse = state;
    this.changedPrize.emit(this.prize);
  }

  /**
   * Process change prize type, rebuild form, set selected prize as null
   */
  onChangeType(e, prize) {
    this.buildFormByType(e.value);
    this.selectedSftOption = null;
    this.selectedNftOption = null;
    this.selectedType = e.value;
    prize.prizeType = e.value;
  }

  /**
   * Build form by prize type
   * @param newType new type of prize
   */
  buildFormByType(newType) {
    this.streakPrizeFormGroup.clearValidators();
    if (this.selectedType) {
      this.formFieldConfig[this.selectedType].forEach(control => {
        this.streakPrizeFormGroup.removeControl(control);
      });
    }

    this.formFieldConfig[newType].forEach(control => {
      const validator = [Validators.required];
      this.streakPrizeFormGroup.addControl(control, this.formBuilder.control(''));
      if (control === 'reservedBalance') {
        validator.push(Validators.min(1));
      }
      this.streakPrizeFormGroup.get(control).addValidators(validator);
    });
    if (newType === 'SFT') {
      this.streakPrizeFormGroup.addValidators([this.remainingCount.validatePrize()]);
    }
    if (this.isUnsavedPrize){
      switch (this.prize.prizeType){
        case StreakPrizeTypeEnum.NFT:
          if (this.prize.selected && !this.prize.prizeId) this.streakPrizeFormGroup.markAllAsTouched();
          break;
        case StreakPrizeTypeEnum.SFT:
          if (this.prize.selected && (!this.prize.prizeId || !this.prize.reservedBalance)) {
            this.streakPrizeFormGroup.get('reservedBalance').markAsTouched();
            this.streakPrizeFormGroup.get('nft').markAsTouched();
            this.streakPrizeFormGroup.markAllAsTouched();
          }
          break
        case StreakPrizeTypeEnum.POINTS:
          if (this.prize.selected && !this.prize.points) this.streakPrizeFormGroup.markAllAsTouched();
          break
      }
    }
  }

  /**
   * Clear selected value of nft or sft prize
   * @param name name if form prize type (sft or nft)
   */
  clearPrize(name) {
    if (name === StreakPrizeTypeEnum.SFT) {
      this.selectedSftOption = null;
    } else {
      this.selectedNftOption = null;
    }
    this.streakPrizeFormGroup.get('nft').patchValue(null);
  }

  getOptionText(option) {
    return option?.name;
  }

  openPannel() {
    var iDiv = document.createElement('div');
    iDiv.className = 'cdk-overlay-backdrop cdk-overlay-transparent-backdrop cdk-overlay-backdrop-showing';
    iDiv.id = 'additional-back';
    const element = document.getElementsByClassName('cdk-overlay-container');
    element[0].insertBefore(iDiv, element[0].childNodes[2]);
    element[0].classList.add('event');
  }

  closePannel() {
    const element = document.getElementsByClassName('cdk-overlay-container');
    const elementToDelete = document.getElementById('additional-back');
    elementToDelete.parentNode.removeChild(elementToDelete);
    element[0].classList.remove('event');
  }

  /**
   * Open add new prize popup
   */
  addNewPrize() {
    this.dialog
      .open(CreatePrizeComponent, {
        maxHeight: '860px',
        width: '660px',
        height: '90%',
        panelClass: ['prize-builder'],
      })
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.streakPrizeFormGroup.get(fieldName).touched && !this.isUnsavedPrize) return false;
    return this.streakPrizeFormGroup.get(fieldName).hasError(errorName);
  }

  /**
   * Track is current prize is not saved
   */
  isSavedPrize() {
    if (!this.savedPrizes || !this.savedPrizes.length) return true;
    if (this.savedPrizes.some(id => this.selectedSftOption?.id === id)) {
      return this.streakPrizeFormGroup.get('reservedBalance').value >
        // @ts-ignore
        this.prize.prize.reservedBalance + this.prize.prize.availableCount
    } else {
      return true;
    }
  }


  /**
   * Filter nftPrizeList
   * If prize of current day is in used array dont delete it, else delete it from nftPrizeList
   * If in usedPrizeList no one equal to current prize from nftPrizeList filter it based on availability logic
   * Availability logic check if prize has enought availableCount or prize this is prize of the current day
   * @param prize current prize from nftPrizeList filtering loop
   * @param usedPrizeList prize id which was used but not saved in current game
   */
  prizeFiltering(prize, usedPrizeList) {
    const currentPrize = usedPrizeList.find(id => this.prize.prizeId === id);
    if (currentPrize) return true;
    if (usedPrizeList.some(id => id === prize.id)) return false;
    return prize.availableCount > 0 || prize.id === this.prize.prizeId;
  }
}
