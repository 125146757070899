import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AnswerService} from 'src/app/core/services/answer.service';
import {APP_DATA} from 'src/app/general.app.config';
import {AnswerItem} from "../../models/QuestionDetailsModel";
import {SportEventItem, SubmitAnswer} from "../../models/QuestionResultDetailsModel";
import {QuestionTypes} from "../../Enums/QuestionTypesEnum";
import {takeUntil} from 'rxjs';
import {SnackBarService} from "../../../core/services/snack-bar.service";

@Component({
  selector: 'right-answer',
  templateUrl: './right-answer.component.html',
  styleUrls: ['./right-answer.component.scss']
})
export class RightAnswerComponent implements OnInit {
  answerFormGroup: FormGroup;

  appData = APP_DATA;

  questionType: string;

  selectedItem: AnswerItem;

  answerList: AnswerItem[];

  sportEvent: SportEventItem;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RightAnswerComponent>,
    private answerService: AnswerService,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.answerList = this.data.question.answers;
    this.questionType = this.getFormType();
    this.sportEvent = this.data.sportEvent;
    this.createFormField();
    if (this.data.question.correctAnswer) {
      this.fillForm();
    }
  }

  createFormField() {
    if (this.questionType === 'double input') {
      this.answerFormGroup = new FormGroup({
        homeTeam: new FormControl('', [Validators.required]),
        awayTeam: new FormControl('', [Validators.required]),
      });
    } else {
      this.answerFormGroup = new FormGroup({
        answer: new FormControl('', [Validators.required]),
      });
    }

  }

  submitRightAnswers() {
    if (this.answerFormGroup.valid) {
      const answer = new SubmitAnswer;
      answer.questionId = this.data.question.id;

      switch (this.data.type) {
        case QuestionTypes.SCORE.label:
          answer.score = `${this.answerFormGroup.value.homeTeam}:${this.answerFormGroup.value.awayTeam}`
          break;
        case QuestionTypes.RANGE.label:
          answer.value = this.answerFormGroup.value.answer;
          break;
        case QuestionTypes.LIST.label:
        case QuestionTypes.OPTIONS.label:
        case QuestionTypes.LISTS.label:
          answer.answerId = this.answerFormGroup.value.answer.id;
          break;
      }
      this.answerService.submitCorrectAnswer(answer)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(val => {
          },
          error => {
            let massage = error.error.message;
            if (massage.includes(['[questionId]'])) {
              massage = massage.replace('[questionId]', 'questionId: ' + this.data.question.id);
            }
            this.snackBarService.showSnackBar(massage, true);
          });

      this.dialogRef.close(true);
    }
  }

  fillForm() {
    switch (this.data.type) {
      case QuestionTypes.SCORE.label:
        const answer = this.data.question.correctAnswer.score.split(':');
        this.answerFormGroup.get('homeTeam').patchValue(answer[0])
        this.answerFormGroup.get('awayTeam').patchValue(answer[1])
        break;
      case QuestionTypes.RANGE.label:
        this.answerFormGroup.get('answer').patchValue(this.data.question.correctAnswer.value)
        break;
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
      case QuestionTypes.LISTS.label:
        const question = this.data.question.answers
          .find(a => a.id === this.data.question.correctAnswer.answerId);
        this.selectedItem = question;
        this.answerFormGroup.get('answer').patchValue(question);

        break;
    }
    // if (this.questionType ===  'double input') {
    //   this.answerFormGroup = new FormGroup({
    //     homeTeam: new FormControl('', [Validators.required]),
    //     awayTeam: new FormControl('', [Validators.required]),
    //   });
    // } else {
    //   this.answerFormGroup.get('answer').patchValue('this.data')
    // }
  }

  getFormType(): string {
    switch (this.data.type) {
      case QuestionTypes.SCORE.label:
        return 'double input';
      case QuestionTypes.RANGE.label:
        return 'input';
      case QuestionTypes.LIST.label:
      case QuestionTypes.OPTIONS.label:
      case QuestionTypes.LISTS.label:
        return 'select';
    }
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.answerFormGroup.get(fieldName).touched) return false;
    return this.answerFormGroup.get(fieldName).hasError(errorName);
  }

}
