import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {filter, Subject, takeUntil} from 'rxjs';
import {PrizeService} from 'src/app/core/services/prize.service';
import {ProfileService} from 'src/app/core/services/profile.service';
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {ethers} from 'ethers';
import {NFTAbi, SFTAbi} from "../../blockchain/abi";
import {PersonalPrizeDitailsModel} from "../../models/PersonalPrizeDitailsModel";
import {APP_DATA} from "../../../general.app.config";


@Component({
  selector: 'create-prize',
  templateUrl: './create-prize.component.html',
  styleUrls: ['./create-prize.component.scss']
})
export class CreatePrizeComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  currentTab = 'Transfer';

  personalWalletAddress: string;

  walletAddress: string;

  vendorWalletFormGroup: FormGroup;

  transferFormGroup: FormGroup;

  selectedPrizes: PersonalPrizeDitailsModel[];

  /**
   * Indicator to show loader
   */
  isLoaded = true;

  tabs = {transfer: 'Transfer', import: 'Import'};

  buttonName = this.tabs.transfer;

  showInput = true;

  appData = APP_DATA;

  source: string;

  uploadedPrize: PersonalPrizeDitailsModel;

  constructor(
    public dialogRef: MatDialogRef<CreatePrizeComponent>,
    private snackBarService: SnackBarService,
    private profileService: ProfileService,
    private prizeService: PrizeService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.profileService.currentUser$
      .pipe(
        filter(user => !!user),
        takeUntil(this.unsubscribe$))
      .subscribe((user) => {
          const {personalWalletAddress, walletAddress} = user;
          this.personalWalletAddress = personalWalletAddress;
          this.walletAddress = walletAddress;

          this.vendorWalletFormGroup = new FormGroup({
            walletAddress: new FormControl(this.personalWalletAddress),
          });
          this.transferFormGroup = new FormGroup({
              openseaURL: new FormControl(null,
                [Validators.pattern(/^https:\/\/(testnets\.opensea|opensea)\.io\/assets\/(mumbai|matic)\/(0[xX][0-9a-fA-F]{40})\/([0-9]+)$/)]),
              NFTContractAddress: new FormControl(null, [Validators.pattern(/^0[xX][0-9a-fA-F]{40}$/)]),
              tokenId: new FormControl(null, [Validators.pattern(/^[0-9]*$/)]),
            },
            {validators: [this.comparisonValidator]}
          );
        },

        error => {
          this.snackBarService.showSnackBar(error.error.message, true);
        });
  }

  comparisonValidator(group: FormGroup): any {
    if (group.get('NFTContractAddress').value && !group.get('tokenId').value) {
      return {'required': true};
    }
    if (!group.get('NFTContractAddress').value && group.get('tokenId').value) {
      return {'required': true};
    }
    return null;
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }


  /**
   * Using for transfer prizes from wallet
   */
  onTransferPrize() {
    // @ts-ignore
    if (!window.ethereum) {
      window.open("https://metamask.app.link/dapp/playhunch.io/");
      this.snackBarService.showSnackBar('Please, install Metamask', true);
      return;
    }
    // @ts-ignore
    if (!window.ethereum.selectedAddress) {
      this.snackBarService.showSnackBar('Please, connect Metamask account', true);
      return;
    }

    const iterationLength = this.selectedPrizes.length

    const transfer = async () => {
      this.isLoaded = false;
      for await (const [index, prize] of this.selectedPrizes.entries()) {
        const {contractAddress, tokenId, totalAmount, type, name} = prize;

        const provider = new ethers.providers.Web3Provider((window as any).ethereum);
        provider.getSigner().getAddress().catch(err => {
          this.snackBarService.showSnackBar('Please, sign in to the Metamask', true);
          this.isLoaded = true;
        })
        const signer = provider.getSigner();

        const address = await signer.getAddress();

        if (address.toLowerCase() !== this.personalWalletAddress.toLowerCase()) {
          this.snackBarService.showSnackBar('Please, select connected to Hunch MetaMask account', true);
          this.isLoaded = true;
          return;
        }

        const hunchWallet = this.walletAddress;

        let abi: any = NFTAbi;
        let methodName = 'safeTransferFrom(address,address,uint256)';
        const args = [address, hunchWallet, tokenId];
        if (type === 'SFT') {
          abi = SFTAbi;
          methodName = 'safeTransferFrom(address,address,uint256,uint256,bytes)';
          const SFTAmount = totalAmount;
          args.push(SFTAmount, 0);
        }

        const contract = new ethers.Contract(contractAddress, abi, signer);
        let failed = false;

        contract.safeTransferFrom
        const tx = await contract[methodName](...args)
          .catch(() => {
            if (index === iterationLength - 1) {
              this.isLoaded = true;
            }
          failed = true;
        });
        if (failed) {
          continue;
        }

        const res = await tx.wait();
        this.prizeService.transferPrize({contractAddress, tokenId, totalAmount})
          .subscribe(val => {
              this.snackBarService.showSnackBar(`The prize ${name} transferred successfully`);
              this.prizeService.needUpdatePrizeList();
              if (index === this.selectedPrizes.length - 1 || this.selectedPrizes.length === 1) {
                this.isLoaded = true;
                this.selectedPrizes = [];
              }
            },
            error => {
              this.snackBarService.showSnackBar(error.error.message, true);
              this.isLoaded = true;
            });
      }
    }
    transfer();
  }

  /**
   * Using for import prizes for some services
   */
  onImportPrize() {
    // @ts-ignore
    if (!window.ethereum) {
      window.open("https://metamask.app.link/dapp/playhunch.io/");
      this.snackBarService.showSnackBar('Please, install Metamask', true);
      return;
    }

    const importPrize = async () => {
      this.isLoaded = false;
      const {totalAmount, type, contractAddress, tokenId} = this.uploadedPrize;

      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      provider.getSigner().getAddress().catch(err => {
        this.snackBarService.showSnackBar('Please, sign in to the Metamask', true);
        this.isLoaded = true;
      });

      const signer = provider.getSigner();

      const address = await signer.getAddress();

      const hunchWallet = this.walletAddress;

      let abi: any = NFTAbi;
      let methodName = 'safeTransferFrom(address,address,uint256)';
      const args = [address, hunchWallet, tokenId];
      if (type === 'SFT') {
        abi = SFTAbi;
        methodName = 'safeTransferFrom(address,address,uint256,uint256,bytes)';
        const SFTAmount = totalAmount;
        args.push(SFTAmount, 0);
      }

      const contract = new ethers.Contract(contractAddress, abi, signer);

      const tx = await contract[methodName](...args);
      const res = await tx.wait();
      this.prizeService.transferPrize({contractAddress, tokenId, totalAmount})
        .subscribe(val => {
            this.snackBarService.showSnackBar(`The prize ${name} transferred successfully`);
            this.prizeService.needUpdatePrizeList();
            this.deleteUploadedImage();
            this.isLoaded = true;
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            this.isLoaded = true;
          });

    }
    importPrize();
  }

  onLinkWallet() {
    // @ts-ignore
    if (window.ethereum) {
      // @ts-ignore
      window.ethereum.request({method: 'eth_requestAccounts'}).then(
        walletToken => {
          this.updateUserMetamaskWalletData(walletToken[0]);
        },
        err => {
          this.snackBarService.showSnackBar(err.message);
        });
    } else {
      window.open("https://metamask.app.link/dapp/playhunch.io/");
    }
  }

  updateUserMetamaskWalletData(token) {
    this.profileService.updateVendorUser({personalWalletAddress: token})
      .subscribe(val => {
        this.profileService.currentUser$.next(val);
      })
  }

  onSelectedPrize(prizes) {
    this.selectedPrizes = prizes;
  }

  unlinkWallet() {
    this.updateUserMetamaskWalletData('');
  }

  onTabChange(event) {
    this.buttonName = event.value;
  }

  uploadImage(fieldName) {
    if (!this.personalWalletAddress) {
      this.snackBarService.showSnackBar('Please, link MetaMask wallet', true);
      return;
    }
    this.isLoaded = false;
    const valueForm = this.transferFormGroup.value;
    let contractAddress;
    let tokenId;
    if (fieldName === 'openseaURL') {
      const regex = /^https:\/\/(testnets\.opensea|opensea)\.io\/assets\/(mumbai|matic)\/(.+)\/(\d+)$/;
      const [, , ,address, id] = valueForm.openseaURL.match(regex);
      contractAddress = address;
      tokenId = id;
    } else {
      contractAddress = valueForm.NFTContractAddress;
      tokenId = valueForm.tokenId
    }

    this.prizeService.getPersonalPrizeInfo(contractAddress, tokenId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(prize => {
          this.uploadedPrize = prize;
          this.source = fieldName;
          this.showInput = false;
          this.isLoaded = true;
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true);
          this.isLoaded = true;
        })
  }

  deleteUploadedImage() {
    this.isLoaded = false;
    this.transferFormGroup.get('openseaURL').patchValue(null);
    this.transferFormGroup.get('NFTContractAddress').patchValue(null);
    this.transferFormGroup.get('tokenId').patchValue(null);
    this.showInput = true;
    this.source = null;
    this.uploadedPrize = null;
    this.isLoaded = true;
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.transferFormGroup.get(fieldName).touched) return false;
    return this.transferFormGroup.get(fieldName).hasError(errorName);
  }

}
