<div class="app-header-container">
  <div class="page-info">
    <div class="chapter-mame body-medium-regular" *ngIf="currentChapter">{{currentChapter}}</div>
    <h2 class="page-mame">{{currentPage}}</h2>
  </div>
  <div class="buttons-container">
    <button *ngIf="!isAdmin" mat-stroked-button color="primary">Help</button>
    <ng-container [ngSwitch]="currentLocation">
      <button mat-raised-button color="primary" *ngSwitchCase="'all-games'" (click)="createGame()">
        Create Game
      </button>
      <button mat-raised-button color="primary" *ngSwitchCase="'prizes'" (click)="addPrize()">
        Add Prize
      </button>
      <button mat-raised-button color="primary" *ngSwitchCase="'leaderboard-prizes'" (click)="addReward()">
        New Prize
      </button>
      <button mat-raised-button color="primary" *ngSwitchCase="'video-library'" (click)="addVideo()">
        Add Video
      </button>
      <button mat-raised-button color="primary" *ngSwitchCase="'vendors'" (click)="addNewVendor()">
        New Vendor
      </button>
    </ng-container>


  </div>
</div>
