import {StreakDayItemModel} from "./StreakDayItemModel";
import {SingleRoundItemDetailsModel} from "./SingleRoundItemDetailsModel";
import {StreakPrizeDetailsModel} from "./StreakPrizeDetailsModel";

export class CreateRoundItemDetailsModel {
  round: SingleRoundItemDetailsModel
  questionsIds: number[]
  streakDays: StreakPrizeDetailsModel[]
  publish: boolean
}
