import {HttpClient} from '@angular/common/http';
import {SaasSettingsService} from "./core/services/saas-settings.service";
import {AuthService} from "./core/services/auth.service";
import {LocalStorageService} from "./core/services/local-storage.service";


/**
 * Inject services, and before all app will load call function to get application config
 * Set up role of current user, becouse config need only for vendor
 */
export function initializeAppFactory( saasSetingsService: SaasSettingsService, http: HttpClient, authService: AuthService, localStorageService: LocalStorageService) {
  return () => {
    authService.setCurrentRole(localStorageService.getItem('role'));
    return saasSetingsService.loadConfig()
  };
}
