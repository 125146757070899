<button
  mat-button
  color="primary"
  [disabled]="disabled"
  [ngClass]="getClass()"
  >
  <mat-icon class="material-icons material-icons-outlined" *ngIf="isBeforeIconPosition">{{iconName}}</mat-icon>
  <ng-content></ng-content>
  <mat-icon class="material-icons material-icons-outlined" *ngIf="!isBeforeIconPosition">{{iconName}}</mat-icon>
</button>

