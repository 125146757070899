<div class="body-small-medium input-label">
  {{title}}{{isRequired ? '*' : ''}}
  <i
    *ngIf="tooltipText"
    class="fa-solid fa-circle-info"
    [matTooltipPosition]="TooltipPositionEnum.right"
    #tooltip="matTooltip"
    [matTooltip]="tooltipText"
    [matTooltipClass]="TooltipPositionEnum.right"></i>
</div>

<div class="dropdiv" [class.processing]="processing"
     *ngIf="!(isDisabled && uploadedFiles)"
     [ngClass]="{disabled: isDisabled, 'with-error': isImageTooBig || isMandatoryError}"
     (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
  <label class="custom-file-upload">
    <input (change)="onChange($event)" type="file" onclick="this.value=null" [disabled]="isDisabled"/>
    <img src="assets/images/icons/upload.svg">
    <div class="drag-text">
      Drag and drop or browse to choose a file
    </div>
  </label>
  <div *ngIf="isLoading" class="disabled-overlay">
    <mat-spinner [diameter]="30" color="primary"></mat-spinner>
  </div>
</div>
<mat-error *ngIf="isImageTooBig">
  <i class="fas fa-exclamation-circle"></i>
  <span>{{appData.BIG_IMAGE}}</span>
</mat-error>
<mat-error *ngIf="isMandatoryError">
  <i class="fas fa-exclamation-circle"></i>
  <span>{{appData.MANDATORY_IMAGE}}</span>
</mat-error>

<ng-container *ngIf="uploadedFiles">
  <div class="image-uploaded-line">
    <div class="image-info-container">
      <div class="image-info">
        <image-with-loader
          [overlayHeight]="'24px'"
          [spinnerSize]="20"
          [imageSrc]="uploadedFiles.url" class="cell-image">
        </image-with-loader>
        <div class="image-size">{{imageSize + imageSizeSymbol}}</div>
      </div>
      <div class="image-control">
        <mat-icon *ngIf="!isDisabled && !isDisabledDelete" (click)="deleteImage()" class="material-icons material-icons-outlined">clear</mat-icon>
      </div>
    </div>
  </div>
</ng-container>

