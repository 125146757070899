<div class="question-details-container">
  <div class="question-details-header body-big-bold">QUESTIONS</div>
  <div class="question-grid">
    <div *ngFor="let question of questions; let i = index" class="question-container">
      <single-text-streak-question
        [index]="i"
        [_question]="question"
        [_isUnsavedQuestion]="isUnsavedQuestion"
        [questions]="questions"
        [roundId]="editableRound?.id"
        (isValidQuestion)="handleValidQuestion($event, i)"
        (moveQuestion)="handleMoveQuestion($event, i)"
        (changedQuestion)="onChangedQuestion($event, i)">
      </single-text-streak-question>
    </div>
  </div>
</div>
