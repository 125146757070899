<div class="snack-bar">
  <div class="massages">
    <div *ngFor="let err of data">
      {{err}}
    </div>
  </div>
  <button type="button" mat-flat-button color="primary" (click)="dismissSnackbar();">Ok</button>
</div>


