import {StreakPrizeTypeEnum} from "../Enums/StreakPrizeTypeEnum";
import {StreakPrizeDayStatus} from "../Enums/StreakPrizeDayStatus";
import {PrizeDetailsModel} from "./PrizeDetailsModel";

export class StreakPrizeDetailsModel {
  day: number
  questionOneText: string
  questionTwoText: string
  prizeType: StreakPrizeTypeEnum
  prizeId: number
  reservedBalance: number
  points: number
  selected?: boolean
  isCollapse?:boolean
  id?: number
  disabled: boolean;
  status:StreakPrizeDayStatus;
  isValid?: boolean
  prize?:PrizeDetailsModel;
}
