import { AbstractControl } from "@angular/forms";
import { HerosRewardModel } from "../../models/HerosRewardModel";
import { PrizeDetailsModel } from "../../models/PrizeDetailsModel";

export const prizeValidator = (revard?: HerosRewardModel) => (
  (control: AbstractControl) => {
    if (revard) {
      if (
        revard.prizeId != control.get('prizeId')?.value ||
        revard.fromPosition != control.get('fromPosition')?.value ||
        revard.toPosition != control.get('toPosition')?.value
      ) {
        return checkPosition(control);
      }
    } else {
      return checkPosition(control);
    }
  }
);

const checkPosition = (control: AbstractControl) => {
  const fromPosition = Number(control.get('fromPosition')?.value);
  const toPosition = Number(control.get('toPosition')?.value);
  const prize: PrizeDetailsModel = control.get('prize').value;
  if (
    fromPosition >= 0 && 
    toPosition >= 0 && 
    prize && 
    ((toPosition - fromPosition + 1) > prize.availableCount)
  ) {
    return {prizeErorr: true};
  }
}