import { NgModule } from '@angular/core';
import {DatesFormatPipePipe} from "./dates.pipe";
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    DatesFormatPipePipe,
    FilterPipe
  ],
    exports: [
        DatesFormatPipePipe,
        FilterPipe
    ],
  imports: [
  ]
})
export class PipesModule { }
