import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {LocalStorageService} from "./local-storage.service";
import {Router} from "@angular/router";
import {APP_ROUTES} from "../../app.routes.config";
import {Observable, of} from "rxjs";
import * as Sentry from "@sentry/angular";
import { UserRole } from 'src/app/common/Enums/UserRoleEnum';
import {SnackBarService} from "./snack-bar.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }

  currentRole: UserRole;

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
    private localStorageService: LocalStorageService,
    private router: Router,
    private snackBarService: SnackBarService,
  ) { }

  logOut() {
    this.http.delete(this.uriConfig.logout, this.httpOptions)
      .subscribe(()=> {
        this.logoutSentry();
        this.localStorageService.logOut();
        this.router.navigate(['/' + APP_ROUTES.AUTH + '/login' ]);
      },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true);
        });
  }

  refreshToken (token) {
    return this.http.post<any>(this.uriConfig.refresh, {}, token);
  }

  getCurrentUser(): Observable<any> {
    return of(this.localStorageService.getToken());
  }

  logoutSentry() {
    // Sentry.configureScope(scope => scope.setUser(null));
  }

  login(user: any): Observable<{accessToken: string, refreshToken: string }> {
    return this.http.post<any>(this.uriConfig.login, user, this.httpOptions);
  }

  createVendor(body) {
    return this.http.post<any>(this.uriConfig.signUp, body, this.httpOptions);
  }

  validateRestoreToken(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.validateResetToken, body)
  }

  resetPassword(body): Observable<any> {
    return  this.http.post<string>(this.uriConfig.resetPassword, body)
  }

  restorePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.restorePassword, body)
  }

  changePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.changePassword, body)
  }

  setSentryUser(user: SentryUser) {
    const {email} = user;
    Sentry.setUser({email});
  }

  setCurrentRole(role) {
    this.currentRole = role;
  }

  getCurrentRole(): UserRole {
    return this.currentRole;
  }

}
export interface SentryUser {
  email: string;
  role: string;
}
