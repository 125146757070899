import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {Subject, takeUntil, throwError} from "rxjs";
import moment from "moment";
import {RoundTypesEnum} from "../../common/Enums/RoundTypesEnum";
import {
  CreateRoundMainDetailsFormInterface,
} from "../../common/FormsModels/create-round-main-details-form-interface";
import {APP_DATA} from "../../general.app.config";
import {EventsService} from "../../core/services/events.service";
import {roundTypes} from "../../common/models/RoundTypeModel";
import {RoundTypeItem} from "../../common/models/RoundTypeItem";
import {RoundSportEnum} from "../../common/Enums/RoundSportEnum";
import {TooltipPositionEnum} from 'src/app/common/Enums/TooltipPositionEnum';
import {RoundItemDetailsModel} from "../../common/models/RoundItemDetailsModel";
import {IsCloseDateAfterOpen} from "./date-validator";
import {NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import {NgxMatDateAdapter, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {merge} from 'rxjs';
import {cloneDeep} from "lodash";
import {TextService} from "../../core/services/text.service";
import {RoundStatusEnum} from "../../common/Enums/RoundStatusEnum";


export const CUSTOM_MOMENT_FORMATS = {
  parse: {},
  display: {
    dateInput: 'DD MMM YYYY, HH:mm',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.scss'],
  providers: [
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
  ],
})
export class GameDetailsComponent implements OnInit, OnDestroy {
  /**
   * Send changed form value to parent component
   */
  @Output() formValues = new EventEmitter<any>();

  /**
   * Send if dates was changed to parent component
   */
  @Output() isDataChanged = new EventEmitter<boolean>();

  /**
   * Send if sport type was changed to parent component
   */
  @Output() isSportTypeChanged = new EventEmitter<any>();

  /**
   * Send form validity to parent component
   */
  @Output() isValidForm = new EventEmitter<boolean>();

  /**
   * Save flag if round can be edited, calculated on round status
   */
  editableRound: any

  /**
   * Set data if open exist round and wath if this is streak if date was changed
   */
  @Input() set _editableRound(round) {
    this.editableRound = round;
    if (this.editableRound) {
      this.fillForm();

      if (this.editableRound.type.toLowerCase().includes('streak')) {
        this.checkChangeEndDate();
      }
    }
  };

  @Input() isEditingDisable: boolean;

  /**
   * For video streak, save sport type for witch video question was not created
   */
  @Input() excludedSportType: string[];

  /**
   * Flag to save if save button was clicked
   */
  isTryToSave = false

  /**
   * If save button was clicked, need to run validation, marked all field as touched to show error
   */
  @Input() set isSaveButtonClicked(isClicked) {
    if (isClicked) {
      this.isTryToSave = true;
    }
    if (isClicked && this.newRoundDetailsForm && !this.newRoundDetailsForm.valid) {
      this.newRoundDetailsForm.markAllAsTouched();
    }
  }

  allRoundTypesList = roundTypes;

  TooltipPositionEnum = TooltipPositionEnum;

  isOtherRound = false;

  isPrediction = true;

  newRoundDetailsForm: FormGroup<CreateRoundMainDetailsFormInterface>;

  public unsubscribe$: Subject<void> = new Subject();

  /**
   * All general app texts
   */
  appData = APP_DATA;

  selectedCategory: string;

  /**
   * Indicator to show loader
   */
  isLoaded = true;

  pageSize = 10;

  /**
   * Store page number( change on pagination)
   */
  pageNumber = 1;

  /**
   * Store column name where sorting is actieve
   */
  sortField = 'name';

  /**
   * Store sort direction
   */
  sortDirection = 'asc';

  eventsList: any[];

  /**
   * Contain general round type: Prediction or streak
   */
  generalTypeList: RoundTypeItem[];

  /**
   * Contain specific round type fo prediction: sport or other
   */
  oneRoundSportTypeList: RoundTypeItem[];

  sportTypes = Object.keys(RoundSportEnum);

  /**
   * Selected general round type
   */
  generalRoundType: RoundTypeItem;

  /**
   * Selected specific round type
   */
  oneRoundSportType: RoundTypeItem;

  RoundTypesEnum = RoundTypesEnum;

  tooltipsTexts = {
    name: 'The title of the game displayed to users',
    description: 'Text describing the round, how to play and what can be won',
    openDate: 'The time and date the round will appear open for entries',
    closeDate: 'The time and date the round will close for entries',
    type: 'If the round content is related to sport/politics or other',
    backgroundImage: 'We recommend using a 1000px x 262px image resolution (minimum). Max image size is 4MB',
    sport: 'Select one option that best categorizes the content of the round'
  }

  constructor(
    private fb: NonNullableFormBuilder,
    private eventsService: EventsService,
    private isCloseDateAfterOpen: IsCloseDateAfterOpen,
    private textService: TextService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.generalTypeList = this.allRoundTypesList.filter(type => type.beVariable !== RoundTypesEnum.Other);
    this.oneRoundSportTypeList = this.allRoundTypesList.filter(type => !type.beVariable.toLowerCase().includes('streak'));
    this.generalRoundType = this.generalTypeList[0];
    this.oneRoundSportType = this.oneRoundSportTypeList[0];
    this.buildForm();
    merge(
      this.newRoundDetailsForm.get('openDate').valueChanges,
      this.newRoundDetailsForm.get('type').valueChanges,
      this.newRoundDetailsForm.get('closeDate').valueChanges)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => this.isDataChanged.emit(true))

    this.newRoundDetailsForm.get('sport').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => this.isSportTypeChanged.emit(val))
    this.newRoundDetailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(formValue => {
        const {type, openDate, closeDate} = formValue;

        if (((type && type.toLowerCase().includes('streak')) || (this.editableRound && this.editableRound.type.toLowerCase().includes('streak'))) && openDate && closeDate) {

          let startDate = cloneDeep(openDate);
          let endDate = cloneDeep(closeDate);
          if (typeof startDate === 'string') {
            //@ts-ignore
            startDate = moment(moment(startDate).utcOffset(0).format());
          } else {
            startDate = moment(moment(startDate).utcOffset(0).format());
          }
          if (typeof endDate === 'string') {
            //@ts-ignore
            endDate = moment(moment(endDate).utcOffset(0).format());
          }
          const hour = startDate.hours();
          const minute = startDate.minutes();
          const endHour = endDate.hours();
          const endMinute = endDate.minutes();
          if (hour !== endHour || minute !== endMinute) {
            endDate.set('hour', hour);
            endDate.set('minute', minute);
            const y = endDate.toISOString()
            this.newRoundDetailsForm.get('closeDate').patchValue(y);
            return;
          }
        }

        this.dateErrorChecker();
        this.formValues.emit(formValue);
        this.isValidForm.emit(this.newRoundDetailsForm.valid);
      })
  }

  checkChangeEndDate () {
    // this.newRoundDetailsForm.get('closeDate').valueChanges
    //   .subscribe(response => {
    //     debugger
    //     // const formattedDate = new Date(response);
    //     // const offset = formattedDate.getTimezoneOffset() / 60;
    //
    //     const savedCloseDate = moment(response);
    //     const savedTime = savedCloseDate.hours();
    //     const savedMinute = savedCloseDate.minutes();
    //     // @ts-ignore
    //     const currentHours = response.hours();
    //     // @ts-ignore
    //     const currentMinutes = response.minutes();
    //     if (savedTime !== currentHours || savedMinute !== currentMinutes) {
    //       savedCloseDate.hours(savedTime)
    //       // @ts-ignore
    //       this.newRoundDetailsForm.get('closeDate').patchValue(savedCloseDate);
    //     }
    //   });
  }

  buildForm() {
    // @ts-ignore
    this.newRoundDetailsForm = this.fb.group({
        name: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(52),
          (control) => {
            const includeDoubleSpace = /\s{2}/.test(control.value)
            if (includeDoubleSpace) {
              return {
                doubleSpace: {value: control.value}
              }
            } else {
              return null
            }
          }]),
        description: new FormControl('', [Validators.minLength(2), Validators.maxLength(255), Validators.required]),
        openDate: new FormControl('',
          [Validators.required,
            (control) => {
              const dateNow = Date.now();

              // if (this.editedRoundData && this.roundType === GameTypesEnum.Streak) return null;
              if (moment(dateNow).isBefore(control.value)) {
                return null;
              } else {
                return {
                  invalidOpenDate: {value: control.value}
                }
              }
            }]),
        closeDate: new FormControl('', [Validators.required,
          (control) => {
            const dateNow = Date.now();

            // if (this.editedRoundData && this.roundType === GameTypesEnum.Streak) return null;

            if (moment(dateNow).isBefore(control.value)) {
              return null;
            } else {
              return {
                invalidCloseDate: {value: control.value}
              }
            }
          }
        ]),
        sport: new FormControl(null, this.isOtherRound ? [] : [Validators.required]),
        type: new FormControl(RoundTypesEnum.Sport, [Validators.required]),
        backgroundImageDesktopId: new FormControl(null),
        backgroundImageMobileId: new FormControl(null),
      },
      {
        validators: [this.isCloseDateAfterOpen.validateDates()],
      }
    );
  }
  /**
   * Fill game details form with data from BE, disabled field with sport types,
   * and disable all fields if round is not editable.
   * Remove validation for text streak for sport type
   */
  fillForm() {
    this.newRoundDetailsForm.get('name').patchValue(this.editableRound.name);
    this.newRoundDetailsForm.get('closeDate').patchValue(this.editableRound.closeDate);
    this.newRoundDetailsForm.get('openDate').patchValue(this.editableRound.openDate);
    this.newRoundDetailsForm.get('description').patchValue(this.editableRound.description);
    this.newRoundDetailsForm.get('type').patchValue(this.editableRound.type);
    this.newRoundDetailsForm.get('backgroundImageDesktopId').patchValue(this.editableRound.backgroundImageDesktopId);
    this.newRoundDetailsForm.get('backgroundImageMobileId').patchValue(this.editableRound.backgroundImageMobileId);

    if (this.isEditingDisable || (!(this.editableRound.status === RoundStatusEnum.PENDING || this.editableRound.status === RoundStatusEnum.DRAFT))) {
      this.newRoundDetailsForm.disable();
    }
    if (!!this.editableRound) {
      this.newRoundDetailsForm.get('type').disable();
    }
    this.isOtherRound = this.editableRound.type === RoundTypesEnum.Other;
    this.isPrediction = !this.editableRound.type.toLowerCase().includes('streak');
    if (!this.isOtherRound) {
      if (this.isPrediction) {
          const sportIndex = this.sportTypes.findIndex(type => type.toLowerCase() === this.editableRound.sport[0].toLowerCase());
          // @ts-ignore
          this.newRoundDetailsForm.get('sport').patchValue(this.sportTypes[sportIndex]);


      } else {
        // @ts-ignore
        const sports = this.editableRound.sport.map(sport => this.textService.capitalizeFirstLetter(sport));
        // @ts-ignore
        this.newRoundDetailsForm.get('sport').patchValue(sports);
      }

    } else {
      this.generalTypeList = this.allRoundTypesList.filter(type => type.beVariable !== RoundTypesEnum.Sport);
      this.newRoundDetailsForm.get('sport').removeValidators(Validators.required);
      // @ts-ignore
      this.newRoundDetailsForm.get('sport').patchValue('');
    }
    if (this.editableRound.type === RoundTypesEnum.Text_streak) {
      this.newRoundDetailsForm.get('sport').removeValidators(Validators.required);
      this.newRoundDetailsForm.get('sport').clearValidators();
      this.newRoundDetailsForm.get('sport').updateValueAndValidity();
    }
    const formValue = this.newRoundDetailsForm.value;
    this.formValues.emit(formValue);
    this.isValidForm.emit(this.newRoundDetailsForm.valid);
  }

  /**
   * Watch round type changes(sport ot other), and remove sport validation if OTHER type selected
   */
  onRoundTypeChange(event) {
    this.isOtherRound = event.value === RoundTypesEnum.Other;
    if (this.isOtherRound) {
      this.newRoundDetailsForm.get('sport').removeValidators(Validators.required);
      this.newRoundDetailsForm.get('sport').clearValidators();
      this.newRoundDetailsForm.get('sport').updateValueAndValidity();
    }
  }

  //TODO need recheck and delete as unused
  getEventsList() {
    this.isLoaded = false;
    const eventListRequest = this.getRequestBody();
    this.eventsService.getEventsList(eventListRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        //TODO fix any type
        (eventListResponse: { total: number, records: any[] }) => {
          this.isLoaded = true;
          this.eventsList = eventListResponse.records;
          this.eventsService.eventListUpdated();
        },
        (error) => {
          this.isLoaded = true;
          throwError(error);
        })
  }

  //TODO need recheck and delete as unused
  getRequestBody() {
    const {pageNumber, pageSize, sortDirection, sortField} = this;
    const upcoming = false;
    return {
      pageNumber,
      pageSize,
      sortDirection,
      sortField,
      upcoming
    }
  }

  /**
   * Watch round type changes(prediction or streak), and control validation for different rounds type
   */
  selectionGeneralTypeChange(event) {
    this.isOtherRound = event.value === RoundTypesEnum.Other;
    this.isPrediction = !event.value.toLowerCase().includes('streak');
    if (event.value === RoundTypesEnum.Text_streak || this.isOtherRound) {
      this.newRoundDetailsForm.get('sport').removeValidators(Validators.required);
      this.newRoundDetailsForm.get('sport').clearValidators();
      this.newRoundDetailsForm.get('sport').updateValueAndValidity();
    } else {
      this.newRoundDetailsForm.get('sport').addValidators(Validators.required);
    }
  }
  /**
   * Save uploaded file ID in form
   */
  uploadFile(event, name) {
    this.newRoundDetailsForm.get(name + 'Id').patchValue(event ? event.id : null);
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.newRoundDetailsForm.get(fieldName).touched) return false;
    return this.newRoundDetailsForm.get(fieldName).hasError(errorName);
  }

  /**
   * Control error on dates form, control close date must be after open date,
   * and open and close date must be after current date
   */
  dateErrorChecker() {
    if (this.newRoundDetailsForm.errors?.datesOrderWrong) {
      this.newRoundDetailsForm.controls['openDate'].setErrors({'incorrect': true});
      this.newRoundDetailsForm.controls['closeDate'].setErrors({'incorrect': true});
      this.newRoundDetailsForm.controls['openDate'].markAsTouched();
      this.newRoundDetailsForm.controls['closeDate'].markAsTouched();
    }

    if (this.newRoundDetailsForm.controls['openDate'].value
      && this.newRoundDetailsForm.controls['closeDate'].value
      && !this.newRoundDetailsForm.errors?.datesOrderWrong
      && !this.newRoundDetailsForm.controls['openDate'].getError('invalidOpenDate')
      && !this.newRoundDetailsForm.controls['closeDate'].getError('invalidCloseDate')
    ) {
      this.newRoundDetailsForm.controls['openDate'].setErrors(null);
      this.newRoundDetailsForm.controls['closeDate'].setErrors(null);
    }
  }
}
