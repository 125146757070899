import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {RoundItemDetailsModel} from "../../common/models/RoundItemDetailsModel";
import {cloneDeep} from "lodash";
import moment from 'moment';
import {TextStreakQuestionDetailsModel} from "../../common/models/TextStreakQuestionDetailsModel";
import {QuestionService} from "../../core/services/question.service";
import {MoveDirectionEnum} from "../../common/Enums/MoveDirectionEnum";
import {QuestionStatusEnum} from "../../common/Enums/QuestionStatusEnum";

@Component({
  selector: 'text-streak-question',
  templateUrl: './text-streak-question.component.html',
  styleUrls: ['./text-streak-question.component.scss']
})
export class TextStreakQuestionComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * Input event if round dates was changed
   */
  @Input() isDataChanged: boolean;

  /**
   * Current round details
   */
  @Input() roundDetails: any;

  /**
   * Details of edited round
   */
  @Input() editableRound: any;

  savedStreakPrizes: any[];

  isUnsavedQuestion = false;

  @Input() set _isUnsavedQuestion(flag) {
    this.isUnsavedQuestion = flag;
  }

  /**
   * Output event for changes question
   */
  @Output() changedQuestions = new EventEmitter<TextStreakQuestionDetailsModel[]>();

  /**
   * Round data
   */
  round: RoundItemDetailsModel;

  /**
   * Numbers of day in round
   */
  daysCount: number;

  savedStreakQuestions: TextStreakQuestionDetailsModel[];

  questions: TextStreakQuestionDetailsModel[];

  /**
   * Ctored correct answers for streak questions
   */
  correctAnswersStreak: any[];

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private questionService: QuestionService
  ) {
  }

  ngOnInit(): void {
    if (this.round?.id) {
      combineLatest(
        [
          this.questionService.getQuestionListForManualResult(this.round.id),
          this.questionService.getStreakQuestionById(this.round.id)
        ]
      )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([corectAnswers, streakPrize]) => {
          // @ts-ignore
          this.savedStreakPrizes = streakPrize.records;
          // @ts-ignore
          this.correctAnswersStreak = corectAnswers;
          this.calculateQuestion();
          this.changedQuestions.emit(this.questions);
        })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isDateDifference = false;
    if (this.editableRound){
      isDateDifference = this.editableRound.openDate !== this.roundDetails.openDate || this.editableRound.closeDate !== this.roundDetails.closeDate
    }
    if (!this.editableRound || isDateDifference) {
      this.round = this.roundDetails;
    } else {
      this.round = this.editableRound;
    }
    if (this.isDataChanged && this.round ) {
      this.calculateQuestion();
    }
    if (this.editableRound && changes.isDataChanged && changes.isDataChanged.currentValue && this.questions){
      this.changedQuestions.emit(this.questions);
    }
  }

  /**
   * Create list of questions for current round base on daysCount
   * property daysCount calculate based on start and end date
   * Firstly create empty question object,
   * and if this is editind round assign saved data to relevant object
   */
  calculateQuestion() {
    let startDate = cloneDeep(this.round.openDate);
    let endDate = cloneDeep(this.round.closeDate);
    if (!startDate || !endDate) return;

    if (typeof startDate === 'string') {
      //@ts-ignore
      startDate = moment(startDate);
    }
    if (typeof endDate === 'string') {
      //@ts-ignore
      endDate = moment(endDate);
    }
    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    this.daysCount = moment(endDate).diff(moment(startDate), 'days');
    const prizes = Array.from(Array(this.daysCount).keys())
      .map(day => {
        const questionDay = new TextStreakQuestionDetailsModel();
        questionDay.status = QuestionStatusEnum.CREATED;
        return questionDay;
      });
    if (this.savedStreakPrizes) {
      this.savedStreakPrizes.forEach((question, index) => {

        if (index <= prizes.length - 1) {
          prizes[index].questionOneText = question.questions[0].text;
          prizes[index].questionOneId = question.questions[0].id;
          if (question.questions[0].correctAnswer) {
            const answer = question.questions[0].correctAnswer === 1 ? 'YES' : 'NO';
            prizes[index].questionOneCorrectAnswer = answer;
          }
          const correctAnswerOne = this.correctAnswersStreak.find(q => q.id === prizes[index].questionOneId)
          if (correctAnswerOne && correctAnswerOne.correctAnswer && !prizes[index].questionOneCorrectAnswer)  {
            const answer = correctAnswerOne.answers.find(a => a.id === correctAnswerOne.correctAnswer.answerId).text
            prizes[index].questionOneCorrectAnswer = answer;
          }
          if (correctAnswerOne && correctAnswerOne.correctAnswer) {
            prizes[index].questionOneNewId = correctAnswerOne.correctAnswer.id;
          }
          question.questions[0].id;
          prizes[index].questionTwoText = question.questions[1].text;
          prizes[index].questionTwoId = question.questions[1].id;
          if (question.questions[1].correctAnswer) {
            const answer = question.questions[1].correctAnswer === 1 ? 'YES' : 'NO';
            prizes[index].questionTwoCorrectAnswer = answer;
          }
          const correctAnswerTwo = this.correctAnswersStreak.find(q => q.id === prizes[index].questionTwoId)
          if (correctAnswerTwo && correctAnswerTwo.correctAnswer && !prizes[index].questionOneCorrectAnswer) {
            const answer = correctAnswerTwo.answers.find(a => a.id === correctAnswerTwo.correctAnswer.answerId).text
            prizes[index].questionTwoCorrectAnswer = answer;
          }
          if (correctAnswerTwo && correctAnswerTwo.correctAnswer) {
            prizes[index].questionTwoNewId = correctAnswerTwo.correctAnswer.id;
          }
          prizes[index].status = question.status;
          prizes[index].id = question.streakDayId;
          prizes[index].isValid = true;
        }
      });
    }
    this.questions = prizes;
  }

  /**
   * On change question text for question by index
   */
  onChangedQuestion(question, index) {
    const {questionOneText, questionTwoText} = question;
    this.questions[index].questionOneText = questionOneText;
    this.questions[index].questionTwoText = questionTwoText;

    this.changedQuestions.emit(this.questions);
  }

  /**
   * Move question UP or down
   * @param direction - up or down direction to move
   * @param currentIndex - current indextt of question in questions array
   */
  handleMoveQuestion(direction: MoveDirectionEnum, currentIndex: number) {
    if (this.questions.length < 2) return;
    if (direction === MoveDirectionEnum.DOWN && currentIndex === this.questions.length - 1) {
      if (this.questions[0].status !== QuestionStatusEnum.CREATED) {
        const index = this.questions.findIndex(q => q.status === QuestionStatusEnum.CREATED)
        this.questions.splice(index, 0, this.questions[currentIndex])
      } else {
        this.questions.unshift(this.questions[currentIndex]);
      }
      this.questions.pop();
      this.changedQuestions.emit(this.questions);

      return;
    }
    if (direction === MoveDirectionEnum.UP && currentIndex === 0) {
      this.questions.push(this.questions[currentIndex]);
      this.questions.shift();
      this.changedQuestions.emit(this.questions);
      return;
    }

    const newIndex = direction === MoveDirectionEnum.DOWN ? currentIndex + 1 : currentIndex - 1;
    this.questions = this.arrayMove(this.questions, currentIndex, newIndex);
    this.changedQuestions.emit(this.questions);
  }

  /**
   * Set property validity to each question
   * Need to show error if question is not valid and not allow to save round
   * @param flag getting from child component based on form valid property
   * @param index index of current prize
   */
  handleValidQuestion(flag, idnex) {
    this.questions[idnex].isValid = flag;
  }

  /**
   * Function to change order in array using for question change order
   */
  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };


}
