import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, catchError, finalize, lastValueFrom, takeUntil, tap, throwError} from "rxjs";
import {CurrentLocationService} from "../../../core/services/current-location.service";
import {TextService} from "../../../core/services/text.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateNewRoundModalComponent} from "../../../create-new-round-modal/create-new-round-modal.component";
import {CreatePrizeComponent} from "../create-prize/create-prize.component";
import {AddVideoComponent} from "../add-video/add-video.component";
import {CreateVendorModalComponent} from "../create-vendor-modal/create-vendor-modal.component";
import { CreateRewardModalComponent } from '../create-reward-modal/create-reward-modal.component';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  isLoadingReward$ = new BehaviorSubject(false)

  @Input() isAdmin: boolean;

  /**
   * Current page name
   */
  currentPage: string;

  /**
   * Current chanter name
   */
  currentChapter: string;

  currentLocation: string

  private unsubscribe$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.currentLocationService.getCurrentChapterName()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(chapterName => {
        this.currentChapter = chapterName.length > 3 ? chapterName : chapterName.toUpperCase();
      })

    this.currentLocationService.getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pagePath => {
        this.currentLocation = pagePath;
        const currentPage = this.textService.createNameWithSpaces(pagePath);

        this.currentPage = this.textService.capitalizeFirstLetterInEachWord(currentPage);
      })
  }

  constructor(
    private currentLocationService: CurrentLocationService,
    private rewardsService: RewardsService,
    public textService: TextService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createGame() {
    this.dialog
      .open(CreateNewRoundModalComponent,{
        maxHeight: '860px',
        height: '90%',
        width: '660px',
        panelClass:['game-builder'],
        disableClose: true,
      })
  }

  addPrize() {
    this.dialog
      .open(CreatePrizeComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
      })
  }

  addVideo() {
    this.dialog
      .open(AddVideoComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
      })
  }

  addNewVendor() {
    this.dialog
      .open(CreateVendorModalComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
      })
  }

  addReward() {
    const dialogRef = this.dialog
      .open(CreateRewardModalComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
        data: {
          isLoading$: this.isLoadingReward$,
          title: 'Add Leaderboard Prize',
        }
      });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.onSave = async (reverd) => {
      this.isLoadingReward$.next(true);
      await lastValueFrom(this.rewardsService.createLeadboardPrize(reverd).pipe(
        tap(data => this.rewardsService.needUpadteLeaderboardPrizes$.next({})),
        catchError(error => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error)
        }),
        tap(() => this.dialog.closeAll()),
        finalize(() => this.isLoadingReward$.next(false))
      ))
    }
    componentInstance.onClose = () => {dialogRef.close()};
  }
}
