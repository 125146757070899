export enum DateFormats {
  DD_MMM = 'dd-mmm',
  MMM_YY = 'Mmm-yy',
  MMM_YYYY = 'Mmm-yyyy',
  MMMM_YY = 'Full month name -yy',
  MMMM_YYYY = 'Full month name yyyy',
  MM_YYYY_slash = 'Mm/yyyy',
  MM_YY_slash = 'Mm/yy',
  MM_YYYY='Mm-yyyy',
  MM_YY='Mm-yy',
  DD_MMMM_YYYY = 'Last day full month name yyyy',
  DD_MMMM = 'Last day ending full month',
  YYYY_MM_DD = 'Yyyy-mm-dd',
  DD_MMMM_YY = 'Dd-mmm-yy',
  DD_MMM_YYYY = 'dd-mmm-yyyy',
  DD_MMMM_YYYY_hh_mmtz = 'Dd mmm yyyy, hh:mmtz',
  MMM_DD_YYYY_at_hh_mmtz = 'Mmm dd yyyy at hh:mmtz'
}
