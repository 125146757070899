import { Component } from '@angular/core';

@Component({
  selector: 'new-prize-modal',
  templateUrl: './new-prize-modal.component.html',
  styleUrls: ['./new-prize-modal.component.scss']
})
export class NewPrizeModalComponent {

}
