import {PrizeTypeEnum} from "../Enums/PrizeTypeEnum";
import {PrizeStatusEnum} from "../Enums/PrizeStatusEnum";

export class PrizeDetailsModel {
  id: number
  name: string
  description: string
  imageUrl: string
  type: PrizeTypeEnum
  totalAmount: number
  availableCount: number
  usedInGamesCount: number
  status: PrizeStatusEnum
}
