import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject, takeUntil} from 'rxjs';
import {APP_DATA} from "../../../general.app.config";
import {RoundService} from "../../../core/services/round.service";
import {TextService} from "../../../core/services/text.service";
import {VideoQuestionModel} from "../../models/VideoQuestionModel";
import {VideoService} from "../../../core/services/video.service";
import {SnackBarService} from "../../../core/services/snack-bar.service";

@Component({
  selector: 'add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit, OnDestroy {
  /**
   * Indicator to show loader
   */
  isLoaded = true;

  videoDataFromGroup: FormGroup;

  appData = APP_DATA;

  /**
   * Video question have only two answer option with fixed ID
   */
  answers = [
    {title: 'Yes', id: 1},
    {title: 'No', id: 2}
  ]

  selectedAnswer = this.answers[0];

  /**
   * List of the available sport types
   */
  sportTypes: string[];

  /**
   * Sport type to which assigned the video
   */
  selectedSportType: string;

  /**
   * Second where video will stop
   */
  stopTimeSeconds = 2;

  /**
   * Miliseconds where video will stop
   */
  stopTimeMilliseconds = 25;

  /**
   * Time user can spend on thinking about answer
   */
  answerTimeSec = 10;

  /**
   * Current slider position in miliseconds
   */
  currentVideoTime: number;

  /**
   * Is video popup contain an error
   */
  showVideoError = false;

  /**
   * Popup title, contain add if this is adding video, contain edit if this is editing video
   */
  title = 'Add Video';


  /**
   * Button title, submit if this is adding video, save edit if this is editing video
   */
  buttonTitle = 'Submit';

  /**
   * Video object
   */
  video: any;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddVideoComponent>,
    private roundService: RoundService,
    public textService: TextService,
    private videoService: VideoService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.isLoaded = false;

    this.videoDataFromGroup = new FormGroup({
      text: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
      title: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
      sportType: new FormControl(null, [Validators.required]),
      url: new FormControl('https://s3.us-east-2.amazonaws.com/videos.hunch/9df6ae6b-1a43-4f55-89b7-5c40f86eb41f.mp4', [Validators.required]),
      answer: new FormControl(this.answers[0], [Validators.required]),
    });

    this.roundService.getSportTypes()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sportTypes) => {
        this.sportTypes = sportTypes;
        this.selectedSportType = this.sportTypes[0];
        this.videoDataFromGroup.get('sportType').patchValue(this.selectedSportType);
        if (this.data) {
          this.title = 'Edit Video';
          this.buttonTitle = 'Save';
          this.fillForm();
          this.isLoaded = true;
        } else {
          this.isLoaded = true;
        }
      })
  }

  /**
   * Fill video form with video data achived from BE when video is edited
   */
  fillForm() {
    if (!this.data || !this.data.sportType) return;
    const {
      sportType,
      text,
      title,
      correctAnswerId,
      attributes: {stopTimeMilliseconds, stopTimeSeconds, url}
    } = this.data;
    this.videoDataFromGroup.get('text').patchValue(text);
    this.videoDataFromGroup.get('title').patchValue(title);
    this.videoDataFromGroup.get('sportType').patchValue(sportType);
    this.videoDataFromGroup.get('url').patchValue(url);
    const selectedAnswer = this.answers.find(answer => answer.id === correctAnswerId);
    this.videoDataFromGroup.get('answer').patchValue(selectedAnswer);
    this.selectedSportType = sportType;
    this.stopTimeMilliseconds = stopTimeMilliseconds;
    this.stopTimeSeconds = stopTimeSeconds;
    this.currentVideoTime = this.stopTimeSeconds + this.stopTimeMilliseconds / 1000;
    this.video = {link: url};
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }

  /**
   * On save button click
   * Create a body for saving video request and send different request if this is editing or adding
   */
  saveVideo() {
    if (!this.stopTimeSeconds && !this.stopTimeMilliseconds) {
      this.showVideoError = true;
      return;
    }
    this.isLoaded = false;
    const videoData = this.videoDataFromGroup.value;
    const {text, title, sportType, url, answer} = videoData;
    const {stopTimeSeconds, stopTimeMilliseconds, answerTimeSec} = this;
    const attributes = {answerTimeSec, url, stopTimeSeconds, stopTimeMilliseconds};

    const videoBody = {title, text, sportType, correctAnswerId: answer.id, attributes} as VideoQuestionModel;

    const request$ = this.data ?
      this.videoService.updateVideoQuestion(videoBody, this.data.id) :
      this.videoService.createVideoQuestion(videoBody);

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.dialogRef.close();
          this.snackBarService.showSnackBar('Video saved successfully');
          this.videoService.needUpdateVideoList();
          this.isLoaded = true;
        },
        error => {
          this.isLoaded = true;
          this.snackBarService.showSnackBar(error.error.message, true);
        });
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.videoDataFromGroup.get(fieldName).touched) return false;
    return this.videoDataFromGroup.get(fieldName).hasError(errorName);
  }

  /**
   * Listeting when video is loaded and path value
   */
  uploadFile(event) {
    this.videoDataFromGroup.get('url').patchValue(event ? event.link : event);
  }

  /**
   * Listeting slider position and assign current time as stop time
   */
  onTimeUpdate(time) {
    this.stopTimeSeconds = Math.floor(time);
    this.stopTimeMilliseconds = Math.round((time - this.stopTimeSeconds) * 1000);
  }

}

