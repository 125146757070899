<div class="form-container">
  <form [formGroup]="questionFormGroup">
    <div class="body-medium-medium day-number">Day {{index + 1}}</div>
    <ng-container *ngIf="!isCloseDay && !isCompleteDay; else closeDayForm">
      <div class="general-from-filed">
        <div class="form-field-with-label">
          <mat-label>Question 1*</mat-label>
          <mat-form-field appearance="outline">
            <input
              trim
              matInput
              placeholder="Enter question text"
              [formControlName]="'questionOneText'">
          </mat-form-field>
          <mat-error *ngIf="isShowGeneralError('questionOneText', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY}}</span>
          </mat-error>
          <mat-error *ngIf="!isShowGeneralError('questionOneText', 'required') && isShowGeneralError('questionOneText', 'maxlength')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MAX_LENGHT_255}}</span>
          </mat-error>
        </div>
        <div class="form-field-with-label">
          <mat-label> Answer Options*</mat-label>
          <mat-form-field appearance="outline" *ngIf="!isCloseDay; else singleSelector">
            <mat-select
              multiple
              (selectionChange)="isChangedQuestion = true"
              [formControlName]="'answerOptions1'"
              required>
              <mat-option *ngFor="let answer of answerOptions" [value]="answer">
                {{answer}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #singleSelector>
            <mat-form-field appearance="outline">
              <mat-select
                [formControlName]="'answerOptions1'"
                required>
                <mat-option *ngFor="let answer of answerOptions" [value]="answer">
                  {{answer}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="isShowGeneralError('answerOptions1', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY_SELECT}}</span>
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div class="general-from-filed">
        <div class="form-field-with-label">
          <mat-label>Question 2*</mat-label>
          <mat-form-field appearance="outline">
            <input
              trim
              matInput
              placeholder="Enter question text"
              [formControlName]="'questionTwoText'">
          </mat-form-field>
          <mat-error *ngIf="isShowGeneralError('questionTwoText', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY}}</span>
          </mat-error>
          <mat-error *ngIf="!isShowGeneralError('questionTwoText', 'required') && isShowGeneralError('questionTwoText', 'maxlength')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MAX_LENGHT_255}}</span>
          </mat-error>
        </div>
        <div class="form-field-with-label">
          <mat-label> Answer Options*</mat-label>
          <mat-form-field appearance="outline" *ngIf="!isCloseDay; else singleSelectorSecond">
            <mat-select
              multiple
              (selectionChange)="isChangedQuestion = true"
              [formControlName]="'answerOptions2'"
              required>
              <mat-option *ngFor="let answer of answerOptions" [value]="answer">
                {{answer}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #singleSelectorSecond>
            <mat-form-field appearance="outline">
              <mat-select
                [formControlName]="'answerOptions2'"
                required>
                <mat-option *ngFor="let answer of answerOptions" [value]="answer">
                  {{answer}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="isShowGeneralError('answerOptions2', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY_SELECT}}</span>
            </mat-error>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #closeDayForm>
      <div class="fixed-from-filed">
        <div class="question-text-label">{{question?.questionOneText}}</div>
        <div class="form-field-with-label">
          <mat-radio-group
            color="primary"
            [formControlName]="'answerOptions1'">
            <mat-radio-button
              [value]="answer"
              *ngFor="let answer of answerOptions">
              {{answer}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="isShowGeneralError('answerOptions1', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY_SELECT}}</span>
          </mat-error>
        </div>
      </div>
      <div class="fixed-from-filed last">
        <div class="question-text-label">{{question?.questionTwoText}}</div>
        <div class="form-field-with-label">
          <mat-radio-group
            color="primary"
            [formControlName]="'answerOptions2'">
            <mat-radio-button
              [value]="answer"
              *ngFor="let answer of answerOptions">
              {{answer}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="isShowGeneralError('answerOptions2', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY_SELECT}}</span>
          </mat-error>
        </div>
      </div>
      <div class="buttons-container">
        <button
          type="submit"
          class="regular-button"
          [disabled]="isSubmitCorrectDisabled"
          (click)="submitCorrectAnswer()"
          mat-flat-button
          color="primary">
          Submit correct result
        </button>
      </div>

    </ng-template>

  </form>
  <div class="control-block" *ngIf="!isCompleteDay && !isCloseDay">
    <mat-divider></mat-divider>
    <div class="control-panel">
      <div class="controls-icons">
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="question.status !== QuestionStatusEnum.CREATED || disableMoveUp || questions.length === 1"
          [ngClass]="{'disabled': question.status !== QuestionStatusEnum.CREATED || disableMoveUp || questions.length === 1}"
          class="material-icons material-icons-outlined"
          (click)="moveQuestionUp()">arrow_upward
        </mat-icon>
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          [matRippleDisabled]="question.status !== QuestionStatusEnum.CREATED ||
          questions.length === 1 ||
          (questions.length > 1 && index === questions.length - 1 && questions[index- 1].status !== QuestionStatusEnum.CREATED)"
          [ngClass]="
          {
          'disabled': question.status !== QuestionStatusEnum.CREATED ||
          questions.length === 1 ||
          (questions.length > 1 && index === questions.length - 1 && questions[index- 1].status !== QuestionStatusEnum.CREATED)}"
          class="material-icons material-icons-outlined"
          (click)="moveQuestionDown()">arrow_downward
        </mat-icon>
      </div>
    </div>
  </div>

</div>
