import {EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {QuestionService} from "../../core/services/question.service";
import {QuestionResultDetailsModel} from "../../common/models/QuestionResultDetailsModel";
import {AnswerDetailsModel} from "../../common/models/AnswerDetailsModel";
import {QuestionTypes} from "../../common/Enums/QuestionTypesEnum";

@Component({
  selector: 'set-correct-question',
  templateUrl: './set-correct-question.component.html',
  styleUrls: ['./set-correct-question.component.scss']
})
export class SetCorrectQuestionComponent implements OnInit, OnDestroy {

  /**
   * Current round ID
   */
  @Input() roundId: number;

  /**
   * Is current round completed, need to disabled fields
   */
  @Input() isCompleted: boolean;

  /**
   * Unsaved correct answers in current round
   */
  @Input() unsetAnswers: any[];

  /**
   * Output event for selected correct answer
   */
  @Output() correctAnswers = new EventEmitter<any[]>();

  /**
   * Output event is selected correct answers was changed
   */
  @Output() valueWasChanged = new EventEmitter<boolean>();

  /**
   * Question list
   */
  questionList: QuestionResultDetailsModel[];

  /**
   * Selected answer list
   */
  setAnswerList = [];

  isEditingCorrectAnswers = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private questionService: QuestionService,
  ) {
  }

  ngOnInit(): void {
    if (this.isCompleted) {
        this.questionService.getQuestionList(this.roundId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((questions:  AnswerDetailsModel[]) => {
          this.isEditingCorrectAnswers = true;
          this.questionList = this.prepareCompletedQuestionList(questions);
        });
    } else {
      this.questionService.getQuestionListForManualResult(this.roundId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((questionList: QuestionResultDetailsModel[]) => {
          if (!questionList[0]) return;
          this.isEditingCorrectAnswers = !!questionList[0].correctAnswer;
          this.questionList = questionList;
          const preparedQuestionList = questionList.map(q => {
            const {id:questionId} = q;
            return {questionId}
          });
          this.setAnswerList = preparedQuestionList;
          this.correctAnswers.emit(preparedQuestionList);
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Prepare question with setted correct answers to show on template, base on different question type
   * @param questions - question list for round
   */
  prepareCompletedQuestionList(questions) {
    return questions.map((question) => {
      const newQuestion = {...new QuestionResultDetailsModel, ...question}
      newQuestion.answers = question.answers;
      switch (question.type) {
        case QuestionTypes.SCORE.label:
          newQuestion.correctAnswer = {score: question.correctAnswer};
          break;
        case QuestionTypes.RANGE.label:
          newQuestion.correctAnswer = {value: question.correctAnswer};
          break;
        case QuestionTypes.LIST.label:
        case QuestionTypes.OPTIONS.label:
        case QuestionTypes.LISTS.label:
          newQuestion.correctAnswer = {answerId: question.correctAnswer};
      }

      return newQuestion;
    })
  }

  /**
   * Set selected correct answer to the list of questions by question index in array
   * @param answer - selected answer
   * @param index  - index in question array
   */
  handleCorrectAnswer(answer, index) {
    this.setAnswerList[index] = answer;
    this.correctAnswers.emit(this.setAnswerList);
  }

  /**
   * Mark if one of the questions correctt answer was changed
   * @param flag - flag define true or false
   */
  handleChangesValue(flag) {
    this.valueWasChanged.emit(flag);
  }

  /**
   * Check if current question does not have correct answer
   * Goes throw array with unset answers and find question by question id
   * @param question - question to check if correct answer was set
   */
  isUnsetAnswer(question) {
    return this.unsetAnswers && this.unsetAnswers.length && this.unsetAnswers.find(q => q.questionId === question.id) ;
  }

}
