<div class="question-details-container">
  <div class="question-details-header body-big-bold">QUESTIONS</div>
  <div class="question-grid">
    <div *ngFor="let question of questionList; let i = index" class="question-container">
      <single-correct-question
      [question]="question"
      [answerList]="question.answers"
      [isCompleted]="isCompleted"
      [isUnset]="isUnsetAnswer(question)"
      (correctAnswer)="handleCorrectAnswer($event, i)"
      (valueWasChanged)="handleChangesValue($event)"
      >

      </single-correct-question>
    </div>
  </div>
</div>
