import { Injectable } from '@angular/core';
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  createShortFormatDate(date) {
    let formatDate = date;
    if (typeof date === 'string') {
      formatDate = new Date(date);
    }
    const month = formatDate.getMonth() + 1;
    const day = formatDate.getDate();
  }

  /**
   * Convert current date to date forman and set second to zero
   * @param stringDate - date in string format
   */
  setSecondToZero(stringDate) {
    let formatDate = stringDate;
    if (typeof stringDate === 'string') {
      formatDate = new Date(stringDate);
    } else {
      formatDate = new Date(moment(formatDate).toISOString())
    }

    const zeroSecondsDate = new Date(formatDate.setSeconds(0));
    const newDate = zeroSecondsDate.toISOString();
    return newDate;
  }
}
