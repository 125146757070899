<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">{{title}}</h2>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!this.videoDataFromGroup.valid || !isLoaded"
          (click)="saveVideo()">
          {{buttonTitle}}
        </button>
      </div>
    </div>
  </div>
  <div class="game-builder-body">
    <div class="game-builder-content">
      <div class="prize-details">
        <form [formGroup]="videoDataFromGroup">
          <div class="form-field-with-label">
            <mat-label>Title*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Title"
                [formControlName]="'title'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('title', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('title', 'required') && isShowError('title','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_255}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Sport Type*</mat-label>
            <mat-form-field appearance="outline">

              <mat-select
                [(value)]="selectedSportType"
                required
                [formControlName]="'sportType'">
                <mat-option
                  *ngFor="let type of sportTypes"
                  [value]="type">
                  {{ textService.capitalizeFirstLetter(type) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('sportType', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
          </div>
          <video-drag-and-drop
            [isRequired]="true"
            [title]="'Video'"
            [video]="video"
            [currentVideoTime]="currentVideoTime"
            (fileUpload)="uploadFile($event)"
            (timeUpdated)="onTimeUpdate($event)">
          </video-drag-and-drop>
          <mat-error
            class="video-error"
            *ngIf="!stopTimeMilliseconds && !stopTimeSeconds && showVideoError">
            <i class="fas fa-exclamation-circle"></i>
            <span class="text">{{appData.VIDEO_NOT_ZERO}}</span>
          </mat-error>
          <div class="question-fields">
            <div class="form-field-with-label">
              <mat-label>Question*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Question"
                  [formControlName]="'text'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('text', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('text', 'required') && isShowError('text','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_255}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Correct answer*</mat-label>
              <mat-radio-group
                color="primary"
                [formControlName]="'answer'"
                aria-label="Select an option"
                [value]="selectedAnswer">
                <mat-radio-button
                  *ngFor="let answer of answers"
                  [value]="answer">
                  {{answer.title}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
