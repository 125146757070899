import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable, of} from "rxjs";
import {RoundItemDetailsModel} from "../../common/models/RoundItemDetailsModel";
import {CreateRoundItemDetailsModel} from "../../common/models/CreateRoundItemDetailsModel";
import {SingleRoundItemDetailsModel} from "../../common/models/SingleRoundItemDetailsModel";
import {RoundListItemModel} from "../../common/models/RoundListItemModel";

@Injectable({
  providedIn: 'root'
})
export class RoundService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getRoundList(body):Observable<{ total: number,  records: RoundItemDetailsModel[]}> {
    let params = new HttpParams()
    Object.keys(body).filter(v => body[v]).forEach(param => {
      params = params.set(param, body[param])
    })
    return this.http.get<{ total: number,  records: RoundItemDetailsModel[]}>(this.uriConfig.rounds, { params });
  }

  getClosedRounds():Observable<{ total: number,  records: RoundListItemModel[]}>  {
    let params = new HttpParams();
    params = params.set('status', 'CLOSED');
    //@ts-ignore
    return this.http.get(this.uriConfig.rounds, { params });
  }

  getResultingRounds():Observable<RoundListItemModel[]>  {
    return this.http.get<RoundListItemModel[]>(this.uriConfig.rounds + '/resulting');
  }

  getRoundById(id):Observable<SingleRoundItemDetailsModel> {
    return this.http.get<SingleRoundItemDetailsModel>(this.uriConfig.rounds+'/'+id);
  }

  getSportTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.uriConfig.rounds+'/sports-types');
  }

  deleteRoundById(roundId:string) {
    return this.http.delete<any>(this.uriConfig.rounds + '/' + roundId);
  }

  needUpdateRoundList() {
    this.isNeedUpdate$.next(true);
  }

  roundListUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getRoundListStatus() {
    return this.isNeedUpdate$;
  }

  updateRoundById(roundId: number, round: SingleRoundItemDetailsModel) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + roundId, round);
  }

  createNewRound(round:CreateRoundItemDetailsModel): Observable<any> {
    return this.http.post<any>(this.uriConfig.rounds, round);
  }

  createDraft(round:CreateRoundItemDetailsModel | SingleRoundItemDetailsModel): Observable<any> {
    return this.http.post<any>(this.uriConfig.rounds, round);
  }
  editeDraft(round:CreateRoundItemDetailsModel | SingleRoundItemDetailsModel, id: number): Observable<any> {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + id, round);
  }

  createRoundPreview(round:CreateRoundItemDetailsModel) {
      return this.http.post<any>(this.uriConfig.rounds + '/preview', round);
  }

  createRoundFromPreview(round:CreateRoundItemDetailsModel, key : string) {
    return this.http.post<any>(this.uriConfig.rounds + '/preview/' + key, null);
  }
}
