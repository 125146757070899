import {QuestionStatusEnum} from "../Enums/QuestionStatusEnum";

export class TextStreakQuestionDetailsModel {
  questionOneText: string;
  questionOneId: number;
  questionTwoText: string;
  questionTwoId: number;
  status:QuestionStatusEnum;
  id: number;
  isValid: boolean;
  questionOneCorrectAnswer: string;
  questionTwoCorrectAnswer: string;
  questionTwoNewId: number
  questionOneNewId: number
}
