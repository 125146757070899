<div class="prize-details-container" [ngClass]="{'persona-prize': personalPrizes}">
  <ng-container *ngIf="!hideTitle">
    <div class="prize-details-header body-big-bold" *ngIf="!personalPrizes">PRIZES</div>
  </ng-container>
  <div *ngIf="!isAnyPrizeSelected && isShowErrorTime && !personalPrizes && prizeList?.length && !errorMessages" class="general-prize-error">
    <mat-icon class="material-icons">task_alt</mat-icon>
    <span class="text">{{appData.MANDATORY_PRIZE}}</span>
  </div>
  <div *ngIf="!prizeList?.length && isShowErrorTime && !isEditingDisable && !personalPrizes && !errorMessages" class="general-prize-error">
    <mat-icon class="material-icons">task_alt</mat-icon>
    <span class="text">{{appData.ADD_PRIZE}}</span>
  </div>
  <div *ngIf="selected && selected?.availableCount <= 0 && selected?.id !== selectedPrizeId && !errorMessages" class="general-prize-error">
    <mat-icon class="material-icons">task_alt</mat-icon>
    <span class="text">{{appData.PRIZE_REMAINING}}</span>
  </div>
  <div *ngIf="errorMessages" class="general-prize-error">
    <mat-icon class="material-icons">task_alt</mat-icon>
    <span class="text">{{errorMessages}}</span>
  </div>
  <div class="prize-grid">
    <div class="search-panell">
      <form>
        <div class="form-field-with-label">
          <mat-label>
            {{ label ? label : (personalPrizes ? 'Select one prize to transfer to Hunch': 'Choose one prize')}}
          </mat-label>
          <mat-form-field appearance="outline">
            <input
              matInput
              #search
              [disabled]="isEditingDisable"
              (input)="onSearch()"
              placeholder="Name">
            <mat-icon matPrefix="">search</mat-icon>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="main-content-container">
      <div class="prizes" *ngIf="prizeList?.length; else emptyText">
        <single-prize-item
          *ngFor="let prize of prizeList; let i = index"
          [ngClass]="{'last-row-item': !((i+1) % 3)}"
          [prizeItem]="prize"
          [overlayHeight]="'140px'"
          [imageHeight]="140"
          [width]="'174px'"
          [isEditingDisable]="isEditingDisable"
          [selectedPrizeId]="selectedPrizeId"
          [selectedPrizesArray]="selectedPrizesArray"
          [isAnyPrizeSelected]="isPrizeSelected"
          [multiSelect]="multiSelect"
          [personalPrizes]="personalPrizes"
          (selectedPrize)="onSelectedPrize($event)">
        </single-prize-item>
      </div>
      <ng-template #emptyText>
        <div class="body-medium-regular empty-prize-text">No prizes found</div>
      </ng-template>
      <mat-paginator *ngIf="prizeList?.length"
                     hidePageSize="true"
                     [length]="totalPrizeLenght"
                     [pageSize]="pageSize"
                     (page)="onPaginateChange($event)"
                     [showFirstLastButtons]="!personalPrizes"
                     aria-label="Select page of periodic elements">
      </mat-paginator>
      <div *ngIf="isLoading" class="disabled-overlay">
        <mat-spinner [diameter]="30" color="primary"></mat-spinner>
      </div>
    </div>


  </div>
</div>

