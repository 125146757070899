import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {CreateQuestionDetailModel, QuestionDetailsModel} from "../../common/models/QuestionDetailsModel";
import {QuestionRequestDetails} from "../../common/models/QuestionResultDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getQuestionList(roundId: number): Observable<QuestionDetailsModel[]> {
    //@ts-ignore
    return this.http.get<any>(this.uriConfig.questionsList + '/' + roundId);
  }

  createNewQuestion(question: CreateQuestionDetailModel): Observable<QuestionDetailsModel> {
    return this.http.post<any>(this.uriConfig.questions, question);
  }

  updateQuestion(questionId: number, question: CreateQuestionDetailModel): Observable<QuestionDetailsModel> {
    return this.http.patch<QuestionDetailsModel>(this.uriConfig.questions + '/' + questionId, question);
  }

  getQuestionListForManualResult(roundId: number) {
    return this.http.get(this.uriConfig.questionsManual + '/' + roundId);
  }

  getStreakQuestionById(roundId:number) {
    return this.http.get(this.uriConfig.streaks + '/questions/' +roundId);
  }

  updateStreakQuestion(dayId, body) {
    return this.http.patch<any>(this.uriConfig.questions + '/streak/' + dayId, body);
  }

  getQuestionById(questionId): Observable<QuestionRequestDetails> {
    //@ts-ignore
    return this.http.get<any>(this.uriConfig.questions + '/' + questionId);
  }

  needUpdateQuestionList() {
    this.isNeedUpdate$.next(true);
  }

  questionListUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getQuestionListStatus() {
    return this.isNeedUpdate$;
  }
}
