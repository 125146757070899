import {Component, EventEmitter, Output} from '@angular/core';
import {Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject, takeUntil, filter, switchMap} from "rxjs";
import {RoundItemDetailsModel} from "../../common/models/RoundItemDetailsModel";
import {cloneDeep} from "lodash";
import moment from 'moment';
import {StreakPrizeDetailsModel} from "../../common/models/StreakPrizeDetailsModel";
import {StreakPrizeTypeEnum} from "../../common/Enums/StreakPrizeTypeEnum";
import {PrizeService} from "../../core/services/prize.service";
import {PrizeDetailsModel} from "../../common/models/PrizeDetailsModel";
import {StreakPrizeDayDitailsModel} from "../../common/models/StreakPrizeDayDitailsModel";
import {StreakPrizeDayStatus} from "../../common/Enums/StreakPrizeDayStatus";
import {RoundService} from "../../core/services/round.service";

@Component({
  selector: 'streak-prize',
  templateUrl: './streak-prize.component.html',
  styleUrls: ['./streak-prize.component.scss']
})
export class StreakPrizeComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * Current round details
   */
  @Input() roundDetails: any;

  /**
   * Editable round details
   */
  @Input() editableRound: any;

  /**
   * Is any data on game details tab was changed, need if round is still open and just some days was closed
   */
  @Input() isDataChanged: boolean;

  /**
   * Flag to track is any prize was not save
   */
  isUnsavedPrize = false;

  /**
   * Input event is any prize was not save
   */
  @Input() set _isUnsavedPrize(flag) {
    this.isUnsavedPrize = flag;
  }

  /**
   * Output event for changes days
   */
  @Output() changedPrezies = new EventEmitter<StreakPrizeDetailsModel[]>();

  round: RoundItemDetailsModel;

  /**
   * Numbers of day in round
   */
  daysCount: number;

  /**
   * List of days generated based on daysCount
   */
  prizes: StreakPrizeDetailsModel[];

  /**
   * List of nft prizes
   */
  nftPrizeList: PrizeDetailsModel[];

  /**
   * List of saved prizes from current round
   */
  savedStreakPrizes: StreakPrizeDayDitailsModel[];

  /**
   * List of used in round prizes id, need to not show user used prize in available list
   */
  usedPrizeId: number[];

  /**
   * List of saved prize id
   */
  savedPrizes: number[];

  /**
   * Modified list of setted prizes
   */
  outputPrizes: StreakPrizeDetailsModel[];

  manuallyUsedPrizeId: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    let isDateDifference = false;
    if (this.editableRound){
      isDateDifference = this.editableRound.openDate !== this.roundDetails.openDate || this.editableRound.closeDate !== this.roundDetails.closeDate
    }
    if (!this.editableRound || isDateDifference) {
      this.round = this.roundDetails;
    } else {
      this.round = this.editableRound;
    }
    if (this.isDataChanged && this.round) {
      this.calculatePrize();
    }
    if (this.editableRound && changes.isDataChanged && changes.isDataChanged.currentValue && this.prizes) {
      this.changedPrezies.emit(this.outputPrizes);
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private prizeService: PrizeService,
    private roundService: RoundService
  ) {
  }

  ngOnInit() {
    if (this.round.id) {
      this.prizeService.getStreakRoundById(this.round.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((streakPrizes: StreakPrizeDayDitailsModel[]) => {
          this.savedStreakPrizes = streakPrizes.map(streakPrize => {
            let newPrize = cloneDeep(streakPrize);
            if (streakPrize.prize) {
              const {availableCount, id: prizeId, imageUrl: prizeImageUrl, name, reservedBalance, type: prizeType} = streakPrize.prize;
              newPrize = {...newPrize, availableCount, prizeId, prizeImageUrl, name, reservedBalance, prizeType}
            }
            return newPrize;
          });
          this.calculatePrize();
          this.changedPrezies.emit(this.outputPrizes);
        })
    }


    this.prizeService.needUpdatePrizeList();
    this.prizeService.getPrizeListStatus()
      .pipe(
        filter(response => response),
        takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchPrizeList());
  }

  fetchPrizeList() {
    if (this.round.id) {
      this.roundService.getRoundById(this.round.id)
        .pipe(
          switchMap((currentRoundPrizes) => {
            this.savedPrizes = currentRoundPrizes.streakDays
              .map(day => day.prize?.id)
              .filter(value => value);
            return this.prizeService.getPrizeList({status: 'READY', includeIds: this.savedPrizes})
          })
        )
        .subscribe(response => {
          this.nftPrizeList = response.records;
        })
    } else {
      this.prizeService.getPrizeList({status: 'READY'})
        .subscribe(response => {
          this.nftPrizeList = response.records;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Create list of days(prizes) for current round base on daysCount
   * property daysCount calculate based on start and end date
   * Firstly create empty prizes object,
   * and if this is editind round assign saved data to relevant object
   */
  calculatePrize() {
    let startDate = cloneDeep(this.round.openDate);
    let endDate = cloneDeep(this.round.closeDate);
    if (!startDate || !endDate) return;

    if (typeof startDate === 'string') {
      //@ts-ignore
      startDate = moment(startDate);
    }
    if (typeof endDate === 'string') {
      //@ts-ignore
      endDate = moment(endDate);
    }
    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    this.daysCount = moment(endDate).diff(moment(startDate), 'days');
    const prizes = Array.from(Array(this.daysCount).keys())
      .map(day => {
        const prize = new StreakPrizeDetailsModel();
        prize.day = day + 1;
        prize.selected = false;
        prize.isCollapse = true;
        prize.disabled = false;
        prize.status = StreakPrizeDayStatus.CREATED;
        prize.prizeType = StreakPrizeTypeEnum.NFT;
        prize.isValid = true;
        return prize;
      });
    if (this.savedStreakPrizes) {
      this.savedStreakPrizes.forEach((prize: StreakPrizeDayDitailsModel, index: number) => {
        if (index <= prizes.length - 1) {
          const newPrize = cloneDeep(prize);
          newPrize.selected = !!prize.prizeType;
          newPrize.isCollapse = true;

          if (prize.points) {
            newPrize.prizeType = StreakPrizeTypeEnum.POINTS;
            newPrize.selected = true;
          }
          if (!newPrize.prizeType) newPrize.prizeType = StreakPrizeTypeEnum.NFT;
          newPrize.reservedBalance = prize.reservedBalance;
          newPrize.disabled = prize.status !== StreakPrizeDayStatus.CREATED;
          newPrize.status = prize.status;
          newPrize.isValid = true;

          prizes[index] = newPrize;
        }
      });
    }
    this.prizes = prizes;
    this.outputPrizes = [...this.prizes]
  }

  /**
   * Clone prize list, call create used array function and change prize on present index to the new
   * assign property usedPrizeId - all used prize id
   * and call output event with saved prizes
   * @param prize changed prize
   * @param index index of current prize
   */
  onPrizeChanged(prize, index) {
    this.createUsedPrizeIdArray(prize, index);
    const newPrize = cloneDeep(prize);
    this.outputPrizes[index] = newPrize;
    this.changedPrezies.emit(this.outputPrizes);
  }

  /**
   * Clone existing used prize id list to input will track this is new array
   * If in prize no prizeId and new points, current prize Id will delete from array, as it was changed to points prize
   * If prize id is not change noting happen
   * If prize id changed two ways: if this Id present in used array, delete it from array, if no, add
   * Assign new id array to manuallyUsedPrizeId
   * @param prize changed prize
   * @param index index of current prize
   */
  createUsedPrizeIdArray(prize, index) {
    if (prize.prizeType !== StreakPrizeTypeEnum.POINTS) {
      const newList = [...this.manuallyUsedPrizeId];

      if (!prize.prizeId && !prize.points) {
        const usedPrizeIndex = this.manuallyUsedPrizeId.findIndex(prizeId => prizeId === this.outputPrizes[index].prizeId);

        if (usedPrizeIndex > -1) {
          newList.splice(usedPrizeIndex, 1);
        }
        this.manuallyUsedPrizeId = newList;
        return;
      }

      if (this.outputPrizes[index].prizeId !== prize.prizeId) {

        const used = this.manuallyUsedPrizeId.findIndex(p => p === this.outputPrizes[index].prizeId);

        if (used > -1) {
          newList.splice(used, 1);
        }
        newList.push(prize.prizeId);
      }

      this.manuallyUsedPrizeId = newList;
    }
  }

  /**
   * Set property validity to each prize
   * Need to show error if prize is not valid and not allow to save round
   * @param flag getting from child component based on form valid property
   * @param index index of current prize
   */
  handleValidPrize(flag, index) {
    this.prizes[index].isValid = flag;
  }
}
