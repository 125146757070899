export const APP_ROUTES = {
  AUTH: 'auth',
  DASHBOARD: 'dashboard',
  VIDEO_LIBRARY: 'video-library',
  GAMES: 'games',
  ALL_GAMES: 'all-games',
  ANALYTICS: 'game-results',
  PRIZES_AND_WINNERS: 'prizes-and-winners',
  NFT_PRIZES: 'prizes',
  WINNERS: 'winners',
  CRM: 'crm',
  PLAYERS: 'players',
  PLAYER_SUPPORT: 'player-support',
  SETTINGS: 'settings',
  NOTIFICATION: 'notifications',
  PORTAL: 'v',
  PORTAL_ADMIN: 'a',
  RESTORE_PASSWORD:'restorePassword',
  ADMIN: 'admin',
  VENDORS: 'vendors-and-users',
  VENDORS_LIST: 'vendors',
  USERS: 'users',
  LEADERBOARD_WINNERS: 'leaderboard-winners',
  LEADERBOARD_PRIZES: 'leaderboard-prizes',
};
