import {FormGroup, ValidatorFn} from "@angular/forms";
import moment from 'moment';
import {Injectable} from "@angular/core";


@Injectable({providedIn: 'root'})

export class IsCloseDateAfterOpen {
  /**
   * Check that close date is after open date
   */
  public validateDates(): ValidatorFn {
    return (formGroup: FormGroup) => {
      const openDate = formGroup.get('openDate').value;
      const closeDate = formGroup.get('closeDate').value;

      if (!openDate || !closeDate) {
        return null;
      }
      if (moment(closeDate).isBefore(openDate)) {
        return { datesOrderWrong: true };
      }
      return null;
    };
  }
}
