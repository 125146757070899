import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {UriConfig} from "../../app.config";
import { HerosRewardModel } from 'src/app/common/models/HerosRewardModel';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  needUpadteLeaderboardPrizes$ = new BehaviorSubject({});

  getLeaderboardPrizes(body):Observable<{ records: HerosRewardModel[], total: number }>  {
    let params = new HttpParams()
    Object.keys(body).filter(v => body[v]).forEach(param => {
      params = params.set(param, body[param])
    })
    return this.http.get<{ records: HerosRewardModel[], total: number }>(this.uriConfig.heroesRewards,  { params });
  }

  updateLeadboardPrize(prizeId, body): Observable<any> {
    return this.http.patch<any>(`${this.uriConfig.heroesRewards}/${prizeId}`, body);
  }

  createLeadboardPrize(body): Observable<any> {
    return this.http.post<any>(`${this.uriConfig.heroesRewards}`, body);
  }

  deleteLeadboardPrize(id: number): Observable<any> {
    return this.http.delete<any>(`${this.uriConfig.heroesRewards}/${id}`);
  }
}