export const APP_DATA = {
  MANDATORY: 'Please, fill this field',
  CONTRACT_ADDRESS: 'Must be valid Contract Address',
  WALLET: 'Must be a valid wallet address',
  OPENSEA_URL: 'Must be valid Opensea URL',
  MANDATORY_IMAGE: 'The image is mandatory',
  BIG_IMAGE: 'File is too large. Max size is 4mb',
  BIG_VIDEO: 'File is too large. Max size is 16mb',
  MANDATORY_SELECT: 'Please, select item',
  MANDATORY_PRIZE: 'Please, select prize',
  MANDATORY_EVENT: 'Questions of this type require an event to be specified',
  VIDEO_WITHOUT_QUESTION: 'You dont have video questions for sport types: ',
  ADD_PRIZE: 'Please, add prize',
  PRIZE_REMAINING: 'Please, select prize with remaining count more than 0',
  PRIZE_REMAINING_OVER: 'Balance must be less or equal to ',
  PASSWORD_DONT_MATCH: 'Passwords do not match. Please, try again',
  DATES_ERROR: 'Close date can`t be before Open date',
  OPEN_DATE_ERROR: 'Open date can`t be before current date',
  CLOSE_DATE_ERROR: 'Close date can`t be before current date',
  EMAIL_ERROR: 'Incorrect email',
  PHONE_ERROR: 'Incorrect phone',
  INCLUDE_DIGIT: 'Must include a digit',
  INCLUDE_LOWER_CASE: 'Must contain a lowercase letter',
  INCLUDE_UPPER_CASE: 'Must contain an uppercase letter',
  MIN_LENGHT: 'Must contain at least 8 characters',
  MIN_LENGHT_1: 'Must contain at least 1 characters',
  MIN_LENGHT_2: 'Must contain at least 2 characters',
  MIN_LENGHT_3: 'Must contain at least 3 characters',
  MAX_LENGHT: 'Must contain 20 characters maximum',
  MAX_LENGHT_15: 'Must contain 15 characters maximum',
  MAX_LENGHT_25: 'Must contain 25 characters maximum',
  MAX_LENGHT_200: 'Must contain 200 characters maximum',
  MAX_LENGHT_100: 'Must contain 100 characters maximum',
  MAX_LENGHT_255: 'Must contain 255 characters maximum',
  MAX_LENGHT_70: 'Must contain 70 characters maximum',
  MAX_LENGHT_50: 'Must contain 50 characters maximum',
  MAX_LENGHT_52: 'Must contain 52 characters maximum',
  MAX_LENGHT_32: 'Must contain 32 characters maximum',
  MAX_RANGE: 'Max Value must be equal or less than 9999',
  MIN_RANGE:  'Min Value must be greater than 0',
  INTEGER_VALUE: 'Only unsigned integer numbers are allowed',
  SPECIAL_CHARACTER_EXCLUDE: 'Please, remove unsupported characters',
  SPECIAL_CHARACTERS: 'Must contain a special character: #?!\[\]~`@$%^&\' * -<>(){}|\\/"',
  URL: 'Must be url address',
  DOUBLE_SPACE: 'Must not include double spaces',
  TWO_ANSWERS_ONLY: 'Must contain two answers',
  TWO_ANSWERS: 'At least two answers',
  THREE_ANSWERS: 'At least three answers',
  RANGE_MIN_VALUE_ERROR: 'The Min Value can’t be equal or greater than the Max Value',
  RANGE_INCREMENTAL_VALUE_ERROR: 'The increment can’t be 0 or greater than the difference between Max and Min Values',
  RANGE_INCREMENTAL_ALIQUOT_ERROR: 'Invalid increment value: sequential adding of an increment to the Min Value should be equal to Max Value',
  INVALID_DATE: 'The entered date and/or time is invalid. Please, try again',
  IMAGE_TOOLTIP_MASSAGE: 'We recommend using a VARIABLE image resolution (minimum). Max image size is 4MB',
  VIDEO_NOT_ZERO: 'Video stop seconds must be greater than 0',
  TOOLTIP_VARIABLE: 'VARIABLE',
  MOBILE_WIDTH: 768,
};

export const APP_CONFIG = {

}
