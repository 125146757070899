import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../../material/material.module";
import {AppHeaderComponent} from './app-header/app-header.component';
import {ButtonWithIconComponent} from './button-with-icon/button-with-icon.component';
import {DragAndDropFileComponent} from './drag-and-drop-file/drag-and-drop-file.component';
import {BrandSidenavComponent} from "./brand-sidenav/brand-sidenav.component";
import {ImageWithLoaderComponent} from "./image-with-loader/image-with-loader.component";
import {DialogComponent} from './dialog/dialog.component';
import {CreateEventModalComponent} from './create-event-modal/create-event-modal.component';
import {PipesModule} from "../modules/pipes/pipes.module";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { NewPrizeModalComponent } from './new-prize-modal/new-prize-modal.component';
import { RightAnswerComponent } from './right-answer/right-answer.component';
import { CreatePrizeComponent } from './create-prize/create-prize.component';
import {PrizesComponent} from "./prizes/prizes.component";
import {SinglePrizeItemComponent} from "./prizes/single-prize-item/single-prize-item.component";
import { AddVideoComponent } from './add-video/add-video.component';
import { VideoDragAndDropComponent } from './video-drag-and-drop/video-drag-and-drop.component';
import {DirectivesModule} from "../modules/directives/directives.module";
import { CreateVendorModalComponent } from './create-vendor-modal/create-vendor-modal.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import {SelectFilterComponent} from './select-filter/select-filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { CreateRewardModalComponent } from './create-reward-modal/create-reward-modal.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { FormContainerComponent } from './form-container/form-container.component';
import { SmallFileLoaderComponent } from './small-file-loader/small-file-loader.component';

@NgModule({
  declarations: [
    AppHeaderComponent,
    ButtonWithIconComponent,
    DragAndDropFileComponent,
    BrandSidenavComponent,
    ImageWithLoaderComponent,
    DialogComponent,
    CreateEventModalComponent,
    NewPrizeModalComponent,
    RightAnswerComponent,
    CreatePrizeComponent,
    CreateRewardModalComponent,
    PrizesComponent,
    SinglePrizeItemComponent,
    AddVideoComponent,
    VideoDragAndDropComponent,
    CreateVendorModalComponent,
    EditUserModalComponent,
    SelectFilterComponent,
    StatusChipComponent,
    FormContainerComponent,
    SmallFileLoaderComponent
  ],
    exports: [
        AppHeaderComponent,
        ButtonWithIconComponent,
        DragAndDropFileComponent,
        BrandSidenavComponent,
        ImageWithLoaderComponent,
        PrizesComponent,
        SinglePrizeItemComponent,
        SelectFilterComponent,
        StatusChipComponent,
        FormContainerComponent,
        SmallFileLoaderComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DirectivesModule,
        MatMenuModule
    ]
})
export class CommonComponentsModule {
}
