import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RoundItemDetailsModel} from "../common/models/RoundItemDetailsModel";
import {CreateRoundItemDetailsModel} from "../common/models/CreateRoundItemDetailsModel";
import {RoundService} from "../core/services/round.service";
import {filter, Subject, takeUntil} from "rxjs";
import {RoundSportEnum} from "../common/Enums/RoundSportEnum";
import {SnackBarService} from "../core/services/snack-bar.service";
import {SingleRoundItemDetailsModel} from "../common/models/SingleRoundItemDetailsModel";
import {DialogService} from "../common/components/dialog/dialog.service";
import {RoundStatusEnum} from "../common/Enums/RoundStatusEnum";
import {RoundTypesEnum} from "../common/Enums/RoundTypesEnum";
import {StreakPrizeTypeEnum} from "../common/Enums/StreakPrizeTypeEnum";
import {cloneDeep} from "lodash";
import {PrizeService} from "../core/services/prize.service";
import {DateService} from "../core/services/date.service";
import moment from "moment";
import {StreakPrizeDayStatus} from "../common/Enums/StreakPrizeDayStatus";
import {QuestionService} from "../core/services/question.service";
import {StreakDayItemModel} from "../common/models/StreakDayItemModel";
import {QuestionStatusEnum} from "../common/Enums/QuestionStatusEnum";
import {VideoService} from "../core/services/video.service";
import {VideoQuestionModel} from "../common/models/VideoQuestionModel";
import {AnswerService} from "../core/services/answer.service";
import {LocalStorageService} from "../core/services/local-storage.service";
import {ProfileService} from "../core/services/profile.service";

@Component({
  selector: 'create-new-round-modal',
  templateUrl: './create-new-round-modal.component.html',
  styleUrls: ['./create-new-round-modal.component.scss']
})
export class CreateNewRoundModalComponent implements OnInit, OnDestroy {

  /**
   * Store entered round details
   */
  roundDetails: any;

  /**
   * Save if form is valid
   */
  isFormValid: boolean;

  /**
   * Track is save button was clicked
   */
  isSaveButtonClicked = false;

  /**
   * Prediction game prize details
   */
  prizeDetails: any;

  /**
   * Popup header, create if this is new round, edit if this is editing round
   */
  header = 'Create';

  /**
   * Is any prize for prediction game was selected
   */
  isAnyPrizeSelected = true;

  /**
   * Tabs name, anchor name and tabs status
   */
  tabsName = [
    {name: 'Game Details', isActive: true, anchorName: 'gameDetails'},
    {name: 'Prizes', isActive: false, anchorName: 'prizes'},
    {name: 'Questions', isActive: false, anchorName: 'questions'}
  ]

  selectedIndex = 0;

  /**
   * Store round details if this is editing round
   */
  editableRound: RoundItemDetailsModel;

  savedRound: RoundItemDetailsModel;

  /**
   * Is current round has unsaved questions(prediction)
   */
  isUnsavedQuestions = false;

  /**
   * Is current round has unsaved prize
   */
  isUnsavedPrize = false;

  /**
   * Is current round has unsaved questions(streak)
   */
  isUnsavedStreakQuestion = false;

  /**
   * Questions id for prediction round
   */
  questionId = [];

  /**
   * Streak prizes
   */
  streakPrizes: any;

  /**
   * Streak questions
   */
  streakQuestions: any;

  /**
   * Is one of the questions was edited
   */
  isQuestionEdited: boolean;

  /**
   * Use for dont track scroll event while it scroll on tab click
   */
  isScrollTrackBlocking = false;

  /**
   * Is current round cant be edited
   */
  isEditingDisable = false;

  /**
   * Store current round type
   */
  roundType = RoundTypesEnum.Sport;

  RoundTypesEnum = RoundTypesEnum;

  /**
   * Is round date was changed
   */
  isDataChanged: boolean;

  predictionSelectedPrize: any;

  /**
   * Use for show loader
   */
  isLoadedData = false;

  /**
   * List of all video questions
   */
  videoQuestion: VideoQuestionModel[];

  /**
   * Store sport type which cant be use on video streak,
   * becouse vendor dont have this sport type video question
   */
  excludedSportType: string[];

  /**
   * Track is any error in round details data, nedd to prevent save function
   */
  isAnyErrorOnWizard = false;

  /**
   * Indicator to show loader
   */
  isLoaded = false;

  RoundStatusEnum = RoundStatusEnum;

  correctAnswersForQuestions: any[];

  unsetAnswers: any[];

  isStreakWithCloseDate = false;

  isPublished = false;

  valueWasChanged: boolean;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CreateNewRoundModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roundService: RoundService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private prizeService: PrizeService,
    private dateService: DateService,
    private questionService: QuestionService,
    private videoServiceService: VideoService,
    private answerService: AnswerService,
    private localStorageService: LocalStorageService,
    private profileService: ProfileService,
  ) {
  }

  /**
   * Process click on close button on wizzard.
   * If round status was completed (edited and submitted disabled) = just close wizzard
   * If any other status show confirmation popup
   */
  onCloseRoundWizard() {
    if (this.editableRound?.status === RoundStatusEnum.COMPLETED) {
      this.dialogRef.close();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(filter((response) => !!response))
        .subscribe(() => {
          this.dialogRef.close();
        })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (this.data) {
      this.header = 'Edit';

      this.roundService.getRoundById(this.data.id)
        .subscribe((val: any) => {
          // @ts-ignore
          if (val.type === RoundTypesEnum.Other || val.type === RoundTypesEnum.Sport) {
            this.predictionSelectedPrize = val.prize;
            if (this.predictionSelectedPrize)
              this.predictionSelectedPrize.availableCount = val.prize.type === 'NFT' ? 0 : val.prize.amount;
          }
          this.editableRound = val;
          // @ts-ignore
          this.isStreakWithCloseDate = this.editableRound.streakDays.some(day => day.status === QuestionStatusEnum.CLOSED);
          this.roundType = val.type;
          this.savedRound = val;
          this.isEditingDisable = !(val.status === RoundStatusEnum.PENDING || val.status === RoundStatusEnum.DRAFT);
          this.isLoaded = true;
          this.isLoadedData = true;
        })

    } else {
      this.isLoaded = true;
      this.isLoadedData = true;
    }
    this.videoServiceService.getVideoQuestions(null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((questions: { total: number, records: VideoQuestionModel[] }) => {
        this.videoQuestion = questions.records;
      });
  }

  /**
   * Track is data or round type was changed
   */
  onDataChanged(event) {
    this.isDataChanged = event;

    setTimeout(() => {
      this.isDataChanged = false
    }, 100)

  }

  /**
   * Process save round with draft status
   */
  onSaveDraft() {
    this.onSaveRound(true)
  }

  /**
   * Save round
   * Track is any of tab contain error:
   * (all fields fill in game details, prize seleted for prediction,
   * vendor has video question for selected sport type for video streak,
   * all question are filled for text streak,
   * all question are saved for prediction
   * Valid data field on streak prize type)
   * If all data valid save draft if isDraft true or open preview,
   * and if on preview publish button was clicked - publish
   * @param isDraft is draft button was clicked
   */
  onSaveRound(isDraft = false) {
    this.isUnsavedQuestions = false;
    this.isUnsavedPrize = false;
    this.isUnsavedStreakQuestion = false;
    this.isAnyErrorOnWizard = false;
    if (this.isUnsavedPrize) this.isUnsavedPrize = false;

    this.isSaveButtonClicked = true;

    if (this.roundType === RoundTypesEnum.Other || this.roundType === this.RoundTypesEnum.Sport) {
      if (this.questionId.length && this.questionId.some(q => !q.id) && this.isQuestionEdited) {
        this.isUnsavedQuestions = true;
        this.isAnyErrorOnWizard = true;
      }

      if (this.prizeDetails && !this.prizeDetails && !this.data) {
        this.isAnyErrorOnWizard = true;
      }
      if (this.isUnsavedQuestions) {
        this.isAnyErrorOnWizard = true;
      }
    }

    if ((this.roundType === RoundTypesEnum.Other || this.roundType === RoundTypesEnum.Sport) && !this.questionId.length) {
      this.isUnsavedQuestions = true;
      this.isAnyErrorOnWizard = true;
    }

    if (this.roundType === RoundTypesEnum.Video_streak) {
      this.excludedSportType = this.roundDetails.sport && this.roundDetails.sport.filter(s => !this.videoQuestion.some(videoQuestion => videoQuestion.sportType === RoundSportEnum[s]));
    }

    if (this.streakPrizes) {
      this.isUnsavedPrize = this.streakPrizes.some(prize => {
        if (!prize.selected) return false;
        switch (prize.prizeType) {
          case StreakPrizeTypeEnum.NFT:
            return !prize.prizeId
          case StreakPrizeTypeEnum.SFT:
            return !prize.prizeId || !prize.reservedBalance
          case StreakPrizeTypeEnum.POINTS:
            return !prize.points
        }
      })
      if (this.isUnsavedPrize) this.isAnyErrorOnWizard = true;
    }

    if (this.roundType === RoundTypesEnum.Text_streak) {
      if (!this.streakQuestions || this.streakQuestions.find(q => !q.questionOneText || !q.questionTwoText)) {
        this.isUnsavedStreakQuestion = true;
        this.isAnyErrorOnWizard = true;
      }
    }

    if (this.excludedSportType && this.excludedSportType.length) {
      this.isAnyErrorOnWizard = true;
    }

    if (this.roundType === RoundTypesEnum.Text_streak) {
      if (this.isUnsavedPrize || this.isUnsavedStreakQuestion || this.streakQuestions.find(q => !q.isValid)) {
        this.isAnyErrorOnWizard = true;
      }
    }

    if (!this.isFormValid && !this.isEditingDisable) return;
    if (this.isAnyErrorOnWizard) return;

    const newRound = new CreateRoundItemDetailsModel;
    newRound.round = {...newRound.round, ...this.roundDetails};

    if (typeof newRound.round.closeDate !== 'string') {
      // @ts-ignore
      newRound.round.closeDate = newRound.round.closeDate.toISOString();
    }

    if (typeof newRound.round.openDate !== 'string') {
      // @ts-ignore
      newRound.round.openDate = newRound.round.openDate.toISOString();
    }
    if (this.roundType === RoundTypesEnum.Other) {
      // @ts-ignore
      newRound.round.sport = [];
    } else if (this.roundType !== RoundTypesEnum.Text_streak) {
      // @ts-ignore
      newRound.round.sport = typeof newRound.round.sport === 'string' ? [RoundSportEnum[newRound.round.sport]] : newRound.round.sport.map(sport => RoundSportEnum[sport]);
    }

    if (this.roundType === RoundTypesEnum.Other || this.roundType === RoundTypesEnum.Sport) {
      newRound.round.prizeId = this.prizeDetails?.id;
      newRound.questionsIds = this.questionId.map(v => v.id);
    } else {

      if (this.streakPrizes && this.streakPrizes.find(p => !p.isValid)) return;

      if (this.roundType === RoundTypesEnum.Text_streak) {
        newRound.streakDays = [];
        this.streakQuestions.forEach((question, index) => {
          let newDay = {...question}
          if (this.streakPrizes) {
            const prize = this.streakPrizes.find(p => p.day === index + 1)
            const {prizeId, reservedBalance, points, id, status, selected, day} = prize;
            if ((points || prizeId) && selected) {
              newDay = {prizeId, reservedBalance, points, id, status, day, ...newDay}
              if (!newDay.reservedBalance) newDay.reservedBalance = 1;
            }
          }

          newRound.streakDays.push(newDay);
          if (!newRound.streakDays[index].day) {
            newRound.streakDays[index].day = index + 1;
          }
        })
      } else {
        if (this.streakPrizes && this.streakPrizes.some(prize => prize.selected)) {
          newRound.streakDays = this.streakPrizes
            .filter(s => s.selected && (s.points || s.prizeId))
            .map(day => {
              const newDay = cloneDeep(day);
              newDay.reservedBalance = day.reservedBalance ? +day.reservedBalance : 1;
              delete newDay.selected
              delete newDay.isCollapse
              delete newDay.status
              delete newDay.prizeType
              delete newDay.disabled
              delete newDay.isValid
              return newDay
            })
        }
      }
    }
    newRound.round.openDate = this.dateService.setSecondToZero(newRound.round.openDate)
    newRound.round.closeDate = this.dateService.setSecondToZero(newRound.round.closeDate)

    let editableRound = new SingleRoundItemDetailsModel;
    if (this.editableRound) {
      editableRound = {...newRound.round};
      if (this.roundType === RoundTypesEnum.Other || this.roundType === this.RoundTypesEnum.Sport) {
        editableRound.questionsIds = [...newRound.questionsIds];
      } else {
        if (newRound.streakDays) {
          newRound.streakDays.forEach((strek, index) => {
            strek.reservedBalance = strek.reservedBalance ? +strek.reservedBalance : null;
          })
          editableRound.streakDays = newRound.streakDays;
        }
      }
    }
    let request$;
    if (isDraft) {
      this.isLoaded = false;
      request$ = this.editableRound ?
        this.roundService.editeDraft(newRound, this.editableRound.id) :
        this.roundService.createDraft(newRound);
    } else {
      const clonedRound = cloneDeep(newRound);
      if (!clonedRound.round.type) clonedRound.round.type = this.editableRound.type;
      clonedRound.round.fromEdit = !!this.editableRound;
      request$ = this.roundService.createRoundPreview(clonedRound);
    }

    request$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
          if (!isDraft) {
            const {domain} = this.profileService.currentUser$.value;
            const fullSiteAddress = 'https://' + domain;
            //need for testing localy
            // const fullSiteAddress = 'http://localhost:4200';

            const token = this.localStorageService.getToken();

            const game = this.roundType.toLowerCase().includes('streak') ? '/game/streak' : '/game';
            const url = `${fullSiteAddress}${game}/preview?accessToken=${token}&uniqueKey=${response.key}`;

            const newWindow = window.open(url, '_blank', 'width=500,height=500,location=no,toolbar=no');
            window.addEventListener("message", (event) => {
              if (event.origin !== fullSiteAddress) {
                return;
              }

              if (event.data === "roundPublish" && !this.isPublished) {
                this.isPublished = true;
                this.isLoaded = false;
                if (this.roundType !== RoundTypesEnum.Other && this.roundType !== this.RoundTypesEnum.Sport) {
                  if (newRound.streakDays) {
                    newRound.streakDays.forEach((strek, index) => {
                      strek.reservedBalance = strek.reservedBalance ? +strek.reservedBalance : null;
                    })
                    if (this.isEditingDisable) {
                      const dayBody = this.createStreaksDaysBody();
                      this.prizeService.updateOpenedStreakRound(this.editableRound.id, {items: dayBody})
                        .subscribe(() => {
                            this.dialogRef.close();
                          },
                          error => {
                            this.snackBarService.showSnackBar(error.error.message, true)
                          })
                      return;
                    }
                  }
                }
                if (this.editableRound && this.editableRound.status === RoundStatusEnum.DRAFT) {
                  newRound.publish = true;
                }
                let saveRequest$;
                if (this.editableRound) {
                  saveRequest$ = this.roundService.editeDraft(newRound, this.editableRound.id);
                } else {
                  saveRequest$ = this.roundService.createRoundFromPreview(newRound, response.key)
                }

                saveRequest$
                  .subscribe(val => {
                      this.isLoaded = true;

                      this.roundService.needUpdateRoundList();
                      this.dialogRef.close();
                      newWindow.close()
                    },
                    error => {
                      this.isLoaded = true;

                      this.snackBarService.showSnackBar(error.error.message, true);
                    });
              }
            });
          } else {
            this.isLoaded = true;

            this.roundService.needUpdateRoundList();
            this.dialogRef.close();
          }
        },
        error => {
          this.isLoaded = true;

          if (typeof error.error.message === 'string') {
            this.snackBarService.showSnackBar(error.error.message, true);
          } else {
            this.snackBarService.showSnackbarWithTemplate(error.error.message, true)
          }
        });
  }

  /**
   * Submit correct answer for all questions for prediction game
   * If one of the answers is not submitted return function
   * If this is editing correct answer, use another api for single correct answer save
   */
  onSubmitCorrectAnswers() {
    const unsetQuestion = this.correctAnswersForQuestions.filter(question => {
      return !question.score && !question.value && !question.answerId;
    })
    this.unsetAnswers = unsetQuestion;

    if (unsetQuestion && unsetQuestion.length) {
      return;
    }
    const roundId = this.editableRound.id;

    if (this.correctAnswersForQuestions.some(q => q.isEdited)) {
      this.correctAnswersForQuestions.forEach(question => {
        if (question.isEdited) {
          this.answerService.resubmitCorrectAnswer(question, question.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.snackBarService.showSnackBar('Answers submitted');
                this.dialogRef.close();
              },
              error => {
                this.snackBarService.showSnackBar(error.error.message, true);
              });
        }
      })
    } else {
      this.answerService.bulkSubmitCorrectAnswers({roundId, correctAnswers: this.correctAnswersForQuestions})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
            this.snackBarService.showSnackBar('Answers submitted');
            this.dialogRef.close();
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
          });
    }
  }

//TODO check and delete if unuse
  checkRoundDataChanges() {
    const savedOpenDate = moment(this.savedRound.openDate);
    const savedCloseDate = moment(this.savedRound.closeDate);
    const openDate = this.roundDetails.openDate;
    const closeDate = this.roundDetails.closeDate;
    const newOpenDate = typeof openDate === 'string' ? moment(openDate) : openDate;
    const newCloseDate = typeof closeDate === 'string' ? moment(closeDate) : closeDate;
    return savedOpenDate.isSame(newOpenDate) && savedCloseDate.isSame(newCloseDate);
  }

  //TODO check and delete if unuse

  checkValidity() {
    return this.streakPrizes.find(prize => {
      switch (prize.prizeType) {
        case StreakPrizeTypeEnum.SFT:
          return prize.selected && !prize.prizeId && !prize.points;
        case StreakPrizeTypeEnum.NFT:
          return prize.selected && !prize.prizeId;
        case StreakPrizeTypeEnum.POINTS:
          return prize.selected && !prize.points;
      }
    })
  }

  //TODO check and delete if unuse
  test(e) {
    e.stopImmediatePropagation()
  }


  /**
   * Store question list from child component
   */
  handleQuestionList(questionList) {
    this.questionId = questionList;
  }

  /**
   * Store correct answer list from child component
   */
  handleCorrectAnswers(answersList) {
    this.correctAnswersForQuestions = answersList;

    if (this.unsetAnswers && this.unsetAnswers.length) {
      const unsetQuestion = this.correctAnswersForQuestions.filter(question => {
        return !question.score && !question.value && !question.answerId;
      })
      this.unsetAnswers = unsetQuestion;
    }
  }

  handleChangesValue(flag) {
    this.valueWasChanged = flag;
  }

  onStreakPrizeChanges(prizes) {
    this.streakPrizes = prizes;
  }

  /**
   * Store if one of the streak question was changed
   */
  onChnageStreakQuestion(streakQuestions) {
    this.streakQuestions = streakQuestions;
  }

  /**
   * Store if one of the prediction question was changed
   */
  handleEditedQuestion() {
    this.isQuestionEdited = true;
  }


  /**
   * On select tab and scroll to selected tab, block listeting scroll event in time of it
   */
  changeSelection(tabName, el: HTMLElement) {
    this.isScrollTrackBlocking = true;
    this.tabsName.forEach(tab => {
      tab.isActive = tab.name === tabName;
    })
    el.scrollIntoView({behavior: 'smooth', block: 'start'});
    setTimeout(() => this.isScrollTrackBlocking = false, 1000)
  }

  /**
   * Listening scroll event and mark as selected relevant tab
   */
  onScroll(e) {
    if (this.isScrollTrackBlocking) return;
    const detailsHeight = document.getElementById(this.tabsName[0].anchorName).clientHeight;
    if (!document.getElementById(this.tabsName[1].anchorName)) return;
    const prizeHeight = document.getElementById(this.tabsName[1].anchorName).clientHeight;
    const scrollPosition = (e.target as Element).scrollTop;
    if (scrollPosition < (detailsHeight / 2)) {
      this.tabsName.forEach(tab => tab.isActive = tab.anchorName === this.tabsName[0].anchorName);
    }
    if (scrollPosition > (detailsHeight / 2.5)) {
      this.tabsName.forEach(tab => tab.isActive = tab.anchorName === this.tabsName[1].anchorName);
    }
    if (prizeHeight > 180) {
      if (scrollPosition > detailsHeight + (prizeHeight / 3)) {
        this.tabsName.forEach(tab => tab.isActive = tab.anchorName === this.tabsName[2].anchorName);
      }
    } else {
      if (scrollPosition > (detailsHeight / 2) + (prizeHeight / 2)) {
        this.tabsName.forEach(tab => tab.isActive = tab.anchorName === this.tabsName[2].anchorName);
      }
    }

  }

  /**
   * Listening form value changes
   */
  onFormValueChanges(event) {
    this.roundDetails = event;
    if (event.type) {
      this.roundType = event.type;
    }
  }

  /**
   * Check validity of form
   */
  onFormValidCheck(isValid) {
    this.isFormValid = isValid;
  }

  /**
   * On change sport type, start track wxcluded sport type from begining
   */
  onSportChanged(data) {
    this.excludedSportType = [];
  }

  /**
   * Is prize selected for prediction
   */
  onSelectedPrize(prizeId) {
    this.isAnyPrizeSelected = true;
    this.prizeDetails = prizeId;
  }

  /**
   * Is prize tab available to show
   * For streak show only if dates and type selected
   * For prediction show always
   */
  showPrizeTab() {
    if (!this.roundDetails && !this.editableRound) return true;
    if (this.roundType === RoundTypesEnum.Sport || this.roundType === RoundTypesEnum.Other) return true;
    if (this.editableRound || (this.roundDetails.openDate && this.roundDetails.closeDate)) return true;
    return false;
  }

  /**
   * Is question tab available to show
   * For streak show only if dates and type selected
   * For prediction show always
   */
  showTextQuestionTab() {
    if (!this.roundDetails && !this.editableRound) return true;
    if (this.editableRound || (this.roundDetails.openDate && this.roundDetails.closeDate)) return true;
    if (this.roundType === RoundTypesEnum.Other || this.roundType === RoundTypesEnum.Sport) return true;
    return false;
  }

  /**
   * Create bodu for save streak days
   */
  createStreaksDaysBody() {
    const days = this.streakPrizes
      .filter(s => s.status === StreakPrizeDayStatus.CREATED)
      .map((prize, index) => {
      const {day, prizeId, reservedBalance, points, id, status} = prize;
      let newDay = {day, prizeId, reservedBalance, points, id, status, ...new StreakDayItemModel()};
      if (this.streakQuestions?.length) {
        const {questionOneText, questionTwoText} = this.streakQuestions[index];
        newDay = {questionOneText, questionTwoText, ...newDay};
      }
      return newDay;
    })
    return days
      .filter(d => d.status === QuestionStatusEnum.CREATED)
      .map(d => {
        delete d.status;
        return d;
      });
  }

  /**
   * Is draft button must be shown
   * Don't show for disable editing round
   */
  isShowDraftButton() {
    if (!this.data) return true;
    if (this.isEditingDisable) return false;
    return this.data.status === RoundStatusEnum.DRAFT;
  }

  /**
   * Is publish button must be shown
   * Don't show for streak with all statuses after close
   * Don't show for prediction with all statuses after open
   */
  isShowPublishButton() {
    if (!this.data || !this.data.name) return true;
    if (!this.data.type.toLowerCase().includes('streak')) {
      return !(this.data.status === RoundStatusEnum.CLOSED ||
        this.data.status === RoundStatusEnum.COMPLETED ||
        this.data.status === RoundStatusEnum.RESULT_PROCESSING ||
        this.data.status === RoundStatusEnum.OPENED);
    }

    switch (this.data.status) {
      case RoundStatusEnum.DRAFT:
      case RoundStatusEnum.PENDING:
        return true;
      case RoundStatusEnum.CLOSED:
      case RoundStatusEnum.COMPLETED:
      case RoundStatusEnum.RESULT_PROCESSING:
        return false;
      default:
        break;
    }

    if (this.editableRound) {
      return this.editableRound.streakDays.find(day => day.status === StreakPrizeDayStatus.CREATED);
    } else {
      return false;
    }
  }

  /**
   * Is submit correct answer button must be shown
   * Don't show for streak and for prediciton with all status except close
   */
  isShowSubmitButton() {
    if (!this.data || !this.data.name) return false;
    if (this.data.type.toLowerCase().includes('streak')) return false;
    return this.data.status === RoundStatusEnum.CLOSED;
  }
}
