<div class="reward-builder-container">
  <div class="reward-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{data.title}}</h2>
      </div>
      <div class="control-header">
        <button
          [disabled]="(data.isLoading$ | async)"
          (click)="saveForm()"
          mat-raised-button
          color="primary">
          {{data.actionButtonTitle || 'Save'}}
        </button>
      </div>
    </div>
  </div>
  <div class="reward-builder-body">
    <div class="reward-builder-content">
      <div class="reward-details">
        <form [formGroup]="rewardForm">
          <div class="form-field-with-label">
            <mat-label>
              From Place*
              <i
                class="fa-solid fa-circle-info"
                [matTooltipPosition]="tooltipPositionEnum.right"
                #tooltipFrom="matTooltip"
                matTooltip="This is the first number of the player on the leaderboard who will receive the prize."
                [matTooltipClass]="tooltipPositionEnum.right"></i>
            </mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="number"
                placeholder="From Place"
                [formControlName]="'fromPosition'"
                >
            </mat-form-field>
            <ng-container 
              [ngTemplateOutlet]="inputError" 
              [ngTemplateOutletContext] ="{
                inputControler:rewardForm.controls['fromPosition'], 
                errorName: 'fromPositionErorr'
              }">
            </ng-container> 
          </div>
          <div class="form-field-with-label">
            <mat-label>
              To Place*
              <i
                class="fa-solid fa-circle-info"
                [matTooltipPosition]="tooltipPositionEnum.right"
                #tooltipTo="matTooltip"
                matTooltip="This is the last number of the player on the leaderboard who will receive the prize."
                [matTooltipClass]="tooltipPositionEnum.right"></i>
            </mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="number"
                placeholder="To Place"
                [formControlName]="'toPosition'"
                >
            </mat-form-field>
            <ng-container 
              [ngTemplateOutlet]="inputError" 
              [ngTemplateOutletContext] ="{
                inputControler: rewardForm.controls['toPosition'], 
                errorName: 'toPositionError'
              }">
            </ng-container>
          </div>
          <div class="form-field-with-label">
            <div class="select-filter-container">
              <select-filter
                [ngClass]="{
                  'error': rewardForm.controls['assignDate'].touched &&
                           rewardForm.controls['assignDate'].errors
                }"
                (onSelect)="rewardForm.controls['assignDate'].setValue($event)"
                [selected]="rewardForm.controls['assignDate'].value"
                [options]="months$ | async"
                title="Month*">
                <i
                  class="fa-solid fa-circle-info"
                  [matTooltipPosition]="tooltipPositionEnum.right"
                  #tooltipMonth="matTooltip"
                  matTooltip="This is the month at the end of which the prize will be awarded"
                  [matTooltipClass]="tooltipPositionEnum.right"></i>
              </select-filter>
            </div>
            <mat-error
              *ngIf="
                rewardForm.controls['assignDate'].touched &&
                rewardForm.controls['assignDate'].errors"
              class="select-error">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">
                {{ appData.MANDATORY }}
              </span>
            </mat-error>
          </div>
          <div class="prizes-wrapper">
            <prizes 
              (selectedPrize)="selectPrize($event)"
              [predictionSelectedPrize]="{
                id: data?.reward?.prizeId
              }"
              [prizeType]="prizeType$ | async"
              label="Choose one prize*"
              [hideTitle]="'true'"
              [editableRoundPrize]="rewardForm.controls['prizeId'].value"
              [isAnyPrizeSelected]="!rewardForm.controls['prizeId'].touched || !!rewardForm.controls['prizeId'].value"
              [errorMessages]="
                rewardForm.hasError('prizeErorr') ? 
                  'You cannot use this prize because the remaining number of SFTs is not enough for all places allocation' : undefined">
            </prizes>
          </div>
        </form>
      </div>
  </div>
</div>
<div *ngIf="(data.isLoading$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

<ng-template #inputError let-inputControler="inputControler" let-errorName="errorName">
  <mat-error
    *ngIf="
      inputControler.touched &&
      inputControler.errors"
    class="select-error">
    <i class="fas fa-exclamation-circle"></i>
    <span class="text">
      <ng-container *ngIf="inputControler.errors?.[errorName]">
        {{inputControler.errors?.[errorName]}}
      </ng-container>
      <ng-container *ngIf="inputControler.errors?.required">
        {{appData.MANDATORY}}
      </ng-container>
      <ng-container *ngIf="inputControler.errors?.max">
        {{'Max value must be less than 1000000'}}
      </ng-container>
    </span>
  </mat-error>
</ng-template>