import {FormGroup, ValidatorFn} from "@angular/forms";
import {Injectable} from "@angular/core";


@Injectable({providedIn: 'root'})

export class RemainingCount {
  /**
   * Check reserved balance form input with available count of prize
   * Reserved balance must be less or equal with available count
   * Use only for sft
   */
  public validatePrize(): ValidatorFn {
    return (formGroup: FormGroup) => {
      if (!formGroup.get('reservedBalance') || !formGroup.get('nft')) return null;

      const reservedBalance = formGroup.get('reservedBalance').value;
      const nft = formGroup.get('nft').value;

      if (!reservedBalance || !nft) {
        return null;
      }
      if (reservedBalance > nft.availableCount) {
        return { maxBalance: true };
      }
      return null;
    };
  }
}
