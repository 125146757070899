import {RoundSportEnum} from "../Enums/RoundSportEnum";
import {EventFromQuestionDetailsModel} from "./EventDetailsModel";
import {AnswerDetailsModel} from "./AnswerDetailsModel";
import {QuestionStatusEnum} from "../Enums/QuestionStatusEnum";

export class QuestionDetailsModel {
  id: number
  order: number
  type: RoundSportEnum
  text: string
  sportEvent: EventFromQuestionDetailsModel
  answers: AnswerDetailsModel []
  status: QuestionStatusEnum
  roundId: number
  attributes: any

}

export class CreateQuestionDetailModel {
  type: string
  text: string
  sportEventId: number
  answers: AnswerItem[]
  attributes: Attributes
  id?: number
}

export class AnswerItem{
  answerId: number
  groupName: string
}
export class Attributes {
  minValue: 0
  maxValue: 0
  incrementValue: 0
  displayValue: string
}
