import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'many-error-snack-bar',
  templateUrl: './many-error-snack-bar.component.html',
  styleUrls: ['./many-error-snack-bar.component.scss']
})
export class ManyErrorSnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }

}
